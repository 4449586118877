import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  fontSize: 16,
});

export const container = s({
  display: "flex",
  padding: 20,
});

export const selectionColumn = s({
  flex: 1,
  padding: `0px 16px`,
  $nest: {
    "&:last-child": {
      borderLeft: "1px solid black",
    },
  },
  display: "flex",
  flexDirection: "column",
});

export const emptySortedSpace = s({
  flex: 1,
  cursor: "pointer",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.subtle,
    },
  },
});

export const columnTitle = s({
  backgroundColor: Style.colors.subtle,
  border: "1px solid black",
  fontSize: 24,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 16,
  marginBottom: 12,
});

export const prop = s({
  padding: `12px 4px`,
  border: `2px solid black`,
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
});

export const unsortedProp = s({
  marginBottom: 12,
});

export const selectedProp = s({
  border: `2px solid ${Style.colors.success}`,
});

export const propSeparater = s({
  border: `0px dashed black`,
  borderBottomWidth: 2,
  width: "100%",
});

export const propSeparaterContainer = s({
  padding: "16px 0px",
});

export const activePropSeparaterContainer = s({
  $nest: {
    "&:hover": {
      [`.${propSeparater}`]: {
        borderColor: Style.colors.error,
      },
    },
  },
});

export const propTitle = s({
  whiteSpace: "pre-wrap",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: "0 0 150px",
});

export const item = s({
  whiteSpace: "pre-wrap",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flex: 1,
});

export const buttonContainer = s({
  textAlign: "center",
  padding: 24,
});

export const button = s({
  padding: `16px 32px`,
  border: "1px solid black",
  backgroundColor: Style.colors.primary,
  fontSize: 20,
  fontWeight: 500,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.subtle,
    },
  },
});
