import React from "react";
import { LoginButton } from "../../auth";
import { useFirebaseContext } from "../../auth";
import { AuthorizationSettings } from "../../pages";
import * as style from "./Header.style";
import { TextInput } from "../TextInput";
import { toast } from "react-toastify";

export const Header: React.FC<{}> = () => {
  const { userJwt } = useFirebaseContext();
  const [isAuthSettingsModalOpen, setIsAuthSettingsModalOpen] =
    React.useState(false);
  const copyToken = () => {
    navigator.clipboard.writeText(userJwt || "");
    toast.success("JWT copied to clipboard");
  };
  return (
    <div className={style.component}>
      {!!userJwt && (
        <>
          <div
            className={style.copyButton}
            onClick={() => {
              setIsAuthSettingsModalOpen(true);
            }}
          >
            allowlist
          </div>
          <div className={style.tokenInputWrapper}>
            <TextInput
              className={style.tokenInput}
              value={userJwt}
              label="User JWT"
              disabled={true}
            />
          </div>
          <div className={style.copyButton} onClick={copyToken}>
            Copy Token
          </div>
        </>
      )}
      <LoginButton />
      <AuthorizationSettings
        onClose={() => setIsAuthSettingsModalOpen(false)}
        isOpen={isAuthSettingsModalOpen}
      />
    </div>
  );
};
