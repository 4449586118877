import * as React from "react";
import * as style from "./Leagues.style";
import * as Components from "../../../components";
import * as Models from "../../../models";
import { classes } from "typestyle";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../../gql";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import { useDispatch } from "react-redux";
import { PlayerActions } from "../../../store/actions";

interface StateProps {
  leagues: Record<string, Models.Bet.League> | null;
}

export const LeaguesInternal: React.FC<StateProps> = ({ leagues }) => {
  const [tab, setTab] = React.useState("create");
  const [leagueName, setLeagueName] = React.useState("");
  const [leagueCode, setLeagueCode] = React.useState("");
  const dispatch = useDispatch();

  const leaguesArray = React.useMemo(() => {
    return Object.values(leagues ?? {});
  }, [leagues]);

  /** Submission */
  const [loading, setLoading] = React.useState(false);
  const [createLeague, createLeagueStatus] = useMutation(
    Mutation.CREATE_LEAGUE_MUTATION,
  );
  const onSubmit = () => {
    if (!leagueName || !leagueCode) {
      toast.error("League Name and League Code are required");
    }
    setLoading(true);
    createLeague({
      variables: {
        input: {
          leagueRequest: {
            name: leagueName,
            leagueCode,
          },
        },
      },
    })
      .then((res) => {
        if (!!res?.data?.createLeague?.league?.leagueCode) {
          toast.success(
            `Congrats! You have created League ${res.data.createLeague.league.leagueCode}`,
          );
          dispatch(
            PlayerActions.UpdateLeagueSuccess({
              league: res.data.createLeague.league,
            }),
          );
          setLeagueCode("");
          setLeagueName("");
        } else {
          toast.error(
            res?.data?.createLeague?.errors?.length
              ? res.data.createLeague.errors[0].message
              : "Something went wrong creating this league - please try again",
          );
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error(
          "Something went wrong creating this league - please try again",
        );
        setLoading(false);
      });
  };

  return (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("create")}
          className={classes(
            style.pageTab,
            tab === "create" ? style.selectedPageTab : "",
          )}
        >
          Create League
        </div>
        <div
          onClick={() => setTab("view")}
          className={classes(
            style.pageTab,
            tab === "view" ? style.selectedPageTab : "",
          )}
        >
          View Leagues
        </div>
      </div>
      <div className={style.pageContainer}>
        {tab === "create" ? (
          <>
            <Components.TextInput
              value={leagueName}
              onChange={setLeagueName}
              className={style.textInput}
              label="League Name - National Basketball Association"
            />
            <Components.TextInput
              value={leagueCode}
              onChange={setLeagueCode}
              className={style.textInput}
              label="League Code - NBA"
            />
            <div
              className={style.button}
              onClick={loading ? undefined : onSubmit}
            >
              {loading ? (
                <Components.Icon.Spinner size={20} />
              ) : (
                "Create League"
              )}
            </div>
          </>
        ) : (
          <div className={style.table}>
            {leaguesArray.map((league) => (
              <div className={style.item} key={league.id}>
                <div>{league.id}</div>
                <div>{league.name}</div>
                <div>{league.leagueCode}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const Leagues = connect(
  (state: RootState) => ({
    leagues: state.players.leagues.items,
  }),
  {},
)(LeaguesInternal);
