import * as Models from "../../models";
import { EntryActions } from "../actions";

export interface EntryStore {
  pending: {
    items: Record<number, Models.Bet.ParlayBetPlacement> | null;
    hasNext: boolean;
    nextCursor?: string;
  };
  settled: {
    items: Record<number, Models.Bet.ParlayBetPlacement> | null;
    hasNext: boolean;
    nextCursor?: string;
  };
}

export const initialState: EntryStore = {
  pending: {
    items: null,
    hasNext: false,
  },
  settled: {
    items: null,
    hasNext: false,
  },
};

export const entries = (
  state: EntryStore = initialState,
  action: EntryActions.Actions,
): EntryStore => {
  switch (action.type) {
    case EntryActions.ActionTypes.GET_ENTRIES_SUCCESS: {
      const newDict: Record<string, Models.Bet.ParlayBetPlacement> = action
        .payload.settled
        ? { ...state.settled.items }
        : { ...state.pending.items };
      action.payload.nodes.forEach((entry) => {
        newDict[entry.transactionId] = entry;
      });
      return {
        ...state,
        settled: action.payload.settled
          ? {
              items: newDict,
              hasNext: action.payload.pageInfo.hasNextPage,
              nextCursor: action.payload.pageInfo.nextCursor,
            }
          : state.settled,

        pending: !action.payload.settled
          ? {
              items: newDict,
              hasNext: action.payload.pageInfo.hasNextPage,
              nextCursor: action.payload.pageInfo.nextCursor,
            }
          : state.pending,
      };
    }
    default:
      return state;
  }
};
