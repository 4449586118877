import { gql } from "@apollo/client";

export const GET_PROPS_QUERY = gql`
  query props(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [PropSortInput!]
    $where: PropFilterInput
  ) {
    props(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      nodes {
        propExternalId
        propInternalId
        title
        startTimeUtc
        closeTimeUtc
        propContent
        propImageUrl
        publishedOnUtc
        recordUpdatedBy
        updatedOnUtc
        propLine
        tooltipInfo
        propSpecials {
          name
          propSpecialId
        }
        propPropOptions {
          propInternalId
          propOptionId
          propOption {
            propText
            propOptionId
          }
          propDecorationId
          decoration {
            propDecorationId
            backgroundColor
            fontColor
            imageUrl
            propDecorationPlayerInfo {
              playerId
              teamId
            }
          }
        }
        sortOrder
        author {
          authorId
          name
        }
        televisionNetwork
        publishingStatus {
          publishingStatusName
        }
        propStatus {
          propStatusName
        }
        propTags {
          name
          propTagId
        }
        result {
          propText
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
