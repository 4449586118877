import { style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const title = s({
  fontSize: 24,
  textAlign: "center",
  marginBottom: 10,
});

export const row = s({
  display: "flex",
  alignItems: "center",
  marginBottom: 8,
  paddingBottom: 8,
  borderBottom: "1px solid black",
});

export const textInput = s({ width: "100%" });

export const buttons = s({
  paddingTop: 12,
});

export const submitButton = s({
  padding: 8,
  background: Style.colors.primary,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 500,
  width: "auto",
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.subtle,
    },
  },
});

export const tabs = s({
  textAlign: "center",
  display: "flex",
  color: Style.text.colors.main,
  marginBottom: 20,
});

export const pageTab = s({
  flex: 1,
  padding: 20,
  border: `2px solid black`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 24,
  fontWeight: 600,
  cursor: "pointer",
});

export const selectedPageTab = s({
  background: Style.colors.primary,
  color: Style.text.colors.main,
});

export const input = s({
  marginBottom: 20,
  width: "90%",
});

export const label = s({
  paddingLeft: 20,
  fontWeight: 500,
  fontSize: 16,
});

export const error = s({
  color: Style.colors.error,
  paddingTop: 16,
  fontSize: 16,
  fontWeight: 600,
})