import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * MESSAGES
   */
  GET_MESSAGES_SUCCESS = "MESSAGES/GET_MESSAGES_SUCCESS",
  UPDATE_MESSAGE_SUCCESS = "MESSAGES/UPDATE_MESSAGE_SUCCESS",
}

export const GetMessagesSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_MESSAGES_SUCCESS,
  {
    nodes: Models.Messages.Message[];
    pageInfo: {
      hasNextPage: boolean;
      nextCursor?: string;
      hasPreviousPage: boolean;
      previousCursor?: string;
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_MESSAGES_SUCCESS);

export const UpdateMessageSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_MESSAGE_SUCCESS,
  {
    message: Models.Messages.Message;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_MESSAGE_SUCCESS);

export type Actions = ReturnType<
  typeof GetMessagesSuccess | typeof UpdateMessageSuccess
>;
