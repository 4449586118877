import { gql } from "@apollo/client";

export const CREATE_LEAGUE_MUTATION = gql`
  mutation CreateLeagueMutation($input: CreateLeagueInput!) {
    createLeague(input: $input) {
      league {
        id
        name
        leagueCode
        country
        logoUrl
      }
      errors {
        ... on LeagueExistsError {
          message
        }
      }
    }
  }
`;
