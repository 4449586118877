import * as Models from "../../models";
import { PromoActions } from "../actions";

export interface PromoStore {
  promoCodes: {
    items: Record<number, Models.Promos.PromoCode> | null;
    hasNext: boolean;
    nextCursor?: string;
    hasPrevious: boolean;
    previousCursor?: string;
  };
  campaigns: {
    items: Record<number, Models.Promos.Campaign> | null;
    hasNext: boolean;
    nextCursor?: string;
    hasPrevious: boolean;
    previousCursor?: string;
  };
  contests: {
    items: Record<number, Models.Bet.Contest> | null;
    hasNext: boolean;
    nextCursor?: string;
    hasPrevious: boolean;
    previousCursor?: string;
  };
}

export const initialState: PromoStore = {
  promoCodes: {
    items: null,
    hasNext: false,
    hasPrevious: false,
  },
  campaigns: {
    items: null,
    hasNext: false,
    hasPrevious: false,
  },
  contests: {
    items: null,
    hasNext: false,
    hasPrevious: false,
  },
};

export const promos = (
  state: PromoStore = initialState,
  action: PromoActions.Actions,
): PromoStore => {
  switch (action.type) {
    case PromoActions.ActionTypes.GET_PROMO_CODES_SUCCESS: {
      const newDict: Record<string, Models.Promos.PromoCode> = state.promoCodes
        .items
        ? { ...state.promoCodes.items }
        : {};
      action.payload.nodes.forEach((message) => {
        newDict[message.id] = message;
      });
      return {
        ...state,
        promoCodes: {
          items: newDict,
          hasNext: action.payload.pageInfo.hasNextPage,
          nextCursor: action.payload.pageInfo.nextCursor,
          hasPrevious: action.payload.pageInfo.hasPreviousPage,
          previousCursor: action.payload.pageInfo.previousCursor,
        },
      };
    }
    case PromoActions.ActionTypes.UPDATE_PROMO_CODE_SUCCESS: {
      const { id } = action.payload.promoCode;
      return {
        ...state,
        promoCodes: {
          ...state.promoCodes,
          items: {
            ...state.promoCodes.items,
            [id]: {
              ...(state.promoCodes.items && state.promoCodes.items[id]),
              ...action.payload.promoCode,
            },
          },
        },
      };
    }
    case PromoActions.ActionTypes.GET_CAMPAIGNS_SUCCESS: {
      const newDict: Record<string, Models.Promos.Campaign> = state.campaigns
        .items
        ? { ...state.campaigns.items }
        : {};
      action.payload.nodes.forEach((message) => {
        newDict[message.id] = message;
      });
      return {
        ...state,
        campaigns: {
          items: newDict,
          hasNext: action.payload.pageInfo.hasNextPage,
          nextCursor: action.payload.pageInfo.nextCursor,
          hasPrevious: action.payload.pageInfo.hasPreviousPage,
          previousCursor: action.payload.pageInfo.previousCursor,
        },
      };
    }
    case PromoActions.ActionTypes.UPDATE_CAMPAIGN_SUCCESS: {
      const { id } = action.payload.campaign;
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          items: {
            ...state.campaigns.items,
            [id]: {
              ...(state.campaigns.items && state.campaigns.items[id]),
              ...action.payload.campaign,
            },
          },
        },
      };
    }
    case PromoActions.ActionTypes.GET_CONTESTS_SUCCESS: {
      const newDict: Record<string, Models.Bet.Contest> = state.contests.items
        ? { ...state.contests.items }
        : {};
      action.payload.nodes.forEach((contest) => {
        newDict[contest.id] = contest;
      });
      return {
        ...state,
        contests: {
          items: newDict,
          hasNext: action.payload.pageInfo.hasNextPage,
          nextCursor: action.payload.pageInfo.nextCursor,
          hasPrevious: action.payload.pageInfo.hasPreviousPage,
          previousCursor: action.payload.pageInfo.previousCursor,
        },
      };
    }
    case PromoActions.ActionTypes.UPDATE_CONTEST_SUCCESS: {
      const { id } = action.payload.contest;
      return {
        ...state,
        contests: {
          ...state.contests,
          items: {
            ...state.contests.items,
            [id]: {
              ...(state.contests.items && state.contests.items[id]),
              ...action.payload.contest,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};
