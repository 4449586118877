import { gql } from "@apollo/client";

export const UPDATE_LEAGUE_MUTATION = gql`
  mutation UpdateLeagueMutation($input: UpdateLeagueInput!) {
    updateLeague(input: $input) {
      league {
        id
        name
        leagueCode
        country
        logoUrl
      }
      errors {
        ... on LeagueExistsError {
          message
        }
      }
    }
  }
`;
