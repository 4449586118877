import React, { useState, useEffect, FC, useMemo, Dispatch } from "react";
import * as style from "./Risk.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { PropActions } from "../../store/actions";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import { bet } from "../../utility";
import { RootState } from "../../store/reducers";
import { useFirebaseContext } from "../../auth";
import { colors } from "../../styles";
import { classes } from "typestyle";

interface StateProps {
  props: Record<string, Models.Props.Prop> | null;
  hasNext: boolean;
  nextCursor?: string;
  getTeams: (after?: string) => void;
  getPlayers: (after?: string) => void;
}

export const RiskInternal: FC = ({}) => {
  /* Apollo */
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();

  const [loading, setLoading] = React.useState(false);
  const [entriesDict, setEntriesDict] = React.useState<Record<string, any>>({});
  const [propsDict, setPropsDict] = React.useState<Record<string, any>>({});
  const [selectedProps, setSelectedProps] = React.useState<string[]>([]);
  const [nextCursor, setNextCursor] = React.useState<string | undefined>("");
  const [activeEntries, setActiveEntries] = React.useState<string[]>([]);
  const [totalStake, setTotalStake] = React.useState(0);
  const [totalPaidOut, setTotalPaidOut] = React.useState(0);
  const [totalHeld, setTotalHeld] = React.useState(0);
  const [totalUnknownRisk, setTotalUnknownRisk] = React.useState(0);
  const [propOptionFlag, setPropOptionFlag] = React.useState(false);
  const [selectPropsFilter, setSelectPropsFilter] = React.useState("");

  const getEntries = (after?: string) => {
    const nltDate = new Date();
    nltDate.setDate(nltDate.getDate() - 30);
    client
      .query({
        query: Query.GET_PARLAY_BET_PLACEMENTS_QUERY,
        variables: {
          first: 10,
          after,
          where: {
            betResultStatusId: {
              eq: "PENDING",
            },
            and: {
              createdOn: {
                nlt: nltDate.toISOString(),
              },
            },
          },
        },
      })
      .then((res) => {
        if (res.data.parlayBetPlacements) {
          const newEntriesDict = { ...entriesDict };
          const newPropsDict = { ...propsDict };
          res.data.parlayBetPlacements.nodes.forEach((entry: any) => {
            newEntriesDict[entry.transactionId] = entry;
            entry.legs.forEach((leg: any) => {
              if (!leg.prop.resultId) {
                if (!newPropsDict[leg.prop.propInternalId]) {
                  newPropsDict[leg.prop.propInternalId] = {
                    ...leg.prop,
                    count: 1,
                    selection: null,
                  };
                } else {
                  newPropsDict[leg.prop.propInternalId].count += 1;
                }
              }
            });
          });
          setEntriesDict(newEntriesDict);
          console.log(newEntriesDict);
          setPropsDict(newPropsDict);
          setNextCursor(
            res.data.parlayBetPlacements.pageInfo.hasNextPage
              ? res.data.parlayBetPlacements.pageInfo.endCursor
              : "",
          );
        }
      });
  };

  useEffect(() => {
    getEntries();
  }, []);

  useEffect(() => {
    if (nextCursor) {
      getEntries(nextCursor);
    }
  }, [nextCursor]);

  const onPropClick = (id: string) => {
    if (selectedProps.includes(id)) {
      const newSelectedProps = [...selectedProps.filter((val) => val != id)];
      setSelectedProps(newSelectedProps);
    } else {
      setSelectedProps([...selectedProps, id]);
    }
  };

  const onPropOptionClick = (propId: string, optionId: string) => {
    const newDict = { ...propsDict };
    if (newDict[propId].selection === optionId) {
      newDict[propId].selection = null;
    } else {
      newDict[propId].selection = optionId;
    }
    setPropsDict(newDict);
    setPropOptionFlag(!propOptionFlag);
  };

  const onSelectAllProps = () => {
    setSelectedProps(Object.values(propsDict).map((p) => p.propInternalId));
  };

  useEffect(() => {
    const newActiveEntries: string[] = [];
    let newTotalStake = 0;
    let newTotalPaidOut = 0;
    let newTotalHeld = 0;
    let newTotalUnknownRisk = 0;
    Object.values(entriesDict).forEach((entry: any) => {
      let isActive = true;
      let isResulted = true;
      let isWin = true;
      entry.legs.forEach((leg: any) => {
        if (leg.prop.resultId) {
          if (leg.prop.resultId !== leg.propOptionId) {
            isWin = false;
          }
        } else {
          if (!isActive || !selectedProps.includes(leg.prop.propInternalId)) {
            isActive = false;
            isResulted = false;
            isWin = false;
          } else if (!propsDict[leg.prop.propInternalId].selection) {
            isResulted = false;
            isWin = false;
          } else if (
            propsDict[leg.prop.propInternalId].selection !== leg.propOptionId
          ) {
            isWin = false;
          }
        }
      });
      if (isActive) {
        newActiveEntries.push(entry.transactionId);
        newTotalStake += entry.wager;
        if (isResulted) {
          if (isWin) {
            newTotalPaidOut += entry.wager * bet.getMultiplier(entry.legCount);
          } else {
            newTotalHeld += entry.wager;
          }
        } else {
          newTotalUnknownRisk +=
            entry.wager * bet.getMultiplier(entry.legCount);
        }
      }
      setActiveEntries(newActiveEntries);
      setTotalHeld(newTotalHeld);
      setTotalStake(newTotalStake);
      setTotalPaidOut(newTotalPaidOut);
      setTotalUnknownRisk(newTotalUnknownRisk);
    });
  }, [selectedProps, propOptionFlag]);

  const filteredPropsToSelect = React.useMemo(() => {
    return Object.values(propsDict)
      .filter((prop) =>
        prop.propLine.toLowerCase().includes(selectPropsFilter.toLowerCase()),
      )
      .sort((a: any, b: any) => (a.propLine > b.propLine ? 1 : -1));
  }, [propsDict, selectPropsFilter]);

  const selectedPropsToRender = React.useMemo(() => {
    return selectedProps.sort((a: any, b: any) =>
      propsDict[a].propLine > propsDict[b].propLine ? 1 : -1,
    );
  }, [selectedProps]);

  return (
    <>
      <div className={style.component}>
        <div
          style={{
            display: "flex",
            gap: 8,
            justifyContent: "center",
            paddingLeft: 150,
          }}
        >
          <div className={style.title}>Select Props to Compare</div>
          <div className={style.button} onClick={onSelectAllProps}>
            Select All
          </div>
          <div className={style.button} onClick={() => setSelectedProps([])}>
            Clear All
          </div>
          <input
            style={{ height: 16 }}
            value={selectPropsFilter}
            onChange={(e) => setSelectPropsFilter(e.target.value)}
            placeholder="filter"
          />
        </div>
        <div className={style.propSelection}>
          {filteredPropsToSelect.map((prop) => (
            <div
              key={prop.propInternalId}
              className={classes(
                style.propSelectionItem,
                selectedProps.includes(prop.propInternalId)
                  ? style.selectedItem
                  : {},
              )}
              onClick={() => onPropClick(prop.propInternalId)}
            >
              <div>{prop.propLine}</div>
              <div>{prop.count}</div>
            </div>
          ))}
        </div>
        <div className={style.title}>Comparison</div>
        <div className={style.resultItem}>
          <div>Entry Count: </div>
          <div className={style.result}>{activeEntries.length}</div>
        </div>
        <div className={style.resultItem}>
          <div>Stake Total: </div>
          <div className={style.result}>{totalStake}</div>
        </div>
        <div className={style.resultItem}>
          <div>Total Paid Out: </div>
          <div className={style.result}>{totalPaidOut}</div>
        </div>
        <div className={style.resultItem}>
          <div>Total Held: </div>
          <div className={style.result}>{totalHeld}</div>
        </div>
        <div className={style.resultItem} style={{ marginBottom: 12 }}>
          <div>Unknown Risk:</div>
          <div className={style.result}>{totalUnknownRisk}</div>
        </div>
        <div className={style.resultItem} style={{ marginBottom: 12 }}>
          <div>Profit</div>
          <div
            className={style.result}
            style={{
              color:
                totalHeld - totalPaidOut < 0 ? colors.error : colors.success,
            }}
          >
            {totalHeld - totalPaidOut}
          </div>
        </div>
        <div className={style.resultItem} style={{ marginBottom: 12 }}>
          <div>Hold %</div>
          <div
            className={style.result}
            style={{
              color:
                totalHeld - totalPaidOut < 0 ? colors.error : colors.success,
            }}
          >
            {(((totalHeld - totalPaidOut) / totalStake) * 100).toFixed(2)}%
          </div>
        </div>
        <div className={style.propSelection} style={{ maxHeight: 300 }}>
          {selectedPropsToRender.map((propId) => (
            <div className={style.propSelectionItem} key={propId}>
              <div style={{ flex: 1 }}>{propsDict[propId].propLine}</div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                {propsDict[propId].propPropOptions.map((option: any) => (
                  <div
                    key={option.propOptionId}
                    style={{
                      padding: 4,
                      backgroundColor:
                        propsDict[propId].selection === option.propOptionId
                          ? colors.primary
                          : "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onPropOptionClick(propId, option.propOptionId);
                    }}
                  >
                    {option.propOption.propText}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export const Risk = connect(
  (state: RootState) => ({
    props: state.props.props.pending.items,
    hasNext: state.props.props.pending.hasNext,
    nextCursor: state.props.props.pending.nextCursor,
  }),
  {},
)(RiskInternal);
