import * as React from "react";
import * as style from "./ViewMessages.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../gql";
import { connect, useDispatch } from "react-redux";
import { MessageActions } from "../../store/actions";
import { RootState } from "../../store/reducers";
import { Icon, Modal } from "../../components";
import * as Models from "../../models";
import { date } from "../../utility";
import { CreateMessage } from "./CreateMessage";
import { decode } from "html-entities";

interface StateProps {
  messages: Record<string, Models.Messages.Message> | null;
  hasPrevious: boolean;
  startCursor?: string;
}

export const ViewMessagesInternal: React.FC<StateProps> = ({
  messages,
  hasPrevious,
  startCursor,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedMessage, setSelectedMessage] = React.useState<string | null>(
    null,
  );

  // /** Get Messages */
  const getMessages = (before?: string) => {
    client
      .query({
        query: Query.GET_MESSAGES_QUERY,
        variables: {
          last: 50,
          before,
        },
      })
      .then((res) => {
        dispatch(MessageActions.GetMessagesSuccess(res.data.messages));
      });
  };
  React.useEffect(() => {
    getMessages();
  }, []);

  const messageArray = !messages
    ? null
    : Object.values(messages).sort((a, b) =>
        new Date(a.startDate) > new Date(b.startDate) ? -1 : 1,
      );

  return (
    <div className={style.component}>
      {!messageArray ? (
        <Icon.Spinner size={16} />
      ) : (
        <div className={style.messagesContainer}>
          {messageArray.map((message) => (
            <div
              className={style.message}
              key={message.messageId}
              onClick={() => setSelectedMessage(message.messageId)}
            >
              <div className={style.field}>{decode(message.title)}</div>
              <div className={style.field}>{decode(message.body)}</div>
              <div className={style.field}>{decode(message.category)}</div>
              <div className={style.field}>
                {message.isActive ? "Active" : "Inactive"}
              </div>
              <div className={style.field}>
                <div style={{ fontWeight: 700 }}>Start Time</div>
                <div>
                  {date.toShortDateAndTimeUTC(new Date(message.startDate))}
                </div>
              </div>
              <div className={style.field}>
                <div style={{ fontWeight: 700 }}>End Time</div>
                <div>
                  <div>
                    {date.toShortDateAndTimeUTC(new Date(message.endDate))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {selectedMessage && !!messages && (
        <Modal
          onRequestClose={() => {
            setSelectedMessage(null);
          }}
          isOpen={!!selectedMessage}
        >
          <CreateMessage
            message={messages[selectedMessage]}
            onClose={() => setSelectedMessage(null)}
          />
        </Modal>
      )}
    </div>
  );
};

export const ViewMessages = connect((state: RootState) => ({
  messages: state.messages.messages.items,
  hasPrevious: state.messages.messages.hasPrevious,
  startCursor: state.messages.messages.previousCursor,
}))(ViewMessagesInternal);
