import { gql } from "@apollo/client";

export const CREATE_MESSAGE_MUTATION = gql`
  mutation CreateMessageMutation($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        messageId
        startDate
        endDate
        title
        body
        category
        isActive
      }
    }
  }
`;
