import { gql } from "@apollo/client";

export const GET_PROMO_CODES = gql`
  query promoCodes(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: PromoCodeFilterInput
  ) {
    promoCodes(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
    ) {
      nodes {
        id
        code
        promoAmount
        campaignId
        redemptionLimit
        totalRedeemed
        isActive
        createdAtUtc
        startedAtUtc
        endedAtUtc
        updatedOnUtc
        updatedBy
        promoCodeRedemptions {
          id
          userId
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
