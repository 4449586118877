import React, { useEffect, FC } from "react";
import * as style from "./EntryTracker.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../gql";
import { EntryActions } from "../../store/actions";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import { classes } from "typestyle";
import { RootState } from "../../store/reducers";
import { Icon } from "../../components";
import { date, hooks } from "../../utility";
import { decode } from "html-entities";
import InfiniteScroll from "react-infinite-scroll-component";

interface StateProps {
  settledNextCursor?: string;
  settledEntries: Record<string, Models.Bet.ParlayBetPlacement> | null;
  pendingNextCursor?: string;
  pendingEntries: Record<string, Models.Bet.ParlayBetPlacement> | null;
}

export const EntryTrackerInternal: FC<StateProps> = ({
  settledEntries,
  settledNextCursor,
  pendingEntries,
  pendingNextCursor,
}) => {
  /* Apollo */
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [mode, setMode] = React.useState<"pending" | "settled">("pending");
  /* Values */

  const getEntries = () => {
    setLoading(true);
    client
      .query({
        query: Query.GET_PARLAY_BET_PLACEMENTS_QUERY,
        variables: {
          first: 50,
          after:
            mode === "pending"
              ? !!pendingNextCursor
                ? pendingNextCursor
                : undefined
              : !!settledNextCursor
              ? settledNextCursor
              : undefined,
          where: {
            betResultStatusId:
              mode === "pending"
                ? {
                    eq: "PENDING",
                  }
                : { neq: "PENDING" },
          },
          order: {
            createdOn: "DESC",
          },
        },
      })
      .then((res) => {
        setLoading(false);
        dispatch(
          EntryActions.GetEntriesSuccess({
            nodes: res.data.parlayBetPlacements.nodes,
            pageInfo: {
              hasNextPage: res.data.parlayBetPlacements.pageInfo.hasNextPage,
              nextCursor: res.data.parlayBetPlacements.pageInfo.endCursor,
            },
            settled: mode === "settled",
          }),
        );
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getEntries();
  }, [mode]);

  const showMore = React.useMemo(() => {
    if (mode === "pending") {
      return !!pendingNextCursor;
    } else {
      return !!settledNextCursor;
    }
  }, [mode, pendingNextCursor, settledNextCursor]);

  const settledEntriesArray = React.useMemo(() => {
    return !settledEntries
      ? []
      : Object.values(settledEntries).sort((a, b) =>
          new Date(a.createdOn) < new Date(b.createdOn) ? 1 : -1,
        );
  }, [settledEntries]);

  const pendingEntriesArray = React.useMemo(() => {
    return !pendingEntries
      ? []
      : Object.values(pendingEntries).sort((a, b) =>
          new Date(a.createdOn) < new Date(b.createdOn) ? 1 : -1,
        );
  }, [pendingEntries]);

  return (
    <>
      <div className={style.component}>
        <div className={style.tabs}>
          <div
            onClick={() => setMode("pending")}
            className={classes(
              style.pageTab,
              mode === "pending" ? style.selectedPageTab : "",
            )}
          >
            Pending
          </div>
          <div
            onClick={() => setMode("settled")}
            className={classes(
              style.pageTab,
              mode === "settled" ? style.selectedPageTab : "",
            )}
          >
            Settled
          </div>
        </div>
        <div className={style.table}>
          <InfiniteScroll
            dataLength={
              mode === "pending"
                ? pendingEntriesArray.length
                : settledEntriesArray.length
            } //This is important field to render the next data
            next={getEntries}
            hasMore={showMore}
            loader={
              <div>
                Loading Entries <Icon.Spinner size={30} />
              </div>
            }
          >
            {mode === "pending"
              ? pendingEntriesArray.map((entry) => (
                  <Entry entry={entry} key={entry.parlayBetPlacementId} />
                ))
              : settledEntriesArray.map((entry) => (
                  <Entry entry={entry} key={entry.parlayBetPlacementId} />
                ))}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

const Entry: FC<{
  entry: Models.Bet.ParlayBetPlacement;
}> = ({ entry }) => {
  return (
    <div className={style.entry}>
      <div className={style.item}>
        <div style={{ fontWeight: 700 }}>Created On</div>
        <div>{date.toShortDateAndTimeUTC(new Date(entry.createdOn))}</div>
      </div>
      <div className={style.smallItem}>{entry.userId}</div>
      <div className={style.smallItem}>
        ${entry.wager}{" "}
        {entry.currencyTypeId === Models.CurrencyType.USD ? "USD" : "Credit"}
      </div>
      {entry.betResultStatusId !== "PENDING" && (
        <div className={style.smallItem}>{entry.betResultStatusId}</div>
      )}
      <div className={style.legs}>
        {entry.legs.map((leg) => (
          <div className={style.leg} key={leg.legBetPlacementId}>
            <div>{decode(leg.prop.propLine)}</div>
            <div>
              {leg.prop.propPropOptions.map(
                (ppo) =>
                  ppo.propOptionId === leg.propOptionId && (
                    <div
                      className={style.legSelectedOption}
                      key={ppo.propOptionId}
                    >
                      {ppo.propOption.propText}
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const EntryTracker = connect(
  (state: RootState) => ({
    settledEntries: state.entries.settled.items,
    settledNextCursor: state.entries.settled.nextCursor,
    pendingEntries: state.entries.pending.items,
    pendingNextCursor: state.entries.pending.nextCursor,
  }),
  {},
)(EntryTrackerInternal);
