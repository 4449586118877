import React, { useEffect, FC } from "react";
import * as style from "./Analytics.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../gql";
import { connect } from "react-redux";
import * as Models from "../../models";
import { RootState } from "../../store/reducers";
import { colors } from "../../styles";
import { getMultiplier } from "../../utility/bet";

interface AnalyticDay {
  date: string;
  payout: number;
  totalHandle: number;
  scratched: number;
  hold: number;
  holdPercent: number;
  usdHandle: number;
  usdScratched: number;
  usdHold: number;
  usdHoldPercent: number;
}

export const AnalyticsInternal: FC = ({}) => {
  /* Apollo */
  const client = useApolloClient();

  const [entriesDict, setEntriesDict] = React.useState<
    Record<string, Models.Bet.ParlayBetPlacement[]>
  >({});
  const [nextCursor, setNextCursor] = React.useState<string | undefined>("");

  const getEntries = (after?: string) => {
    const nltDate = new Date();
    nltDate.setDate(nltDate.getDate() - 40);
    client
      .query({
        query: Query.GET_PARLAY_BET_PLACEMENTS_QUERY,
        variables: {
          first: 50,
          after,
          where: {
            and: {
              createdOn: {
                nlt: nltDate.toISOString(),
              },
            },
          },
        },
      })
      .then((res) => {
        if (res.data.parlayBetPlacements) {
          const newEntriesDict = { ...entriesDict };
          res.data.parlayBetPlacements.nodes.forEach(
            (entry: Models.Bet.ParlayBetPlacement) => {
              let entryDate = new Date(entry.createdOn);
              let entryDateString = entryDate.toLocaleDateString("en-US", {
                timeZone: "America/Los_Angeles",
              });
              if (!!newEntriesDict[entryDateString]) {
                newEntriesDict[entryDateString].push(entry);
              } else {
                newEntriesDict[entryDateString] = [entry];
              }
            },
          );
          setEntriesDict(newEntriesDict);
          setNextCursor(
            res.data.parlayBetPlacements.pageInfo.hasNextPage
              ? res.data.parlayBetPlacements.pageInfo.endCursor
              : "",
          );
        }
      });
  };

  useEffect(() => {
    getEntries();
  }, []);

  useEffect(() => {
    if (nextCursor) {
      getEntries(nextCursor);
    }
  }, [nextCursor]);

  const analyticsDict = React.useMemo(() => {
    const dict: Record<string, AnalyticDay> = {};
    if (!nextCursor) {
      Object.keys(entriesDict).forEach((key) => {
        let handle = 0;
        let payout = 0;
        let scratched = 0;
        let usdHandle = 0;
        let usdScratched = 0;
        entriesDict[key].forEach((entry) => {
          handle += entry.wager;
          if (entry.currencyTypeId == Models.CurrencyType.USD)
            usdHandle += entry.wager;
          switch (entry.betResultStatusId) {
            case Models.Bet.ResultingStatus.USER_WIN:
              payout += entry.wager * getMultiplier(entry.legs.length);
              break;
            case Models.Bet.ResultingStatus.CANCELED:
            case Models.Bet.ResultingStatus.CASH_REFUND:
            case Models.Bet.ResultingStatus.PUSHED:
            case Models.Bet.ResultingStatus.GOODWILL_REFUND:
            case Models.Bet.ResultingStatus.SCRATCHED:
              scratched += entry.wager;
              if (entry.currencyTypeId == Models.CurrencyType.USD)
                usdScratched += entry.wager;
              break;
          }
        });
        const hold = handle - payout - scratched;
        const holdPercent = (hold / handle) * 100;
        const usdHold = usdHandle - payout - usdScratched;
        const usdHoldPercent = (usdHold / usdHandle) * 100;
        dict[key] = {
          date: key,
          totalHandle: handle,
          payout,
          scratched,
          hold,
          holdPercent,
          usdHandle,
          usdHold,
          usdHoldPercent,
          usdScratched,
        };
      });
    }
    return dict;
  }, [nextCursor, entriesDict]);

  return (
    <>
      <div className={style.component}>
        <div className={style.title}>Analytics</div>
        <div className={style.table}>
          <div
            className={style.tableRow}
            style={{ fontWeight: 600, backgroundColor: colors.subtle }}
          >
            <div className={style.rowItem}>Date</div>
            <div className={style.rowItem}>Total Handle</div>
            <div className={style.rowItem}>USD Handle</div>
            <div className={style.rowItem}>Winnings</div>
            <div className={style.rowItem}>Total Scratched</div>
            <div className={style.rowItem}>USD Scratched</div>
            <div className={style.rowItem}>Total Hold</div>
            <div className={style.rowItem}>USD Hold</div>
            <div className={style.rowItem}>Hold %</div>
            <div className={style.rowItem}>USD Hold %</div>
          </div>
          {Object.values(analyticsDict)
            .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1))
            .map((day) => (
              <div key={day.date} className={style.tableRow}>
                <div className={style.rowItem}>{day.date}</div>
                <div className={style.rowItem}>{day.totalHandle}</div>
                <div className={style.rowItem}>{day.usdHandle}</div>
                <div className={style.rowItem}>{day.payout}</div>
                <div className={style.rowItem}>{day.scratched}</div>
                <div className={style.rowItem}>{day.usdScratched}</div>
                <div className={style.rowItem}>{day.hold}</div>
                <div className={style.rowItem}>{day.usdHold}</div>
                <div className={style.rowItem}>
                  {day.holdPercent.toFixed(2)}
                </div>
                <div className={style.rowItem}>
                  {day.usdHoldPercent.toFixed(2)}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export const Analytics = connect(
  (state: RootState) => ({
    props: state.props.props.pending.items,
    hasNext: state.props.props.pending.hasNext,
    nextCursor: state.props.props.pending.nextCursor,
  }),
  {},
)(AnalyticsInternal);
