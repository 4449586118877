import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * LEAGUES
   */
  GET_LEAGUES_SUCCESS = "LEAGUES/GET_LEAGUES_SUCCESS",
  UPDATE_LEAGUE_SUCCESS = "LEAGUES/UPDATE_LEAGUE_SUCCESS",
  /**
   * PLAYERS
   */
  GET_PLAYERS_SUCCESS = "LEAGUES/GET_PLAYERS_SUCCESS",
  UPDATE_PLAYER_SUCCESS = "LEAGUES/UPDATE_PLAYER_SUCCESS",
  /**
   * TEAMS
   */
  GET_TEAMS_SUCCESS = "LEAGUES/GET_TEAMS_SUCCESS",
  UPDATE_TEAM_SUCCESS = "LEAGUES/UPDATE_TEAM_SUCCESS",
}

export const GetLeaguesSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_LEAGUES_SUCCESS,
  {
    nodes: Models.Bet.League[];
    pageInfo: {
      hasNextPage: boolean;
      endCursor?: string;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_LEAGUES_SUCCESS);

export const UpdateLeagueSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_LEAGUE_SUCCESS,
  {
    league: Models.Bet.League;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_LEAGUE_SUCCESS);

export const GetPlayersSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_PLAYERS_SUCCESS,
  {
    nodes: Models.Bet.Player[];
    pageInfo: {
      hasNextPage: boolean;
      endCursor?: string;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_PLAYERS_SUCCESS);

export const UpdatePlayerSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_PLAYER_SUCCESS,
  {
    player: Models.Bet.Player;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_PLAYER_SUCCESS);

export const GetTeamsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_TEAMS_SUCCESS,
  {
    nodes: Models.Bet.Team[];
    pageInfo: {
      hasNextPage: boolean;
      endCursor?: string;
      hasPreviousPage: boolean;
      startCursor?: string;
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_TEAMS_SUCCESS);

export const UpdateTeamSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_TEAM_SUCCESS,
  {
    team: Models.Bet.Team;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_TEAM_SUCCESS);

export type Actions = ReturnType<
  | typeof GetLeaguesSuccess
  | typeof UpdateLeagueSuccess
  | typeof GetPlayersSuccess
  | typeof UpdatePlayerSuccess
  | typeof GetTeamsSuccess
  | typeof UpdateTeamSuccess
>;
