import React, { useState, useEffect } from "react";
import * as style from "./CreatePropOption.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation } from "../../gql";
import { Icon, TextInput, PlayerInfo } from "../../components";
import { connect, useDispatch } from "react-redux";
import { PropActions } from "../../store/actions";
import { RootState } from "../../store/reducers";
import * as Models from "../../models";
import { toast } from "react-toastify";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";

interface StateProps {
  propOptions: Record<string, Models.Props.PropOption> | null;
  players: Record<string, Models.Bet.Player> | null;
  teams: Record<string, Models.Bet.Team> | null;
  leagues: Record<string, Models.Bet.League> | null;
}

interface OwnProps {
  selectedOption?: Models.Props.PropOption;
  onOverride?: (input: Models.Props.CreatePropDecorationRequestInput) => void;
  onClose: () => void;
}

type ComponentProps = StateProps & OwnProps;

export const CreatePropOptionInternal: React.FC<ComponentProps> = ({
  propOptions,
  selectedOption,
  onOverride,
  onClose,
  players,
  teams,
  leagues,
}) => {
  const dispatch = useDispatch();

  const teamsArray = React.useMemo(() => {
    return Object.values(teams ?? {}).sort((a, b) =>
      a.displayName > b.displayName ? 1 : -1,
    );
  }, [teams]);
  const playersArray = React.useMemo(() => {
    return Object.values(players ?? {}).sort((a, b) =>
      a.name > b.name ? 1 : -1,
    );
  }, [players]);

  const [prefillSearch, setPrefillSearch] = useState("");
  const propOptionsArray = React.useMemo(() => {
    if (!propOptions) {
      return [];
    } else if (!!prefillSearch) {
      return Object.values(propOptions).filter((opt) =>
        opt.propText.toLowerCase().includes(prefillSearch.toLowerCase()),
      );
    } else {
      return Object.values(propOptions);
    }
  }, [propOptions, prefillSearch]);

  /* Values */
  const [value, setValue] = useState<string | undefined>(
    selectedOption?.propText ?? "Example",
  );
  const [validationError, setValidationError] = useState<string | undefined>(
    "",
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState(
    selectedOption?.defaultDecoration?.backgroundColor ?? "#000000",
  );
  const [fontColor, setFontColor] = useState(
    selectedOption?.defaultDecoration?.fontColor ?? "#FFFFFF",
  );

  // player info values and functions
  const [playerInfo, setPlayerInfo] = useState<
    Record<string, { playerId: number; teamId: number }>
  >({});

  const onPlayerInfoChange = (
    key: string,
    playerId: number,
    teamId: number,
  ) => {
    const newDict = { ...playerInfo };
    newDict[key] = {
      playerId,
      teamId:
        playerId !== playerInfo[key].playerId && !!players
          ? players[playerId]?.teamId ?? teamId
          : teamId,
    };
    setPlayerInfo(newDict);
    if (!selectedOption && players && players[playerId]) {
      setValue(players[playerId].name);
    }
  };

  const onAddPlayer = () => {
    const newDict = { ...playerInfo };
    newDict[Object.values(playerInfo).length.toString()] = {
      playerId: 0,
      teamId: 0,
    };
    setPlayerInfo(newDict);
  };

  const onRemovePlayer = (key: string) => {
    const newDict = { ...playerInfo };
    delete newDict[key];
    setPlayerInfo(newDict);
  };

  /** Image  */
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [optionImageB64, setOptionImageB64] = useState<string>("");
  const [optionImageFileUrl, setOptionImageFileUrl] = useState<string>("");
  const [propImageError, setPropImageError] = useState<string>("");
  useEffect(() => {
    if (selectedFile != null) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        setOptionImageB64(fileReader!.result!.toString());
      };
    }
  }, [selectedFile]);

  const [createPropOption, { data, loading, error }] = useMutation(
    Mutation.PROP_OPTION_CREATE_MUTATION,
  );
  const onSubmit = () => {
    const propDecorationPlayerInfo = Object.values(playerInfo).filter(
      (p) => p.playerId && p.teamId,
    );
    if (
      !!propOptionsArray.filter((option) => option.propText === value).length
    ) {
      setValidationError("That prop value already exists");
    } else if (
      !value ||
      !fontColor ||
      !backgroundColor ||
      !optionImageB64 ||
      !propDecorationPlayerInfo.length
    ) {
      setValidationError(
        "Please include a value, font color, bg color, image, and at least one Player selection",
      );
    } else {
      setSubmitting(true);
      setValidationError("");
      createPropOption({
        variables: {
          input: {
            createPropOptionRequest: {
              propValue: value,
              defaultDecoration: {
                fontColor,
                backgroundColor,
                optionImageB64,
                propDecorationPlayerInfo,
              },
            },
          },
        },
      })
        .then((res) => {
          setSubmitting(false);
          setValue("");
          if (res?.data?.propOptionCreate?.propOption) {
            dispatch(
              PropActions.CreatePropOptionSuccess({
                propOption: res.data.propOptionCreate.propOption,
              }),
            );
            toast.success("prop option created");
          } else {
            toast.error("prop option creation failed");
          }
          onClose();
        })
        .catch((e) => {
          toast.error("prop option creation failed");
          setSubmitting(false);
          console.error(e);
        });
    }
  };

  return (
    <div className={style.component}>
      <div style={{ flex: 1 }}>
        <div className={style.exampleWrapper}>
          <div
            className={style.example}
            style={{ backgroundColor, color: fontColor }}
          >
            {value}
          </div>
        </div>
        <div className={style.row}>
          <TextInput
            className={style.textInput}
            value={value}
            onChange={setValue}
            label="Value"
            disabled={!!selectedOption}
          />
          <div className={style.label}>Value</div>
        </div>
        <div className={style.row}>
          <HexAlphaColorPicker
            className={style.colorBox}
            color={backgroundColor}
            onChange={setBackgroundColor}
            prefix="#"
          />
          <HexColorInput
            color={backgroundColor}
            onChange={setBackgroundColor}
            alpha
          />
          <div className={style.label}>Background Color</div>
        </div>
        <div className={style.row}>
          <HexAlphaColorPicker
            className={style.colorBox}
            color={fontColor}
            onChange={setFontColor}
            prefix="#"
          />
          <HexColorInput color={fontColor} onChange={setFontColor} alpha />
          <div className={style.label}>Font Color</div>
        </div>
        {!optionImageB64 && !optionImageFileUrl && (
          <div className={style.input}>
            <input
              type="file"
              name="image"
              onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
              accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
            />
            {propImageError && (
              <div className={style.error}>{propImageError}</div>
            )}
          </div>
        )}
        {!!optionImageB64 && !optionImageFileUrl && (
          <>
            <div className={style.imageContainer}>
              <img
                src={optionImageB64}
                alt="prop image"
                className={style.image}
              />
            </div>
            <div
              onClick={() => {
                setSelectedFile(null);
                setOptionImageB64("");
              }}
              className={style.removeImageButton}
            >
              Remove image
            </div>
            {!!optionImageB64 && (
              <div
                className={style.removeImageButton}
                onClick={() => {
                  navigator.clipboard.writeText(optionImageB64);
                  toast.success("b64 copied to clipboard");
                }}
              >
                Copy Image B64
              </div>
            )}
          </>
        )}
        {!!optionImageFileUrl && (
          <>
            <div className={style.imageContainer}>
              <img
                src={optionImageFileUrl}
                alt="prop image"
                className={style.image}
              />
            </div>
            <div
              onClick={() => {
                setOptionImageFileUrl("");
              }}
              className={style.removeImageButton}
            >
              Remove image
            </div>
          </>
        )}
        <div
          onClick={
            !!onOverride
              ? () => {
                  const propDecorationPlayerInfo = Object.values(
                    playerInfo,
                  ).filter((pi) => !!pi.playerId && !!pi.teamId);
                  if (
                    !propDecorationPlayerInfo.length ||
                    !(optionImageB64 || optionImageFileUrl)
                  ) {
                    toast.info(
                      "Please include an image and player info when overriding a prop option decoration",
                    );
                  } else {
                    onOverride({
                      fontColor,
                      backgroundColor,
                      optionImageB64,
                      optionImageFileUrl,
                      propDecorationPlayerInfo,
                    });
                  }
                }
              : onSubmit
          }
          className={style.submit}
        >
          {submitting ? (
            <Icon.Spinner size={15} />
          ) : selectedOption ? (
            "Confirm Decoration"
          ) : (
            "Submit"
          )}
        </div>
        <div className={style.error}>{validationError}</div>
      </div>
      <div className={style.playerInfo}>
        {/* {hasNextTeams && (
          <div
            className={style.fetchButton}
            onClick={() => getTeams(teamsNextCursor)}
          >
            Fetch More Teams
          </div>
        )}
        {hasNextPlayers && (
          <div
            className={style.fetchButton}
            onClick={() => getPlayers(playersNextCursor)}
          >
            Fetch More Players
          </div>
        )} */}
        <div className={style.addPlayerButton} onClick={onAddPlayer}>
          Add Player
        </div>
        {Object.keys(playerInfo).map((playerInfoKey) => (
          <PlayerInfo
            key={playerInfoKey}
            onChange={(playerId, teamId) =>
              onPlayerInfoChange(playerInfoKey, playerId, teamId)
            }
            onRemove={() => onRemovePlayer(playerInfoKey)}
            playerId={playerInfo[playerInfoKey].playerId}
            teamId={playerInfo[playerInfoKey].teamId}
            teamsArray={teamsArray}
            playersArray={playersArray}
            leagues={leagues ?? {}}
          />
        ))}
        {selectedOption && (
          <>
            <div className={style.prefillTitle}>
              Select prop option to pre-fill override (this will not change the
              prop option - only the decoration override)
            </div>
            <input
              value={prefillSearch}
              onChange={(e) => setPrefillSearch(e.target.value)}
              style={{ marginBottom: 12 }}
              placeholder="filter"
            />
            <div className={style.prefillTable}>
              {propOptionsArray.map((option) => (
                <div
                  key={option.propOptionId}
                  className={style.prefillItem}
                  onClick={() => {
                    if (option.defaultDecoration) {
                      const optionPlayerInfo: Record<
                        string,
                        Models.Props.PlayerInfo
                      > = {};
                      option.defaultDecoration.propDecorationPlayerInfo.map(
                        (player, i) => {
                          optionPlayerInfo[i] = {
                            playerId: player.playerId,
                            teamId: player.teamId,
                          };
                        },
                      );
                      setPlayerInfo(optionPlayerInfo);
                      setOptionImageFileUrl(
                        option.defaultDecoration?.imageUrl ?? "",
                      );
                    }
                  }}
                >
                  {option.propText}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export const CreatePropOption = connect(
  (state: RootState) => ({
    propOptions: state.props.propOptions,
    players: state.players.players.items,
    teams: state.players.teams.items,
    leagues: state.players.leagues.items,
  }),
  {},
)(CreatePropOptionInternal);
