import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * ENTRIES
   */
  GET_ENTRIES_SUCCESS = "PROMOS/GET_ENTRIES_SUCCESS",
}

export const GetEntriesSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_ENTRIES_SUCCESS,
  {
    settled?: boolean;
    nodes: Models.Bet.ParlayBetPlacement[];
    pageInfo: {
      hasNextPage: boolean;
      nextCursor?: string;
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_ENTRIES_SUCCESS);

export type Actions = ReturnType<typeof GetEntriesSuccess>;
