import { gql } from "@apollo/client";

export const UPDATE_PROMO_CODE_MUTATION = gql`
  mutation UpdatePromoCodeMutation($input: UpdatePromoCodeInput!) {
    updatePromoCode(input: $input) {
      promoCode {
        id
        code
        promoAmount
        campaignId
        redemptionLimit
        totalRedeemed
        isActive
        createdAtUtc
        startedAtUtc
        endedAtUtc
      }
    }
  }
`;
