import { gql } from "@apollo/client";

export const GET_AUTHORIZATION_SETTINGS = gql`
  query AuthorizationSettings {
    authorizationSettings {
      id
      isAllowListRequired
    }
  }
`;
