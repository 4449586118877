import { gql } from "@apollo/client";

export const PROP_OPTION_CREATE_MUTATION = gql`
  mutation PropOptionCreateMutation($input: PropOptionCreateInput!) {
    propOptionCreate(input: $input) {
      propOption {
        propOptionId
        propText
        defaultDecorationId
        defaultDecoration {
          propDecorationId
          backgroundColor
          fontColor
          imageUrl
          propDecorationPlayerInfo {
            playerId
            teamId
          }
        }
      }
      errors {
        ... on MissingFieldsError {
          message
        }
      }
    }
  }
`;
