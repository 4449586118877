import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * USER
   */
  GET_USERS_SUCCESS = "USER/GET_USERS_SUCCESS",
  UPDATE_USER_SUCCESS = "USER/UPDATE_USER_SUCCESS",
  DELETE_USER_SUCCESS = "USER/DELETE_USER_SUCCESS",
}

export const GetUsersSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_USERS_SUCCESS,
  {
    users: {
      nodes: Models.Users.FirebaseUserEntity[];
    };
    hasNext: boolean;
    nextCursor?: string;
  }
> = ActionModels.createActionCreator(ActionTypes.GET_USERS_SUCCESS);

export const UpdateUserSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_USER_SUCCESS,
  Partial<Models.Users.FirebaseUserEntity> & { id: number }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_USER_SUCCESS);

export const DeleteUserSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.DELETE_USER_SUCCESS,
  { id: number }
> = ActionModels.createActionCreator(ActionTypes.DELETE_USER_SUCCESS);

export type Actions = ReturnType<
  typeof GetUsersSuccess | typeof UpdateUserSuccess | typeof DeleteUserSuccess
>;
