import { gql } from "@apollo/client";

export const PROP_UPDATE_PUBLISHING_STATUS = gql`
  mutation PropUpdatePublishingStatus(
    $input: PropUpdatePublishingStatusInput!
  ) {
    propUpdatePublishingStatus(input: $input) {
      prop {
        recordUpdatedBy
        updatedOnUtc
        propContent
        publishingStatus {
          publishingStatusName
        }
      }
      errors {
        ... on CannotUnpublishPublishedPropsError {
          message
        }
      }
    }
  }
`;
