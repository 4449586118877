import { gql } from "@apollo/client";

export const AUTHORIZATION_UPDATE_SETTINGS_MUTATION = gql`
  mutation AuthorizationUpdateSettings(
    $input: AuthorizationUpdateSettingsInput!
  ) {
    authorizationUpdateSettings(input: $input) {
      authorizationSettings {
        id
        isAllowListRequired
      }
    }
  }
`;
