import { style as s } from "typestyle";
import * as Style from "../../styles";

export const removePlayerButton = s({
  padding: 8,
  background: Style.colors.error,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 600,
  width: 100,
  marginBottom: 12,
  $nest: {
    "&:hover": {
      background: Style.colors.softError,
    },
  },
});

export const playerRow = s({
  display: "flex",
  gap: 32,
  padding: "4px 8px",
  borderRadius: 4,
  marginBottom: 12,
  justifyContent: "flex-end",
  alignItems: "center",
});

export const playerSection = s({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 6,
  alignItems: "center",
});

export const sectionLabel = s({
  fontSize: 12,
  fontWeight: 600,
});
