import React from "react";
import "./App.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as Provider,
  createHttpLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";

export const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  /** Create Apollo Client with Authentication Header */
  /**
   *
   * generates the connectionParams needed for a ws link
   */
  const connectionParams = () => {
    const userJwt = localStorage.getItem("userJwt");
    return {
      authorization: userJwt ? `Bearer ${userJwt}` : "",
    };
  };

  /**
   * creates the http link with authorization header from session storage
   */
  const httpLinkBase = createHttpLink({
    uri: process.env.GQL_HTTP_URI,
  });
  const httpAuthLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    const userJwt = localStorage.getItem("userJwt");
    return {
      headers: {
        ...headers,
        authorization: userJwt ? `Bearer ${userJwt}` : "",
      },
    };
  });
  const httpLink = httpAuthLink.concat(httpLinkBase);

  /**
   * Generates the subscription client and ws link
   */
  // const subscriptionClient = new SubscriptionClient(
  //   "wss://gqladmin.playunits.dev/graphql",
  //   {
  //     connectionParams,
  //     reconnect: true,
  //     reconnectionAttempts: 5,
  //     lazy: true,
  //   },
  // );
  // const wsLink = new WebSocketLink(subscriptionClient);

  /**
   * combines all the links and generates the apollo client
   */
  // const splitLink = split(
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return (
  //       definition.kind === "OperationDefinition" &&
  //       definition.operation === "subscription"
  //     );
  //   },
  //   wsLink,
  //   httpLink,
  // );
  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });

  return <Provider client={client}>{children}</Provider>;
};
