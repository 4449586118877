import * as React from "react";
import * as Models from "../../models";
import { Select, MenuItem } from "@mui/material";
import * as style from "./PlayerInfo.style";

interface PlayerInfoComponentProps {
  playerId: number;
  teamId: number;
  teamsArray: Models.Bet.Team[];
  playersArray: Models.Bet.Player[];
  onChange: (playerId: number, teamId: number) => void;
  onRemove: () => void;
  leagues: Record<string, Models.Bet.League>;
}

export const PlayerInfo: React.FC<PlayerInfoComponentProps> = ({
  playersArray,
  teamsArray,
  onChange,
  onRemove,
  teamId,
  playerId,
  leagues,
}) => {
  return (
    <div className={style.playerRow}>
      <div className={style.playerSection}>
        <div className={style.sectionLabel}>Player</div>
        <Select
          value={playerId}
          onChange={(e) => onChange(e.target.value as number, teamId)}
          autoWidth={true}
          label="Player"
        >
          {playersArray.map((player) => (
            <MenuItem key={player.id} value={player.id}>
              {player.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={style.playerSection}>
        <div className={style.sectionLabel}>Team</div>
        <Select
          value={teamId}
          onChange={(e) => onChange(playerId, e.target.value as number)}
          autoWidth={true}
          label="Team"
        >
          {teamsArray.map((team) => (
            <MenuItem key={team.id} value={team.id}>
              {team.displayName +
                (leagues[team.leagueId]
                  ? ` - (${leagues[team.leagueId].leagueCode})`
                  : "")}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={style.removePlayerButton} onClick={onRemove}>
        Remove Player
      </div>
    </div>
  );
};
