import * as React from "react";
import * as style from "./PlayerZone.style";
import { classes } from "typestyle";
import { Leagues } from "./Leagues";
import { Teams } from "./Teams";
import { Players } from "./Players";

interface ComponentProps {
  getTeams: (after?: string) => void;
  getPlayers: (after?: string) => void;
  getLeagues: (after?: string) => void;
}

export const PlayerZone: React.FC<ComponentProps> = ({
  getPlayers,
  getTeams,
  getLeagues,
}) => {
  const [tab, setTab] = React.useState("players");

  return (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("players")}
          className={classes(
            style.pageTab,
            tab === "players" ? style.selectedPageTab : "",
          )}
        >
          Players
        </div>
        <div
          onClick={() => setTab("teams")}
          className={classes(
            style.pageTab,
            tab === "teams" ? style.selectedPageTab : "",
          )}
        >
          Teams
        </div>
        <div
          onClick={() => setTab("leagues")}
          className={classes(
            style.pageTab,
            tab === "leagues" ? style.selectedPageTab : "",
          )}
        >
          Leagues
        </div>
      </div>
      {tab === "players" && (
        <Players getPlayers={getPlayers} getTeams={getTeams} />
      )}
      {tab === "teams" && <Teams getTeams={getTeams} />}
      {tab === "leagues" && <Leagues />}
    </div>
  );
};
