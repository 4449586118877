import React, { useEffect, FC } from "react";
import * as style from "./Users.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import { RootState } from "../../store/reducers";
import { useFirebaseContext } from "../../auth";
import { Icon, Modal, TextInput } from "../../components";
import { UserActions } from "../../store/actions";
import { date } from "../../utility";
import { classes } from "typestyle";
import { User } from "./User/User";
import { toast } from "react-toastify";

interface StateProps {
  users: Models.Users.FirebaseUserEntity[] | null;
  hasNext: boolean;
  nextCursor?: string;
}

export const UsersInternal: FC<StateProps> = ({
  users,
  hasNext,
  nextCursor,
}) => {
  /* Apollo */
  const client = useApolloClient();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();
  /* Values */
  const [selectedUser, setSelectedUser] = React.useState<number | null>(null);
  /* Filters */
  const [nameFilter, setNameFilter] = React.useState("");
  const [idSearchValue, setIdSearchValue] = React.useState("");
  const [emailFilter, setEmailFilter] = React.useState("");
  const [accountStatusFilter, setAccountStatusFilter] = React.useState("");
  const [hasGottenLastUsers, setHasGottenLastUsers] = React.useState(false);

  const filteredUsers = React.useMemo(() => {
    return users?.filter(
      (user) =>
        (!nameFilter ||
          (!!user.userProfile &&
            `${user.userProfile.firstName} ${user.userProfile.lastName}`
              .toLowerCase()
              .includes(nameFilter.toLowerCase()))) &&
        (!emailFilter ||
          user.email.toLowerCase().includes(emailFilter.toLowerCase())) &&
        (!accountStatusFilter ||
          user.playerProfile.accountStatus
            .toLowerCase()
            .includes(accountStatusFilter.toLowerCase())),
    );
  }, [users, nameFilter, emailFilter, accountStatusFilter]);

  const getUsers = (getLastUsers?: boolean) => {
    if (userJwt) {
      setLoading(true);
      client
        .query({
          query: Query.GET_USERS_QUERY,
          variables: !!getLastUsers
            ? {
                last: 20,
              }
            : {
                first: 20,
                after: !!nextCursor ? nextCursor : undefined,
              },
        })
        .then((res) => {
          if (getLastUsers) {
            setHasGottenLastUsers(true);
          }
          setLoading(false);
          dispatch(
            UserActions.GetUsersSuccess({
              users: res.data.users,
              hasNext: !!getLastUsers
                ? hasNext
                : res.data.users.pageInfo.hasNextPage,
              nextCursor:
                !!getLastUsers && nextCursor
                  ? nextCursor
                  : res.data.users.pageInfo.endCursor,
            }),
          );
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const searchUsers = () => {
    if (userJwt) {
      setLoading(true);
      client
        .query({
          query: Query.SEARCH_USER_BY_EMAIL_QUERY,
          variables: {
            email: emailFilter,
          },
        })
        .then((res) => {
          setLoading(false);
          dispatch(
            UserActions.GetUsersSuccess({
              users: { nodes: res.data.searchUserByEmail },
              hasNext,
              nextCursor,
            }),
          );
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const getUserById = () => {
    var parsedId = Number.parseInt(idSearchValue);
    if (parsedId && !isNaN(parsedId)) {
      setLoading(true);
      client
        .query({
          query: Query.GET_USER_BY_ID_QUERY,
          variables: {
            id: parsedId,
          },
        })
        .then((res) => {
          setLoading(false);
          if (res.data.user) {
            dispatch(
              UserActions.GetUsersSuccess({
                users: { nodes: [res.data.user] },
                hasNext,
                nextCursor,
              }),
            );
          } else {
            toast.info("Cannot find user with that id");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      toast.error("Please enter a number");
    }
  };

  useEffect(() => {
    getUsers();
  }, [userJwt]);

  return (
    <div className={style.component}>
      {!!users ? (
        <div className={style.usersGrid}>
          <div className={style.gridRow}>
            <div className={style.smallItem}>
              <div style={{ fontWeight: 600 }}>User Id</div>
              <input
                className={style.filterContainer}
                value={idSearchValue}
                onChange={(e) => setIdSearchValue(e.target.value)}
                placeholder="search by id"
              />
              <div className={style.searchButton} onClick={getUserById}>
                {loading ? <Icon.Spinner size={12} /> : "Search"}
              </div>
            </div>
            <div className={style.gridItem}>
              <div style={{ fontWeight: 600 }}>Name</div>
              <input
                className={style.filterContainer}
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
                placeholder="name search"
              />
            </div>
            <div
              className={classes(style.gridItem, style.largeItem)}
              style={{ fontWeight: 600 }}
            >
              <div style={{ fontWeight: 600 }}>Email</div>
              <input
                className={style.filterContainer}
                value={emailFilter}
                onChange={(e) => setEmailFilter(e.target.value)}
                placeholder="email search"
              />
              <div className={style.searchButton} onClick={searchUsers}>
                {loading ? <Icon.Spinner size={12} /> : "Search"}
              </div>
            </div>
            <div className={style.gridItem} style={{ fontWeight: 600 }}>
              <div style={{ fontWeight: 600 }}>Account Status</div>
              <input
                className={style.filterContainer}
                value={accountStatusFilter}
                onChange={(e) => setAccountStatusFilter(e.target.value)}
                placeholder="status search"
              />
            </div>
            <div className={style.gridItem} style={{ fontWeight: 600 }}>
              Last Sign In
            </div>
          </div>
          {!!filteredUsers &&
            filteredUsers.map((user) => (
              <div
                className={style.gridRow}
                key={user.id}
                onClick={() => setSelectedUser(user.id)}
              >
                <div className={style.smallItem}>{user.id}</div>
                <div className={style.gridItem}>
                  {user.userProfile ? (
                    user.userProfile.firstName + " " + user.userProfile.lastName
                  ) : (
                    <span style={{ fontWeight: 700 }}>no user profile</span>
                  )}
                </div>
                <div className={classes(style.gridItem, style.largeItem)}>
                  {user.email}
                </div>
                <div className={style.gridItem}>
                  {user.playerProfile?.accountStatus}
                </div>
                <div className={style.gridItem}>
                  {!!user.lastSignInDateUtc
                    ? date.toShortDateAndTimeUTC(
                        new Date(user.lastSignInDateUtc),
                      )
                    : "None"}
                </div>
              </div>
            ))}
          {!!hasNext && (
            <div className={style.buttonContainer}>
              <div
                className={classes(
                  style.updateButton,
                  !hasNext ? style.disabled : "",
                )}
                onClick={!hasNext ? undefined : () => getUsers(false)}
              >
                {loading ? <Icon.Spinner size={15} /> : "Get More Users"}
              </div>
              {!hasGottenLastUsers && (
                <div
                  className={classes(
                    style.updateButton,
                    !hasNext ? style.disabled : "",
                  )}
                  onClick={!hasNext ? undefined : () => getUsers(true)}
                >
                  {loading ? <Icon.Spinner size={15} /> : "Get Last Users"}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          Loading Users <Icon.Spinner size={30} />
        </div>
      )}
      {!!selectedUser && (
        <Modal isOpen={true} onRequestClose={() => setSelectedUser(null)}>
          <User
            onClose={(deletedUser?: number) => {
              setSelectedUser(null);
              if (deletedUser) {
                dispatch(UserActions.DeleteUserSuccess({ id: deletedUser }));
              }
            }}
            userId={selectedUser}
          />
        </Modal>
      )}
    </div>
  );
};

export const Users = connect(
  (state: RootState) => ({
    users: !!state.users.users.items
      ? Object.values(state.users.users.items)
      : null,
    hasNext: state.users.users.hasNext,
    nextCursor: state.users.users.nextCursor,
  }),
  {},
)(UsersInternal);
