import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./configureStore";
import "./App.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FirebaseContextProvider } from "../auth";
import * as Pages from "../pages";
import { DisplayContextProvider } from "./DisplayContext";
import { Header } from "../components";
import { ApolloProvider } from "./ApolloProvider";

// Create Redux Store
const store = getStore();

// Handle Routing
const Page: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pages.Home />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
};

export function App() {
  return (
    <ApolloProvider>
      <FirebaseContextProvider>
        <Provider store={store}>
          <BrowserRouter>
            <DisplayContextProvider>
              <Header />
              <div className="App">
                <Page />
              </div>
            </DisplayContextProvider>
            <ToastContainer
              autoClose={5000}
              position="top-right"
              newestOnTop={false}
              hideProgressBar={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
            />
          </BrowserRouter>
        </Provider>
      </FirebaseContextProvider>
    </ApolloProvider>
  );
}
