import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 80,
  fontSize: 16,
  fontFamily: Style.text.font.main,
  textAlign: "center",
});

export const title = s({
  fontSize: 42,
  lineHeight: "48px",
  marginBottom: 20,
});

export const submit = s({
  padding: 15,
  background: Style.colors.primary,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 600,
  width: 300,
  margin: "auto",
  $nest: {
    "&:hover": {
      background: Style.colors.softYellow,
    },
  },
});
