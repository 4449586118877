import React from "react";
import * as style from "./BulkCredit.style";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../gql";
import { Icon, Modal, TextInput } from "../../components";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { colors } from "../../styles";

interface ComponentProps {
  onClose: () => void;
}

export const BulkCredit: React.FC<ComponentProps> = ({ onClose }) => {
  const [amountInput, setAmountInput] = React.useState<string>("0");
  const [inputError, setInputError] = React.useState<string>("");
  const [clarifying, setClarifying] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [refId, setRefId] = React.useState(uuidv4());

  const [bulkAddSiteCredits, { data, loading, error }] = useMutation(
    Mutation.WALLET_BULK_ADD_SITE_CREDITS,
  );
  const onSubmit = () => {
    const amount = Number.parseFloat(amountInput);
    if (isNaN(amount)) {
      setInputError("Please enter a valid amount");
      setClarifying(false);
    } else if (amount <= 0) {
      setInputError("Please enter a number greater than 0");
      setClarifying(false);
    } else if (typeof amount === "number" && amount > 0) {
      setSubmitting(true);
      setInputError("");
      bulkAddSiteCredits({
        variables: {
          input: {
            transactionAmount: amount,
            refId,
          },
        },
      })
        .then((res) => {
          if (res.data.walletBulkAddSiteCredits.boolean) {
            toast.success("successfully credited all users");
          } else {
            toast.error(
              !!res.data.walletBulkAddSiteCredits.errors
                ? res.data.walletBulkAddSiteCredits.errors[0].message
                : "Bulk credit error",
            );
          }
          setSubmitting(false);
          onClose();
        })
        .catch((e) => {
          toast.error(
            "bulk credit error occurred, please check your input and try again",
          );
          setSubmitting(false);
          console.error(e);
        });
    }
  };

  return (
    <Modal isOpen={true} onRequestClose={onClose}>
      <div className={style.component}>
        <div className={style.title}>
          This will bulk credit <span style={{ fontWeight: 700 }}>all</span>{" "}
          users
        </div>
        <TextInput
          wrapperClassName={style.inputWrapper}
          label="amount"
          error={inputError}
          className={style.input}
          value={amountInput}
          onChange={setAmountInput}
        />
        <div
          onClick={!clarifying ? () => setClarifying(true) : onSubmit}
          className={style.submit}
        >
          {submitting ? (
            <Icon.Spinner
              size={20}
              fill={colors.black1}
              stroke={colors.black1}
            />
          ) : clarifying ? (
            "Are you sure?"
          ) : (
            "Submit"
          )}
        </div>
      </div>
    </Modal>
  );
};
