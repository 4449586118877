import { DateTimePickerProps } from "@mui/x-date-pickers";
import { Prop, PropOption, PropTag } from "./prop";
import { PageInfo, CurrencyType, Maybe, Scalars } from "./shared";

export type PlacementResponse = {
  __typename?: "PlacementResponse";
  transactionId: Scalars["String"];
};

export type UnitsBetPlacementRequestInput = {
  stake: Scalars["Decimal"];
  legs: Array<ParlayLegBetPlacementInput>;
  currencyType: CurrencyType;
  transactionId: Scalars["UUID"];
};

export type ParlayLegBetPlacementInput = {
  propExternalId: Scalars["UUID"];
  propOptionId: Scalars["Long"];
};

export type UserBetPlacementsConnection = {
  __typename?: "UserBetPlacementsConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<UserBetPlacementsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ParlayBetPlacement>>;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type UserBetPlacementsEdge = {
  __typename?: "UserBetPlacementsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ParlayBetPlacement;
};

export type ParlayBetPlacement = {
  __typename?: "ParlayBetPlacement";
  parlayBetPlacementId: Scalars["Long"];
  transactionId: Scalars["UUID"];
  userId: Scalars["Int"];
  createdOn: Scalars["DateTime"];
  updatedOn: Scalars["DateTime"];
  wager: Scalars["Decimal"];
  legs: Array<ParlayLegBetPlacement>;
  currencyTypeId: CurrencyType;
  betResultStatusId: ResultingStatus;
};

export enum ResultingStatus {
  PENDING = "PENDING",
  USER_WIN = "USER_WIN",
  USER_LOSS = "USER_LOSS",
  PUSHED = "PUSHED",
  SCRATCHED = "SCRATCHED",
  CANCELED = "CANCELED",
  GOODWILL_REFUND = "GOODWILL_REFUND",
  CASH_REFUND = "CASH_REFUND",
}

export type ParlayLegBetPlacement = {
  __typename?: "ParlayLegBetPlacement";
  parlayBetPlacement: ParlayBetPlacement;
  legBetPlacementId: Scalars["Long"];
  parlayBetPlacementId: Scalars["Long"];
  propOptionId: Scalars["Long"];
  propOption: PropOption;
  propInternalId: Scalars["Long"];
  prop: Prop;
};

export type PlacementStatusResponse = {
  __typename?: "PlacementStatusResponse";
  workflowState: Scalars["String"];
  engineState: Scalars["String"];
};

export interface League {
  id: number;
  name: string;
  leagueCode: string;
  country?: string;
  logoUrl?: string;
}

export interface Team {
  id: number;
  leagueId: number;
  teamCode: string;
  name: string;
  displayName: string;
  city?: string;
  country?: string;
  conference?: string;
  division?: string;
  primaryColor?: string;
  secondaryColor?: string;
  logoUrl?: string;
}

export interface Player {
  id: number;
  teamId: number;
  name: string;
  jersey?: number;
}

export interface Contest {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  tags: PropTag[];
  contestStartsAtUtc: Date;
  contestEndsAtUtc: Date;
  viewableStartsAtUtc: Date;
  viewableEndsAtUtc: Date;
  createdAtUtc: Date;
  updatedAtUtc: Date;
  reward1?: number;
  reward2?: number;
  reward3?: number;
  reward4?: number;
  reward5?: number;
}
