import { gql } from "@apollo/client";

export const UPDATE_TEAM_MUTATION = gql`
  mutation UpdateTeamMutation($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      team {
        id
        leagueId
        name
        displayName
        teamCode
      }
      errors {
        ... on TeamExistsError {
          message
        }
      }
    }
  }
`;
