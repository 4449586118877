import { gql } from "@apollo/client";

export const GET_USER_CLAIMS_QUERY = gql`
  query GetUserClaims($where: UserClaimsEntityFilterInput) {
    userClaims(where: $where) {
      userClaimId
      userId
      unitsClaimId
      userClaimValue
      unitsClaim {
        unitsClaimId
        claimName
        claimDefaultValue
        assignClaimOnUserCreation
        unitsClaimType
        claimValues {
          unitsClaimValueId
          unitsClaimId
          unitsClaimDescription
          unitsClaimEnumString
        }
      }
    }
  }
`;
