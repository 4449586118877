import * as Models from "../../models";
import { UserActions } from "../actions";

export interface UserStore {
  users: {
    items: Record<string, Models.Users.FirebaseUserEntity> | null;
    hasNext: boolean;
    nextCursor?: string;
  };
}

export const initialState: UserStore = {
  users: {
    items: null,
    hasNext: false,
  },
};

export const users = (
  state: UserStore = initialState,
  action: UserActions.Actions,
): UserStore => {
  switch (action.type) {
    case UserActions.ActionTypes.GET_USERS_SUCCESS: {
      const newDict: Record<string, Models.Users.FirebaseUserEntity> = state
        .users.items
        ? { ...state.users.items }
        : {};
      action.payload.users.nodes.forEach((user) => {
        newDict[user.id] = user;
      });
      return {
        ...state,
        users: {
          items: newDict,
          hasNext: action.payload.hasNext,
          nextCursor: action.payload.nextCursor,
        },
      };
    }
    case UserActions.ActionTypes.UPDATE_USER_SUCCESS: {
      const newDict: Record<string, Models.Users.FirebaseUserEntity> = state
        .users.items
        ? { ...state.users.items }
        : {};
      newDict[action.payload.id] = {
        ...newDict[action.payload.id],
        ...action.payload,
      };
      return {
        ...state,
        users: {
          ...state.users,
          items: newDict,
        },
      };
    }
    case UserActions.ActionTypes.DELETE_USER_SUCCESS: {
      if (state.users.items) {
        const newDict = { ...state.users.items };
        delete newDict[action.payload.id];
        return {
          ...state,
          users: {
            ...state.users,
            items: newDict,
          },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};
