import { gql } from "@apollo/client";

export const GET_PLAYERS_QUERY = gql`
  query players(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: PlayerFilterInput
    $order: [PlayerSortInput!]
  ) {
    players(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      order: $order
    ) {
      nodes {
        id
        name
        teamId
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
