import React, { useEffect, FC } from "react";
import * as style from "./SortProps.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { PropActions } from "../../store/actions";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import { classes } from "typestyle";
import { RootState } from "../../store/reducers";
import { useFirebaseContext } from "../../auth";
import { date } from "../../utility";
import { decode } from "html-entities";
import { toast } from "react-toastify";
import { Icon } from "../../components";

interface StateProps {
  props: Record<string, Models.Props.Prop> | null;
  hasNext: boolean;
  nextCursor?: string;
}

export const SortPropsInternal: FC<StateProps> = ({
  props,
  nextCursor,
  hasNext,
}) => {
  /* Apollo */
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedPropExternalId, setSelectedPropExternalId] = React.useState<
    string | null
  >(null);
  const { userJwt } = useFirebaseContext();
  const [sortedPropsArray, setSortedPropsArray] = React.useState<
    Models.Props.Prop[]
  >([]);
  const [unsortedPropsArray, setUnsortedPropsArray] = React.useState<
    Models.Props.Prop[]
  >([]);

  const [updateSortOrder, { data, loading, error }] = useMutation(
    Mutation.UPDATE_PROP_SORT_ORDER,
  );

  const getProps = () => {
    if (userJwt) {
      const nltDate = new Date();
      nltDate.setDate(nltDate.getDate() - 14);
      client
        .query({
          query: Query.GET_PROPS_QUERY,
          variables: {
            first: 10,
            after: !!nextCursor ? nextCursor : undefined,
            where: {
              propStatus: {
                propStatusName: {
                  eq: "Pending",
                },
              },
              publishedOnUtc: {
                nlt: nltDate.toISOString(),
              },
            },
            order: {
              closeTimeUtc: "DESC",
            },
          },
        })
        .then((res) => {
          dispatch(
            PropActions.GetPropsSuccess({
              props: res.data.props,
              hasNext: res.data.props.pageInfo.hasNextPage,
              nextCursor: res.data.props.pageInfo.endCursor,
            }),
          );
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    getProps();
  }, [userJwt]);

  useEffect(() => {
    if (hasNext && nextCursor) {
      getProps();
    }
  }, [hasNext, nextCursor]);

  React.useEffect(() => {
    if (!!props) {
      const sorted: Models.Props.Prop[] = [];
      const unsorted: Models.Props.Prop[] = [];
      Object.values(props).forEach((prop) => {
        if (!!prop.sortOrder) {
          sorted.push({ ...prop });
        } else {
          unsorted.push({ ...prop });
        }
      });
      setSortedPropsArray(sorted);
      setUnsortedPropsArray(unsorted);
    }
  }, [props]);

  const onUpdateSortOrder = (externalId: string, index: number) => {
    if (props && props[externalId]) {
      const propToSort =
        unsortedPropsArray.find((p) => p.propExternalId === externalId) ??
        sortedPropsArray.find((p) => p.propExternalId === externalId);
      if (propToSort) {
        const newUnsorted = [...unsortedPropsArray].filter(
          (prop) => prop.propExternalId !== externalId,
        );
        const newSorted: Models.Props.Prop[] = [];
        let currentOrder = 1;
        sortedPropsArray.forEach((prop, i) => {
          if (i < index) {
            if (prop.propExternalId !== externalId) {
              newSorted.push({
                ...prop,
                sortOrder: currentOrder,
              });
              currentOrder++;
            }
          } else {
            if (i === index) {
              newSorted.push({
                ...propToSort,
                sortOrder: currentOrder,
              });
              currentOrder++;
            }
            if (prop.propExternalId !== externalId) {
              newSorted.push({
                ...prop,
                sortOrder: currentOrder,
              });
              currentOrder++;
            }
          }
        });
        if (index === sortedPropsArray.length) {
          newSorted.push({
            ...propToSort,
            sortOrder: currentOrder,
          });
        }
        setSortedPropsArray(newSorted);
        setUnsortedPropsArray(newUnsorted);
        setSelectedPropExternalId(null);
      }
    }
  };

  const onUnsortProp = (externalId: string) => {
    if (
      props &&
      props[externalId] &&
      sortedPropsArray.find((p) => p.propExternalId === externalId)
    ) {
      setUnsortedPropsArray([
        ...unsortedPropsArray,
        { ...props[externalId], sortOrder: null },
      ]);
      const newSorted: Models.Props.Prop[] = [];
      let currentOrder = 1;
      sortedPropsArray.forEach((prop, i) => {
        if (prop.propExternalId !== externalId) {
          newSorted.push({ ...prop, sortOrder: currentOrder });
          currentOrder++;
        }
      });
      setSortedPropsArray(newSorted);
    }
    setSelectedPropExternalId(null);
  };

  const onSubmit = () => {
    if (props) {
      const submissionArray: { propExternalId: string; sortOrder?: number }[] =
        [];
      [...sortedPropsArray, ...unsortedPropsArray].forEach((prop) => {
        if (prop.sortOrder !== props[prop.propExternalId].sortOrder) {
          submissionArray.push({
            propExternalId: prop.propExternalId,
            sortOrder: !!prop.sortOrder ? prop.sortOrder : undefined,
          });
        }
      });
      if (submissionArray.length) {
        updateSortOrder({
          variables: {
            input: {
              sortOrder: submissionArray,
            },
          },
        }).then((res) => {
          if (res?.data?.updatePropSortOrder?.prop?.length) {
            res.data.updatePropSortOrder.prop.forEach(
              (prop: Models.Props.Prop) => {
                dispatch(
                  PropActions.UpdatePropSuccess({
                    prop,
                  }),
                );
              },
            );
          }
          toast.success("Successfully updated sort order");
        });
      } else {
        toast.warn("No changes to submit");
      }
    }
  };

  return (
    <div className={style.component}>
      <div className={style.buttonContainer}>
        <div className={style.button} onClick={onSubmit}>
          {loading ? <Icon.Spinner size={24} /> : "Submit Changes"}
        </div>
      </div>
      <div className={style.container}>
        <div className={style.selectionColumn}>
          <div className={style.columnTitle}>Sorted</div>
          <div
            className={classes(
              style.propSeparaterContainer,
              !!selectedPropExternalId
                ? style.activePropSeparaterContainer
                : "",
            )}
            onClick={() => {
              if (selectedPropExternalId) {
                onUpdateSortOrder(selectedPropExternalId, 0);
              }
            }}
          >
            <div className={style.propSeparater} />
          </div>
          {sortedPropsArray
            .sort((a, b) =>
              (a.sortOrder ?? 1000) < (b.sortOrder ?? 1000) ? -1 : 1,
            )
            .map((prop, i) => (
              <div key={prop.propExternalId}>
                <SortProp
                  prop={prop}
                  onClick={(propExternalId: string) => {
                    setSelectedPropExternalId(propExternalId);
                  }}
                  isSelected={prop.propExternalId === selectedPropExternalId}
                />
                <div
                  className={classes(
                    style.propSeparaterContainer,
                    !!selectedPropExternalId
                      ? style.activePropSeparaterContainer
                      : "",
                  )}
                  onClick={() => {
                    if (selectedPropExternalId) {
                      onUpdateSortOrder(selectedPropExternalId, i + 1);
                    }
                  }}
                >
                  <div className={style.propSeparater} />
                </div>
              </div>
            ))}
          {selectedPropExternalId && (
            <div
              className={style.emptySortedSpace}
              onClick={() => {
                if (selectedPropExternalId) {
                  onUpdateSortOrder(
                    selectedPropExternalId,
                    sortedPropsArray.length,
                  );
                }
              }}
            />
          )}
        </div>
        <div className={style.selectionColumn}>
          <div className={style.columnTitle}>Not Sorted</div>
          <div
            className={classes(
              style.propSeparaterContainer,
              !!selectedPropExternalId
                ? style.activePropSeparaterContainer
                : "",
            )}
            onClick={() => {
              if (selectedPropExternalId) {
                onUnsortProp(selectedPropExternalId);
              }
            }}
          >
            <div className={style.propSeparater} />
          </div>
          {unsortedPropsArray
            .sort((a, b) =>
              a.propLine === b.propLine
                ? a.propExternalId > b.propExternalId
                  ? 1
                  : -1
                : a.propLine > b.propLine
                ? 1
                : -1,
            )
            .map((prop) => (
              <SortProp
                className={style.unsortedProp}
                prop={prop}
                onClick={(propExternalId: string) => {
                  setSelectedPropExternalId(propExternalId);
                }}
                isSelected={prop.propExternalId === selectedPropExternalId}
                key={prop.propExternalId}
              />
            ))}
          <div
            className={classes(
              style.propSeparaterContainer,
              !!selectedPropExternalId
                ? style.activePropSeparaterContainer
                : "",
            )}
            onClick={() => {
              if (selectedPropExternalId) {
                onUnsortProp(selectedPropExternalId);
              }
            }}
          >
            <div className={style.propSeparater} />
          </div>
          {selectedPropExternalId && (
            <div
              className={style.emptySortedSpace}
              onClick={() => {
                if (selectedPropExternalId) {
                  onUnsortProp(selectedPropExternalId);
                }
              }}
            />
          )}
        </div>
      </div>
      {hasNext && (
        <div className={style.buttonContainer}>
          <div className={style.button} onClick={getProps}>
            {loading ? <Icon.Spinner size={24} /> : "Get More Props"}
          </div>
        </div>
      )}
    </div>
  );
};

const SortProp: FC<{
  className?: string;
  prop: Models.Props.Prop;
  onClick: (externalId: string) => void;
  isSelected: boolean;
}> = ({ prop, onClick, isSelected, className }) => {
  return (
    <div
      className={classes(
        style.prop,
        isSelected ? style.selectedProp : "",
        className,
      )}
      onClick={() => onClick(prop.propExternalId)}
    >
      <div className={style.propTitle}>{decode(prop.title)}</div>
      <div className={style.propTitle}>{decode(prop.propLine)}</div>
      <div className={style.item} style={{ fontWeight: 600 }}>
        {prop.publishingStatus.publishingStatusName}
      </div>
      <div className={style.item}>
        {prop.propSpecials.length ? prop.propSpecials[0].name : ""}
      </div>
      <div className={style.item}>
        <div>{date.toShortDateAndTimeUTC(new Date(prop.startTimeUtc))}</div>
      </div>
      {!!prop.sortOrder && <div className={style.item}>{prop.sortOrder}</div>}
    </div>
  );
};

export const SortProps = connect(
  (state: RootState) => ({
    props: state.props.props.pending.items,
    hasNext: state.props.props.pending.hasNext,
    nextCursor: state.props.props.pending.nextCursor,
  }),
  {},
)(SortPropsInternal);
