import * as React from "react";
import { DisplayContext } from "../core/DisplayContext";

export const useScript = (url: string) => {
  React.useEffect(() => {
    const script = document.createElement("script");

    const scriptText = document.createTextNode(url);

    script.appendChild(scriptText);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url]);
};

export const useDisplayContext = () => {
  const context = React.useContext(DisplayContext);
  return context;
};

export const useIntersectionObserver = (
  elementRef: React.MutableRefObject<any>,
  { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false }
) => {
  const [entry, setEntry] = React.useState<IntersectionObserverEntry>();

  const frozen = entry && entry.isIntersecting && freezeOnceVisible;

  const updateEntry = (entries: IntersectionObserverEntry[]) => {
    setEntry(entries[0]);
  };

  React.useEffect(() => {
    const node = elementRef && elementRef.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return entry;
};

export const useFocus = (): [React.MutableRefObject<any>, () => void] => {
  const htmlElRef: any = React.useRef(null);
  const setFocus = () => {
    if (htmlElRef.current) {
      htmlElRef.current.focus();
    }
  };

  return [htmlElRef, setFocus];
};
