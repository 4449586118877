import { style as s } from "typestyle";
import * as Style from "../styles";

export const component = s({
  fontFamily: Style.text.font.main,
  padding: 10,
  color: Style.text.colors.main,
  backgroundColor: Style.colors.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 12,
  cursor: "pointer",
  boxSizing: "border-box",
});

export const content = s({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "",
  alignItems: "center",
  fontSize: 15,
  fontWeight: 500,
  height: 27,
});

export const logoContainer = s({
  width: 35,
  height: 27,
  textAlign: "center",
});

export const logo = s({
  position: "absolute",
  left: 0,
  top: 0,
  bottom: 0,
  height: 27,
  width: 27,
});
