import React, { useEffect, FC } from "react";
import * as style from "./EditUser.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import { useFirebaseContext } from "../../../auth";
import { DatePicker, Icon, TextInput } from "../../../components";
import { UserActions } from "../../../store/actions";
import { date } from "../../../utility";
import { classes } from "typestyle";
import { MenuItem, Select, Checkbox } from "@mui/material";
import { toast } from "react-toastify";

interface ComponentProps {
  user: Models.Users.FirebaseUserEntity;
  onClose: () => void;
}

export const EditUser: FC<ComponentProps> = ({ user, onClose }) => {
  /* Apollo */
  const client = useApolloClient();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();
  /* Page */
  const [tab, setTab] = React.useState("userProfile");
  /* Values */
  const [firstName, setFirstName] = React.useState(user.userProfile?.firstName);
  const [lastName, setLastName] = React.useState(user.userProfile?.lastName);
  const [submitUserError, setSubmitUserError] = React.useState("");
  const [dateOfBirth, setDateOfBirth] = React.useState<Date | null>(
    !!user.userProfile?.dateOfBirth
      ? new Date(user.userProfile.dateOfBirth)
      : null,
  );
  const [email, setEmail] = React.useState(user.email);
  // const [addressLine1, setAddressLine1] = React.useState(
  //   user.userProfile?.mailingAddress?.addressLine1,
  // );
  // const [addressLine2, setAddressLine2] = React.useState(
  //   user.userProfile?.mailingAddress?.addressLine2,
  // );
  // const [city, setCity] = React.useState(
  //   user.userProfile?.mailingAddress?.city,
  // );
  // const [friendlyName, setFriendlyName] = React.useState(
  //   user.userProfile?.mailingAddress?.friendlyName,
  // );
  // const [stateCode, setStateCode] = React.useState(
  //   user.userProfile?.mailingAddress?.stateCode,
  // );
  // const [zipCode, setZipCode] = React.useState(
  //   user.userProfile?.mailingAddress?.zipCode,
  // );
  const [phone, setPhone] = React.useState(user.userProfile?.cellPhoneNumber);
  const [accountStatus, setAccountStatus] = React.useState(
    user.playerProfile.accountStatus,
  );
  const [kycStatus, setKycStatus] = React.useState(
    user.playerProfile.kycStatus,
  );
  const [termsAndConditionsStatus, setTermsAndConditionsStatus] =
    React.useState(user.playerProfile.termsAndConditionsStatus);
  const [selfExcludedStatus, setSelfExcludedStatus] = React.useState(
    user.playerProfile.selfExcludedStatus,
  );
  const [depositEnabled, setDepositEnabled] = React.useState(
    user.playerProfile.depositEnabled,
  );
  const [promoEnabled, setPromoEnabled] = React.useState(
    user.playerProfile.promoEnabled,
  );
  const [wageringEnabled, setWageringEnabled] = React.useState(
    user.playerProfile.wageringEnabled,
  );
  const [accountLocked, setAccountLocked] = React.useState(
    user.playerProfile.accountLocked,
  );
  const [featuresBeta, setFeaturesBeta] = React.useState(
    user.playerProfile.featuresBeta,
  );
  const [featuresDebug, setFeaturesDebug] = React.useState(
    user.playerProfile.featuresDebug,
  );
  const [notes, setNotes] = React.useState();
  /* Errors */
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  // const [addressLine1Error, setAddressLine1Error] = React.useState("");
  // const [addressLine2Error, setAddressLine2Error] = React.useState("");
  // const [cityError, setCityError] = React.useState("");
  // const [friendlyNameError, setFriendlyNameError] = React.useState("");
  // const [stateCodeError, setStateCodeError] = React.useState("");
  // const [zipCodeError, setZipCodeError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [accountStatusError, setAccountStatusError] = React.useState("");
  const [kycStatusError, setKycStatusError] = React.useState("");
  const [notesError, setNotesError] = React.useState("");

  /* Mutations */

  const [updatePlayerProfile, updatePlayerProfileStatus] = useMutation(
    Mutation.UPDATE_PLAYER_PROFILE,
  );
  const [updateUserProfile, updateUserProfileStatus] = useMutation(
    Mutation.UPDATE_USER_PROFILE,
  );

  const userProfileOnSubmit = () => {
    if (!firstName || !lastName || !dateOfBirth) {
      setSubmitUserError(
        "Please include at least first name, last name, and DOB",
      );
    } else {
      setSubmitUserError("");
      setLoading(true);
      updateUserProfile({
        variables: {
          input: {
            userProfile: {
              userId: user.id,
              firstName,
              lastName,
              dateOfBirth,
              cellPhoneNumber: phone,
              // mailingAddress: {
              //   friendlyName,
              //   addressLine1,
              //   addressLine2,
              //   city,
              //   stateCode,
              //   zipCode,
              // },
            },
          },
        },
      })
        .then((res) => {
          if (!res.data.updateUserProfile.errors) {
            toast.success(`successfully updated user ${user.email}`);
            dispatch(
              UserActions.UpdateUserSuccess({
                id: user.id,
                ...res.data.updateUserProfile,
              }),
            );
          } else {
            toast.error(`failed to update user ${user.email}`);
          }
          setLoading(false);
          onClose();
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const playerProfileOnSubmit = () => {
    setLoading(true);
    updatePlayerProfile({
      variables: {
        input: {
          playerProfile: {
            userId: user.id,
            kycStatus,
            accountStatus,
            selfExcludedStatus,
            depositEnabled,
            promoEnabled,
            wageringEnabled,
            accountLocked,
            featuresDebug,
            featuresBeta,
          },
        },
      },
    })
      .then((res) => {
        toast.success(`successfully updated user ${user.email}`);
        dispatch(
          UserActions.UpdateUserSuccess({
            id: user.id,
            ...res.data.updatePlayerProfile,
          }),
        );
        setLoading(false);
        onClose();
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("userProfile")}
          className={classes(
            style.pageTab,
            tab === "userProfile" ? style.selectedPageTab : "",
          )}
        >
          User Profile
        </div>
        <div
          onClick={() => setTab("playerProfile")}
          className={classes(
            style.pageTab,
            tab === "playerProfile" ? style.selectedPageTab : "",
          )}
        >
          Player Profile
        </div>
      </div>
      {tab === "userProfile" ? (
        <>
          <div className={style.title}>User Profile</div>
          <TextInput
            className={style.textInput}
            value={email}
            onChange={setEmail}
            label="email"
            error={emailError}
            disabled={true}
          />
          <DatePicker
            wrapperClassName={style.input}
            value={dateOfBirth}
            onChange={(date) => setDateOfBirth(date)}
            label="Date of birth"
          />
          <TextInput
            className={style.textInput}
            value={firstName}
            onChange={setFirstName}
            label="first name"
            error={firstNameError}
          />
          <TextInput
            className={style.textInput}
            value={lastName}
            onChange={setLastName}
            label="last name"
            error={lastNameError}
          />
          <TextInput
            className={style.textInput}
            value={phone}
            onChange={setPhone}
            label="phone"
            error={phoneError}
          />
          {/* <TextInput
            className={style.textInput}
            value={addressLine1}
            onChange={setAddressLine1}
            label="address line 1"
            error={addressLine1Error}
          />
          <TextInput
            className={style.textInput}
            value={addressLine2}
            onChange={setAddressLine2}
            label="address line 2"
            error={addressLine2Error}
          />
          <TextInput
            className={style.textInput}
            value={city}
            onChange={setCity}
            label="city"
            error={cityError}
          />
          <TextInput
            className={style.textInput}
            value={friendlyName}
            onChange={setFriendlyName}
            label="friendly name"
            error={friendlyNameError}
          />
          <TextInput
            className={style.textInput}
            value={stateCode}
            onChange={setStateCode}
            label="state"
            error={stateCodeError}
          />
          <TextInput
            className={style.textInput}
            value={zipCode}
            onChange={setZipCode}
            label="zip"
            error={zipCodeError}
          /> */}
          <div className={style.buttons}>
            <div className={style.submitButton} onClick={userProfileOnSubmit}>
              {loading ? <Icon.Spinner size={15} /> : "Submit"}
            </div>
            <div className={style.error}>{submitUserError}</div>
          </div>
        </>
      ) : (
        <>
          <div className={style.title}>Player Profile</div>
          <div className={style.row}>
            <Select
              value={accountStatus}
              onChange={(e: any) => setAccountStatus(e.target.value)}
              label="Account Status"
              autoWidth={true}
            >
              {accountStatuses.map((status) => (
                <MenuItem value={status} key={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
            <div className={style.label}>Account Status</div>
          </div>
          <div className={style.row}>
            <Select
              value={kycStatus}
              onChange={(e: any) => setKycStatus(e.target.value)}
              label="kyc Status"
              autoWidth={true}
            >
              {kycStatuses.map((status) => (
                <MenuItem value={status} key={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
            <div className={style.label}>KYC Status</div>
          </div>
          <div className={style.row}>
            <Select
              value={selfExcludedStatus}
              onChange={(e: any) => setSelfExcludedStatus(e.target.value)}
              label="Self Exclusion Status"
              autoWidth={true}
            >
              {selfExclusionStatuses.map((status) => (
                <MenuItem value={status} key={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
            <div className={style.label}>Self Exclusion Status</div>
          </div>
          <div className={style.row}>
            <Checkbox
              checked={depositEnabled}
              onChange={(e) => setDepositEnabled(e.target.checked)}
            />
            <div className={style.label}>Deposit Enabled</div>
          </div>
          <div className={style.row}>
            <Checkbox
              checked={promoEnabled}
              onChange={(e) => setPromoEnabled(e.target.checked)}
            />
            <div className={style.label}>Promo Enabled</div>
          </div>
          <div className={style.row}>
            <Checkbox
              checked={wageringEnabled}
              onChange={(e) => setWageringEnabled(e.target.checked)}
            />
            <div className={style.label}>Wagering Enabled</div>
          </div>
          <div className={style.row}>
            <Checkbox
              checked={accountLocked}
              onChange={(e) => setAccountLocked(e.target.checked)}
            />
            <div className={style.label}>Account Locked</div>
          </div>
          <div className={style.row}>
            <Checkbox
              checked={featuresBeta}
              onChange={(e) => setFeaturesBeta(e.target.checked)}
            />
            <div className={style.label}>Beta User</div>
          </div>
          <div className={style.row}>
            <Checkbox
              checked={featuresDebug}
              onChange={(e) => setFeaturesDebug(e.target.checked)}
            />
            <div className={style.label}>Debug User</div>
          </div>
          <div className={style.buttons}>
            <div className={style.submitButton} onClick={playerProfileOnSubmit}>
              {loading ? <Icon.Spinner size={15} /> : "Submit"}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const kycStatuses = [
  "NotStarted",
  "Passed",
  "Failed",
  "InProgress",
  "FailedExceededRetries",
];
const accountStatuses = ["Closed", "Open", "ClosedFraud"];
const selfExclusionStatuses = ["None", "OneYear", "FiveYears", "Permanent"];
