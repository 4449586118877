const leagueTags = [
  "&#x1F440; All",
  "&#x1F3C0; NBA",
  "&#x1F3C0; WNBA",
  "&#x1F3C0; NCAAM",
  "&#x1F3C0; NCAAW",
  "&#x1F3C8; NFL",
  "&#x1F3C8; CFL",
  "&#x1F3C8; NCAAF",
  "&#x26BE; MLB",
  "&#x1F3D2; NHL",
  "&#x26BD; UCL",
  "&#x26BD; EPL",
  "&#x26BD; MLS",
  "&#x26BD; NWSL",
  "&#x26BD; LA LIGA",
  "&#x26F3; PGA TOUR",
  "&#x26F3; LIV",
  "&#x1F94B; MMA",
  "&#x1F94A; BOXING",
  "&#x1F3BE; ATP",
  "&#x1F3BE; WTA",
  "&#x1F3CE; F1",
  "&#x1F3CE; NASCAR",
];

export const isLeague = (tag: string) => {
  return leagueTags.includes(tag);
};

export const getMultiplier = (legCount: number) => {
  switch (legCount) {
    case 2:
      return 3;
    case 3:
      return 6;
    case 4:
      return 10;
    case 5:
      return 20;
    default:
      return 0;
  }
};
