import { gql } from "@apollo/client";

export const CREATE_PLAYER_MUTATION = gql`
  mutation CreatePlayerMutation($input: CreatePlayerInput!) {
    createPlayer(input: $input) {
      player {
        id
        teamId
        name
      }
      errors {
        ... on PlayerExistsError {
          message
        }
      }
    }
  }
`;
