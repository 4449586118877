import React, { useState, useEffect } from "react";
import * as style from "./CreatePropTag.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation } from "../../gql";
import { Icon, TextInput } from "../../components";
import { connect, useDispatch } from "react-redux";
import { PropActions } from "../../store/actions";
import { RootState } from "../../store/reducers";
import * as Models from "../../models";
import { toast } from "react-toastify";

interface OwnProps {
  onClose: () => void;
}

type ComponentProps = OwnProps;

export const CreatePropTag: React.FC<ComponentProps> = ({ onClose }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  /* Values */
  const [name, setName] = useState<string>("");

  /* Errors */
  const [nameError, setNameError] = useState<string>("");

  const [createPropTag, createAuthorStatus] = useMutation(
    Mutation.CREATE_PROP_TAG_MUTATION,
  );
  const onCreate = () => {
    let hasError = false;
    if (!name) {
      setNameError("name is required");
      hasError = true;
    } else {
      setNameError("");
    }

    if (!hasError) {
      setLoading(true);
      createPropTag({
        variables: {
          input: {
            propTag: {
              propTagName: name,
            },
          },
        },
      })
        .then((res) => {
          setLoading(false);
          dispatch(
            PropActions.CreatePropTagSuccess({
              tag: res.data.createPropTag.propTag,
            }),
          );
          toast.success(`successfully created prop tag`);
          onClose();
        })
        .catch((e) => {
          toast.error(
            "something went wrong creating this prop tag - maybe it is already created",
          );
          setLoading(false);
          console.error(e);
          onClose();
        });
    }
  };
  return (
    <div className={style.component}>
      <div className={style.row}>
        <TextInput
          className={style.textInput}
          value={name}
          onChange={setName}
          label="name"
          error={nameError}
        />
      </div>
      <div className={style.row}>
        <div onClick={onCreate} className={style.submit}>
          {loading ? <Icon.Spinner size={15} /> : "Create"}
        </div>
      </div>
    </div>
  );
};
