import * as React from "react";
import * as style from "./Contests.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../gql";
import { connect, useDispatch } from "react-redux";
import { PromoActions } from "../../store/actions";
import { RootState } from "../../store/reducers";
import { Icon, Modal } from "../../components";
import * as Models from "../../models";
import { date } from "../../utility";
import { CreateContest } from "./CreateContest";
import { decode } from "html-entities";
import { colors } from "../../styles";
import { toast } from "react-toastify";

interface StateProps {
  contests: Record<string, Models.Bet.Contest> | null;
  hasPrevious: boolean;
  startCursor?: string;
}

export const ViewContestsInternal: React.FC<StateProps> = ({
  contests,
  hasPrevious,
  startCursor,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedContest, setSelectedContest] = React.useState<number | null>(
    null,
  );

  // /** Get Promo Codes */
  const getContests = (before?: string) => {
    client
      .query({
        query: Query.GET_CONTESTS,
        variables: {
          last: 50,
          before,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(PromoActions.GetContestsSuccess(res.data.contests));
      });
  };
  React.useEffect(() => {
    getContests();
  }, []);

  const contestsArray = !contests
    ? null
    : Object.values(contests).sort((a, b) =>
        new Date(a.contestStartsAtUtc) > new Date(b.contestStartsAtUtc)
          ? -1
          : 1,
      );

  return (
    <div className={style.component}>
      {!contestsArray ? (
        <Icon.Spinner size={16} />
      ) : (
        <div className={style.messagesContainer}>
          <div
            className={style.contest}
            style={{ backgroundColor: colors.subtle, fontWeight: 600 }}
          >
            <div className={style.field}>Name</div>
            <div className={style.field}>Rewards</div>
            <div className={style.field}>Tags</div>
            <div className={style.field}>Active</div>
            <div className={style.field}>
              <div>Viewable Start Date</div>
            </div>
            <div className={style.field}>
              <div>Contest Start Date</div>
            </div>
            <div className={style.field}>
              <div>Contest End Date</div>
            </div>
            <div className={style.field}>
              <div>Viewable End Date</div>
            </div>
          </div>
          {contestsArray.map((contest) => (
            <div
              className={style.contest}
              key={contest.id}
              onClick={() => {
                if (new Date(contest.contestStartsAtUtc) > new Date()) {
                  setSelectedContest(contest.id);
                } else {
                  toast.info(
                    "cannot update a contest that has already started",
                  );
                }
              }}
            >
              <div className={style.field}>{contest.name}</div>
              <div className={style.field}>
                <div>{contest.reward1}</div>
                <div>{contest.reward2}</div>
                <div>{contest.reward3}</div>
                <div>{contest.reward4}</div>
                <div>{contest.reward5}</div>
              </div>
              <div className={style.field}>
                {decode(contest.tags.map((tag) => tag.name).toString())}
              </div>
              <div className={style.field}>
                {contest.isActive ? "Active" : "Inactive"}
              </div>
              <div className={style.field}>
                {" "}
                <div style={{ fontWeight: 600 }}>
                  {date.toShortDateAndTimeUTC(
                    new Date(contest.viewableStartsAtUtc),
                  )}
                </div>
              </div>
              <div className={style.field}>
                {" "}
                <div style={{ fontWeight: 600 }}>
                  {date.toShortDateAndTimeUTC(
                    new Date(contest.contestStartsAtUtc),
                  )}
                </div>
              </div>
              <div className={style.field}>
                {" "}
                <div style={{ fontWeight: 600 }}>
                  {date.toShortDateAndTimeUTC(
                    new Date(contest.contestEndsAtUtc),
                  )}
                </div>
              </div>
              <div className={style.field}>
                <div style={{ fontWeight: 600 }}>
                  {date.toShortDateAndTimeUTC(
                    new Date(contest.viewableEndsAtUtc),
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {selectedContest && !!contests && (
        <Modal
          onRequestClose={() => {
            setSelectedContest(null);
          }}
          isOpen={!!selectedContest}
        >
          <CreateContest
            contest={contests[selectedContest]}
            onClose={() => setSelectedContest(null)}
          />
        </Modal>
      )}
    </div>
  );
};

export const ViewContests = connect((state: RootState) => ({
  contests: state.promos.contests.items,
  hasPrevious: state.promos.contests.hasPrevious,
  startCursor: state.promos.contests.previousCursor,
}))(ViewContestsInternal);
