import React, { useState, useEffect } from "react";
import * as style from "./ResultProp.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { MenuItem, Select } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { PropActions } from "../../store/actions";
import { useFirebaseContext } from "../../auth";
import * as Models from "../../models";
import { RootState } from "../../store/reducers";
import { Icon } from "../../components";
import { classes } from "typestyle";
import { toast } from "react-toastify";

interface StateProps {
  systemPropOptions: Record<string, Models.Props.PropOption> | null;
}

interface OwnProps {
  prop: Models.Props.Prop;
  onClose: () => void;
}

type ComponentProps = StateProps & OwnProps;

export const ResultPropInternal: React.FC<ComponentProps> = ({
  prop,
  systemPropOptions,
  onClose,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();

  /* Values */
  const [selectedPropOption, setSelectedPropOption] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [confirming, setConfirming] = React.useState(false);

  const propOptionsDict = React.useMemo(() => {
    if (systemPropOptions) {
      const newDict: Record<string, Models.Props.PropOption> = {
        ...systemPropOptions,
      };
      prop.propPropOptions.forEach((propPropOption) => {
        newDict[propPropOption.propOption.propText] = propPropOption.propOption;
      });
      return newDict;
    } else {
      return {};
    }
  }, [prop, systemPropOptions]);

  const propOptionsArray = React.useMemo(() => {
    return Object.values(propOptionsDict);
  }, [propOptionsDict]);

  useEffect(() => {
    if (userJwt) {
      client
        .query({
          query: Query.GET_SYSTEM_PROP_OPTIONS_QUERY,
          variables: {
            order: [
              {
                propOptionId: "ASC",
              },
            ],
          },
        })
        .then((res) => {
          dispatch(
            PropActions.GetSystemPropOptionsSuccess({
              props: res.data.systemStatusPropOptions,
            }),
          );
        });
    }
  }, [userJwt]);

  const [updateResult, { data, loading, error }] = useMutation(
    Mutation.PROP_UPDATE_RESULT,
  );

  const onSubmit = () => {
    setSubmitting(true);
    setConfirming(false);
    updateResult({
      variables: {
        input: {
          propExternalId: prop.propExternalId,
          resultId: propOptionsDict[selectedPropOption].propOptionId,
        },
      },
    })
      .then((res) => {
        if (res.data.propUpdateResult.prop) {
          toast.success(`successfully resulted prop`);
          dispatch(
            PropActions.UpdatePropSuccess({
              prop: {
                ...res.data.propUpdateResult.prop,
                propStatus: {
                  propStatusName: "Resulting",
                },
              },
            }),
          );
        } else if (res.data.propUpdateResult.errors) {
          toast.error(
            "error resulting prop: " +
              res.data.propUpdateResult.errors[0].message,
          );
        }
        onClose();
      })
      .catch(() => {
        setSubmitting(false);
        toast.error("unable to result prop");
      });
  };

  return (
    <div className={style.component}>
      {!!confirming ? (
        <div className={style.title}>
          Are you sure you want to choose{" "}
          <span style={{ fontWeight: 700 }}>{selectedPropOption}</span> as the
          result for <span style={{ fontWeight: 700 }}>{prop.title}</span>?
        </div>
      ) : (
        <>
          <div className={style.title}>
            Choose a Result for{" "}
            <span style={{ fontWeight: 700 }}>{prop.title}</span>
          </div>
          <div className={style.subTitle}>{prop.propLine}</div>
          {propOptionsArray.length ? (
            <Select
              value={propOptionsDict[selectedPropOption]?.propText ?? ""}
              onChange={(e: any) => setSelectedPropOption(e.target.value)}
              label="Result"
              autoWidth={true}
            >
              {propOptionsArray.map((option) => (
                <MenuItem value={option.propText} key={option.propOptionId}>
                  {option.propText}
                </MenuItem>
              ))}
            </Select>
          ) : (
            <Icon.Spinner size={32} />
          )}
        </>
      )}
      <div
        onClick={
          !!selectedPropOption
            ? confirming
              ? onSubmit
              : () => setConfirming(true)
            : undefined
        }
        className={classes(
          style.submit,
          !selectedPropOption ? style.disabled : "",
        )}
      >
        {confirming ? (
          "Yes"
        ) : submitting ? (
          <Icon.Spinner size={16} />
        ) : (
          "Submit"
        )}
      </div>
      {confirming && (
        <div
          onClick={() => {
            setSelectedPropOption("");
            setConfirming(false);
          }}
          className={style.submitCancel}
        >
          No
        </div>
      )}
    </div>
  );
};

export const ResultProp = connect(
  (state: RootState) => ({
    systemPropOptions: state.props.systemPropOptions,
  }),
  {},
)(ResultPropInternal);
