import React, { useState, useEffect } from "react";
import * as style from "./PromoCodes.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { DateTimePicker, Icon, TextInput } from "../../components";
import { useDispatch } from "react-redux";
import { MessageActions, PromoActions } from "../../store/actions";
import { useFirebaseContext } from "../../auth";
import MDEditor from "@uiw/react-md-editor";
import * as Models from "../../models";
import { MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { colors } from "../../styles";
import { decode } from "html-entities";

interface OwnProps {
  campaign?: Models.Promos.Campaign | null;
  onClose?: () => void;
  isDuplicating?: boolean;
}

type ComponentProps = OwnProps;
export const CreateCampaign: React.FC<ComponentProps> = ({
  campaign,
  onClose,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();

  /* Values */

  const [name, setName] = useState<string>(campaign ? campaign.name : "");
  const [description, setDescription] = useState<string>(
    campaign ? campaign.description : "",
  );

  /* Errors */
  const [nameError, setNameError] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<string>("");

  /** Submission */
  const [submitting, setSubmitting] = useState(false);
  const [createCampaign, createCampaignStatus] = useMutation(
    Mutation.CREATE_CAMPAIGN_MUTATION,
  );
  const [updateCampaign, updateCampaignStatus] = useMutation(
    Mutation.UPDATE_CAMPAIGN_MUTATION,
  );

  const onSubmit = () => {
    if (!name) {
      setNameError("Input a valid name");
    } else if (name.length > 100) {
      setNameError(
        "Title must be less than 100 characters. You currently have " +
          name.length,
      );
    } else {
      setNameError("");
    }
    if (!description) {
      setDescriptionError("Input a valid rdescription");
    } else if (description.length >= 1000) {
      setDescriptionError(
        "Redemption limit must be less than 1000. You currently have " +
          description.length,
      );
    } else {
      setDescriptionError("");
    }
    setSubmitting(true);
  };

  React.useEffect(() => {
    if (submitting) {
      if (!nameError && !descriptionError) {
        if (!!campaign) {
          updateCampaign({
            variables: {
              input: {
                updateCampaignRequest: {
                  id: campaign.id,
                  name,
                  description,
                },
              },
            },
          })
            .then((res) => {
              dispatch(
                PromoActions.UpdateCampaignSuccess({
                  campaign: res.data.updateCampaign.campaign,
                }),
              );
              toast.success(`successfully updated campaign`);
              setSubmitting(false);
              setName("");
              setDescription("");
              if (onClose) {
                onClose();
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              if (onClose) {
                onClose();
              }
            });
        } else {
          createCampaign({
            variables: {
              input: {
                campaign: {
                  name,
                  description,
                },
              },
            },
          })
            .then((res) => {
              if (res.data?.createCampaign?.campaign) {
                dispatch(
                  PromoActions.UpdateCampaignSuccess({
                    campaign: res.data.createCampaign.campaign,
                  }),
                );
                toast.success(
                  `successfully created campaign ${res.data.createCampaign.campaign.name}`,
                );
                setSubmitting(false);
                setName("");
                setDescription("");
                if (onClose) {
                  onClose();
                }
              } else {
                toast.error(`We were unable to create this campaign`);
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              toast.error(`We were unable to create this campaign`);
              if (onClose) {
                onClose();
              }
            });
        }
      } else {
        setSubmitting(false);
      }
    }
  }, [submitting]);

  return (
    <div className={style.component}>
      <TextInput
        className={style.textInput}
        value={name}
        onChange={setName}
        label="Name"
        error={nameError}
        disabled={!!campaign}
      />
      <TextInput
        value={description}
        onChange={setDescription}
        label="Description"
        className={style.textInput}
        error={descriptionError}
      />
      <div onClick={onSubmit} className={style.submit}>
        {submitting ? <Icon.Spinner size={15} /> : "Submit"}
      </div>
    </div>
  );
};
