export interface Message {
  messageId: string;
  title: string;
  body: string;
  category: MessageCategory;
  startDate: string;
  endDate: string;
  isActive: boolean;
}

export enum MessageCategory {
  PROMOTION = "PROMOTION",
  MAINTENANCE = "MAINTENANCE",
  OUTAGE = "OUTAGE",
}
