import * as React from "react";
import * as style from "./Teams.style";
import * as Components from "../../../components";
import * as Models from "../../../models";
import { classes } from "typestyle";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../../gql";
import { toast } from "react-toastify";
import { MenuItem, Select } from "@mui/material";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import { useDispatch } from "react-redux";
import { PlayerActions } from "../../../store/actions";

interface StateProps {
  leagues: Record<string, Models.Bet.League> | null;
  teams: Record<string, Models.Bet.Team> | null;
  hasNextTeams?: boolean;
  teamsNextCursor?: string;
}

interface ComponentProps {
  getTeams: (after?: string) => void;
}

export const TeamsInternal: React.FC<StateProps & ComponentProps> = ({
  leagues,
  teams,
  hasNextTeams,
  teamsNextCursor,
  getTeams,
}) => {
  const [tab, setTab] = React.useState("create");
  const [teamName, setTeamName] = React.useState("");
  const [displayName, setDisplayName] = React.useState("");
  const [teamCode, setTeamCode] = React.useState("");
  const [leagueId, setLeagueId] = React.useState<number | undefined>(5);
  const dispatch = useDispatch();

  const leaguesArray = React.useMemo(() => {
    return Object.values(leagues ?? {});
  }, [leagues]);

  const teamsArray = React.useMemo(() => {
    return Object.values(teams ?? {});
  }, [teams]);

  /** Submission */
  const [loading, setLoading] = React.useState(false);
  const [createTeam, createTeamStatus] = useMutation(
    Mutation.CREATE_TEAM_MUTATION,
  );
  const onSubmit = () => {
    if (!teamName || !teamCode || !leagueId || !displayName) {
      toast.error(
        "Team name, team code, league, and displayName are all required",
      );
    }
    setLoading(true);
    createTeam({
      variables: {
        input: {
          teamRequest: {
            leagueId,
            name: teamName,
            displayName,
            teamCode,
          },
        },
      },
    })
      .then((res) => {
        if (!!res?.data?.createTeam?.team?.name) {
          toast.success(
            `Congrats! You have created Team ${res.data.createTeam.team.displayName}`,
          );
          dispatch(
            PlayerActions.UpdateTeamSuccess({
              team: res.data.createTeam.team,
            }),
          );
          setTeamCode("");
          setTeamName("");
          setDisplayName("");
          setLeagueId(5);
        } else {
          toast.error(
            res?.data?.createTeam?.errors?.length
              ? res.data.createTeam.errors[0].message
              : "Something went wrong creating this team - please try again",
          );
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error(
          "Something went wrong creating this team - please try again",
        );
        setLoading(false);
      });
  };

  return (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("create")}
          className={classes(
            style.pageTab,
            tab === "create" ? style.selectedPageTab : "",
          )}
        >
          Create Team
        </div>
        <div
          onClick={() => setTab("view")}
          className={classes(
            style.pageTab,
            tab === "view" ? style.selectedPageTab : "",
          )}
        >
          View Teams
        </div>
      </div>
      <div className={style.pageContainer}>
        {tab === "create" ? (
          <>
            <div style={{ marginBottom: 24 }}>
              <Select
                value={leagueId}
                onChange={(e) => setLeagueId(e.target.value as number)}
                autoWidth={true}
                label="League"
              >
                {leaguesArray.map((league) => (
                  <MenuItem key={league.id} value={league.id}>
                    {league.leagueCode}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <Components.TextInput
              value={teamName}
              onChange={setTeamName}
              className={style.textInput}
              label="Team Name - Celtics"
            />
            <Components.TextInput
              value={teamCode}
              onChange={setTeamCode}
              className={style.textInput}
              label="Team Code - BOS"
            />
            <Components.TextInput
              value={displayName}
              onChange={setDisplayName}
              className={style.textInput}
              label="Display Name - Boston Celtics"
            />
            <div
              className={style.button}
              onClick={loading ? undefined : onSubmit}
            >
              {loading ? <Components.Icon.Spinner size={20} /> : "Create Team"}
            </div>
          </>
        ) : (
          <>
            <div className={style.table}>
              <div
                className={style.item}
                style={{ fontWeight: 700, textDecoration: "underline" }}
              >
                <div style={{ flex: 1 }}>ID</div>
                <div style={{ flex: 1, textAlign: "center" }}>Team Code</div>
                <div style={{ flex: 1, textAlign: "center" }}>Team Name</div>
                <div style={{ flex: 1, textAlign: "right" }}>League Code</div>
              </div>
              {teamsArray.map((team) => (
                <div className={style.item} key={team.id}>
                  <div style={{ flex: 1 }}>{team.id}</div>
                  <div style={{ flex: 1, textAlign: "center" }}>
                    {team.teamCode}
                  </div>
                  <div style={{ flex: 1, textAlign: "center" }}>
                    {team.name}
                  </div>
                  <div style={{ flex: 1, textAlign: "right" }}>
                    {leagues && leagues[team.leagueId]?.leagueCode}
                  </div>
                </div>
              ))}
            </div>

            <div
              className={classes(
                style.button,
                !hasNextTeams ? style.disabled : "",
              )}
              style={{ margin: "auto" }}
              onClick={() => getTeams(teamsNextCursor)}
            >
              {loading ? (
                <Components.Icon.Spinner size={20} />
              ) : (
                "Get More Players"
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const Teams = connect(
  (state: RootState) => ({
    leagues: state.players.leagues.items,
    teams: state.players.teams.items,
    hasNextTeams: state.players.teams.hasNext,
    teamsNextCursor: state.players.teams.nextCursor,
  }),
  {},
)(TeamsInternal);
