import * as Models from "../../models";
import { TransactionActions } from "../actions";

export interface TransactionStore {
  wallet: {
    items: Models.Transactions.UnitsTransaction[] | null;
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  betPlacements: {
    edges?: Models.Bet.UserBetPlacementsEdge[];
    nodes?: Models.Bet.ParlayBetPlacement[];
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}

export const initialState: TransactionStore = {
  wallet: {
    items: null,
    totalCount: -1,
    hasNextPage: false,
    hasPreviousPage: false,
  },
  betPlacements: {
    totalCount: -1,
    hasNextPage: false,
    hasPreviousPage: false,
  },
};

export const transactions = (
  state: TransactionStore = initialState,
  action: TransactionActions.Actions
): TransactionStore => {
  switch (action.type) {
    case TransactionActions.ActionTypes.GET_WALLET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        wallet: {
          ...action.payload,
          ...action.payload.pageInfo,
        },
      };
    }
    case TransactionActions.ActionTypes.GET_BET_PLACEMENTS_SUCCESS: {
      return {
        ...state,
        betPlacements: {
          ...action.payload,
          ...action.payload.pageInfo,
        },
      };
    }
    default:
      return state;
  }
};
