import React, { useState, useEffect } from "react";
import * as style from "./CreateProp.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { DateTimePicker, Icon, TextInput } from "../../components";
import MDEditor from "@uiw/react-md-editor";
import { connect, useDispatch } from "react-redux";
import { PropActions } from "../../store/actions";
import { useFirebaseContext } from "../../auth";
import { RootState } from "../../store/reducers";
import * as Models from "../../models";
import { MenuItem, Select } from "@mui/material";
import { Modal } from "../../components";
import { CreatePropOption } from "../CreatePropOption";
import { toast } from "react-toastify";
import { bet } from "../../utility";
import { Author } from "../Author";
import { UpdatePropOption } from "../UpdatePropOption";
import { decode } from "html-entities";
import { CreatePropTag } from "../CreatePropTag";

interface StateProps {
  propOptions: Record<string, Models.Props.PropOption> | null;
  tags: Record<string, Models.Props.PropTag> | null;
  tagsNextCursor?: string;
  specials: Record<string, Models.Props.PropSpecial> | null;
  authorsArray: Models.Props.Author[] | null;
  players: Record<string, Models.Bet.Player> | null;
  teams: Record<string, Models.Bet.Team> | null;
}

interface OwnProps {
  prop?: Models.Props.Prop | null;
  onClose?: () => void;
  isDuplicating?: boolean;
  getTeams: (after?: string) => void;
  getPlayers: (after?: string) => void;
}

type ComponentProps = StateProps & OwnProps;
export const CreatePropInternal: React.FC<ComponentProps> = ({
  propOptions,
  tags,
  authorsArray,
  prop,
  onClose,
  isDuplicating,
  specials,
  getTeams,
  getPlayers,
  players,
  teams,
  tagsNextCursor,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();
  const propOptionsArray = React.useMemo(() => {
    return !!propOptions
      ? Object.values(propOptions).filter(
          (option) =>
            !["Push", "Scratch", "Cancelled"].includes(option.propText),
        )
      : [];
  }, [propOptions]);

  const [showAllTags, setShowAllTags] = useState(false);
  const tagsArray = React.useMemo(() => {
    if (!tags) {
      return [];
    } else if (showAllTags || !!prop) {
      return Object.values(tags).sort((a, b) =>
        a.propTagId > b.propTagId ? -1 : 1,
      );
    } else {
      const tagsList = Object.values(tags);
      const leagueTags: Models.Props.PropTag[] = [];
      const recentTags: Models.Props.PropTag[] = [];
      tagsList.forEach((tag) => {
        if (bet.isLeague(tag.name)) {
          leagueTags.push(tag);
        } else if (recentTags.length < 30) {
          recentTags.push(tag);
        }
      });
      return [...leagueTags, ...recentTags];
    }
  }, [tags, showAllTags]);

  const specialsArray = React.useMemo(() => {
    return !!specials ? Object.values(specials) : [];
  }, [specials]);

  const authors = React.useMemo(() => {
    if (!authorsArray) {
      return null;
    }
    const authorsDict: Record<string, Models.Props.Author> = {};
    authorsArray.forEach((author) => {
      authorsDict[author.name] = author;
    });
    return authorsDict;
  }, [authorsArray]);

  /* Values */
  const [closeTime, setCloseTime] = useState<Date | null>(
    prop ? new Date(prop.closeTimeUtc) : null,
  );
  const [startTime, setStartTime] = useState<Date | null>(
    prop ? new Date(prop.startTimeUtc) : null,
  );
  const [propContent, setPropContent] = useState<string | undefined>(
    prop ? prop.propContent : "Story goes here",
  );
  const [title, setTitle] = useState<string>(prop ? prop.title : "Title here");
  const [propLine, setPropLine] = useState<string>(
    prop ? prop.propLine : "Prop line here",
  );
  const [tooltipInfo, setTooltipInfo] = useState<string>(
    prop ? prop.tooltipInfo : "",
  );
  const [televisionNetwork, setTelevisionNetwork] = useState<string>(
    prop ? prop.televisionNetwork : "AWAY @ HOME | X/Y @ X:XX PM EST | NETWORK",
  );
  const [author, setAuthor] = useState<string>(
    prop ? prop.author.name : "Units Publishing Desk",
  );
  const [propOption1, setPropOption1] = useState<string>(
    prop ? prop.propPropOptions[0].propOption.propText : "Yes",
  );
  const [propOption2, setPropOption2] = useState<string>(
    prop ? prop.propPropOptions[1].propOption.propText : "No",
  );
  const [propOption1DecorationOverride, setPropOption1DecorationOverride] =
    useState<Models.Props.CreatePropDecorationRequestInput | null>(null);
  const [propOption2DecorationOverride, setPropOption2DecorationOverride] =
    useState<Models.Props.CreatePropDecorationRequestInput | null>(null);
  const [propTags, setPropTags] = useState<number[]>(
    prop
      ? prop.propTags.map((tag) => tag.propTagId)
      : [process.env.NODE_ENV === "development" ? 123 : 56],
  );
  const [propSpecial, setPropSpecial] = useState<string | null>(
    prop && prop.propSpecials.length ? prop.propSpecials[0].name : null,
  );
  const isPublished = React.useMemo(() => {
    return prop
      ? prop.publishingStatus.publishingStatusName !== "Draft" && !isDuplicating
      : false;
  }, [prop]);

  /* Errors */
  const [closeTimeError, setCloseTimeError] = useState<string>("");
  const [startTimeError, setStartTimeError] = useState<string>("");
  const [propContentError, setPropContentError] = useState<string>("");
  const [titleError, setTitleError] = useState<string>("");
  const [propLineError, setPropLineError] = useState<string>("");
  const [tooltipError, setTooltipError] = useState<string>("");
  const [authorError, setAuthorError] = useState<string>("");
  useState<string>("");
  const [tvNetworkError, setTvNetworkError] = useState<string>("");
  const [propOption1Error, setPropOption1Error] = useState<string>("");
  const [propOption2Error, setPropOption2Error] = useState<string>("");
  const [propTagsError, setPropTagsError] = useState<string>("");
  const [propSpecialsError, setPropSpecialsError] = useState<string>("");

  /** Modals */
  const [showPropOptionModal, setShowPropOptionModal] =
    useState<boolean>(false);
  const [propOptionDecorationToOverride, setPropOptionDecorationToOverride] =
    useState<string>();
  const [propOptionDecorationToUpdate, setPropOptionDecorationToUpdate] =
    useState<string>();
  const [modalAuthor, setModalAuthor] = useState<string>("");
  const [showPropTagModal, setShowPropTagModal] = useState(false);

  /** Image  */
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // const [propImageB64, setPropImageB64] = useState<string>(
  //   prop ? "imageB64" : "",
  // );
  // const [propImageError, setPropImageError] = useState<string>("");
  // useEffect(() => {
  //   if (selectedFile != null) {
  //     const fileReader = new FileReader();
  //     fileReader.readAsDataURL(selectedFile);
  //     fileReader.onload = () => {
  //       setPropImageB64(fileReader!.result!.toString());
  //     };
  //   }
  // }, [selectedFile]);

  /** Get Prop Options */

  const getPropOptions = (after?: string) => {
    client
      .query({
        query: Query.GET_PROP_OPTIONS_QUERY,
        variables: {
          first: 50,
          after,
          order: [
            {
              propText: "ASC",
            },
          ],
        },
      })
      .then((res) => {
        dispatch(
          PropActions.GetPropOptionsSuccess({ props: res.data.propOptions }),
        );
        if (res.data.propOptions?.pageInfo?.hasNextPage) {
          getPropOptions(res.data.propOptions.pageInfo.endCursor);
        }
      });
  };

  const getPropTags = () =>
    client
      .query({
        query: Query.GET_PROP_TAGS_QUERY,
        variables: {
          first: 50,
          after: tagsNextCursor,
          order: [
            {
              propOptionId: "desc",
            },
          ],
        },
      })
      .then((res) => {
        dispatch(
          PropActions.GetPropTagsSuccess({
            tags: res.data.propTags,
            hasNext: res.data.propTags.pageInfo.hasNextPage,
            nextCursor: res.data.propTags.pageInfo.hasNextPage
              ? res.data.propTags.pageInfo.endCursor
              : undefined,
          }),
        );
      });

  useEffect(() => {
    if (userJwt) {
      getPropOptions();
      getPropTags();
      client
        .query({
          query: Query.GET_AUTHORS_QUERY,
        })
        .then((res) => {
          dispatch(
            PropActions.GetAuthorsSuccess({ authors: res.data.authors }),
          );
        });
      client
        .query({
          query: Query.GET_PROP_SPECIALS_QUERY,
          variables: {
            first: 30,
          },
        })
        .then((res) => {
          dispatch(
            PropActions.GetPropSpecialsSuccess({
              specials: res.data.propSpecials.nodes,
            }),
          );
        });
    }
  }, [userJwt]);

  React.useEffect(() => {
    if (!!tagsNextCursor) {
      getPropTags();
    }
  }, [tagsNextCursor]);

  /** Submission */
  const [submitting, setSubmitting] = useState(false);
  const [createProp, createPropStatus] = useMutation(
    Mutation.PROP_CREATE_MUTATION,
  );
  const [updateProp, updatePropStatus] = useMutation(
    Mutation.PROP_UPDATE_MUTATION,
  );
  const onSubmit = () => {
    if (!startTime) {
      setStartTimeError("Select a valid start time");
    } else {
      setStartTimeError("");
    }
    if (!closeTime) {
      setCloseTimeError("Select a valid start time");
    } else {
      setCloseTimeError("");
    }
    if (!propContent) {
      setPropContentError("Input valid content");
    } else {
      setPropContentError("");
    }
    if (!title) {
      setTitleError("Input a valid title");
    } else if (title.length > 45) {
      setTitleError(
        "Title must be less than 45 characters. You currently have " +
          title.length,
      );
    } else {
      setTitleError("");
    }
    if (!propLine) {
      setPropLineError("Input a valid prop line");
    } else if (propLine.length > 85) {
      setPropLineError(
        "Prop line must be less than 85 characters. You currently have " +
          propLine.length,
      );
    } else {
      setPropLineError("");
    }
    if (tooltipInfo && tooltipInfo.length > 500) {
      setTooltipError(
        "Tooltip Info must be less than 500 characters. You currently have " +
          tooltipInfo.length,
      );
    } else {
      setTooltipError("");
    }
    if (!author) {
      setAuthorError("Please select an author");
    } else {
      setAuthorError("");
    }
    if (!televisionNetwork) {
      setTvNetworkError("Input valid game info");
    } else if (televisionNetwork.length > 40) {
      setTvNetworkError(
        "Game Info must be less than 40 characters. You currently have " +
          televisionNetwork.length,
      );
    } else {
      setTvNetworkError("");
    }
    if (propTags.length < 1) {
      setPropTagsError("Select at least 1 Prop Tag");
    } else {
      setPropTagsError("");
    }
    // validate at least 1 player info on both prop options
    if (
      propOption1DecorationOverride &&
      !propOption1DecorationOverride.propDecorationPlayerInfo.length &&
      (!prop || isDuplicating)
    ) {
      setPropOption1Error(
        "Prop Option 1 Decoration Override must contain at least 1 player info",
      );
    } else if (
      !propOption1DecorationOverride &&
      !!propOptions &&
      !propOptions[propOption1].defaultDecoration?.propDecorationPlayerInfo
        .length
    ) {
      setPropOption1Error(
        "Prop Option 1 does not have any Player Info. Please update the prop option decoration, or add an override",
      );
    } else {
      setPropOption1Error("");
    }
    if (
      propOption2DecorationOverride &&
      !propOption2DecorationOverride.propDecorationPlayerInfo.length &&
      (!prop || isDuplicating)
    ) {
      setPropOption2Error(
        "Prop Option 2 Decoration Override must contain at least 1 player info",
      );
    } else if (
      !propOption2DecorationOverride &&
      !!propOptions &&
      !propOptions[propOption2].defaultDecoration?.propDecorationPlayerInfo
        .length
    ) {
      setPropOption2Error(
        "Prop Option 2 does not have any Player Info. Please update the prop option decoration, or add an override",
      );
    } else {
      setPropOption2Error("");
    }
    // if (propSpecials.length > 1) {
    //   setPropSpecialsError("You can only select 1 prop special");
    // } else {
    //   setPropSpecialsError("");
    // }
    // if (!propImageB64) {
    //   setPropImageError("Input a valid image");
    // } else {
    //   setPropImageError("");
    // }
    setSubmitting(true);
  };

  const getOverrideFromPropPropOption = (
    ppo: Models.Props.PropPropOption,
    isOption1: boolean,
  ) => {
    if (ppo.decoration) {
      const override = {
        fontColor:
          ppo.decoration?.fontColor ??
          ppo.propOption.defaultDecoration?.fontColor ??
          "#FFFFFF",
        backgroundColor:
          ppo.decoration?.backgroundColor ??
          ppo.propOption.defaultDecoration?.backgroundColor ??
          "#000000",
        optionImageFileUrl: ppo.decoration?.imageUrl ?? undefined,
        propDecorationPlayerInfo:
          ppo.decoration?.propDecorationPlayerInfo.map((playerInfo) => ({
            teamId: playerInfo.teamId,
            playerId: playerInfo.playerId,
          })) ?? [],
      };
      if (isOption1) {
        setPropOption1DecorationOverride(override);
      } else {
        setPropOption2DecorationOverride(override);
      }
    }
  };

  React.useEffect(() => {
    setPropOption1DecorationOverride(null);
  }, [propOption1]);

  React.useEffect(() => {
    setPropOption2DecorationOverride(null);
  }, [propOption2]);

  React.useEffect(() => {
    if (prop) {
      getOverrideFromPropPropOption(prop.propPropOptions[0], true);
      getOverrideFromPropPropOption(prop.propPropOptions[1], false);
    }
  }, [prop]);

  React.useEffect(() => {
    if (submitting) {
      if (
        !startTimeError &&
        !closeTimeError &&
        !propContentError &&
        !titleError &&
        !propLineError &&
        !tooltipError &&
        !authorError &&
        !propOption1Error &&
        !propOption2Error &&
        !propTagsError &&
        !tvNetworkError &&
        // !propImageError &&
        !!authors &&
        !!propOptions &&
        !!propOptions[propOption1 || ""] &&
        !!propOptions[propOption2 || ""]
      ) {
        if (!!prop && !isDuplicating) {
          updateProp({
            variables: {
              input: {
                updateRequest: {
                  propExternalId: prop.propExternalId,
                  startTime,
                  closeTime,
                  title,
                  propLine,
                  tooltipInfo,
                  authorId: authors[author].authorId,
                  propContent,
                  televisionNetwork,
                  // propOptions: [
                  //   {
                  //     id: propOptions[propOption1].propOptionId,
                  //     propDecoration: propOption1DecorationOverride,
                  //   },
                  //   {
                  //     id: propOptions[propOption2].propOptionId,
                  //     propDecoration: propOption2DecorationOverride,
                  //   },
                  // ],
                  // propImageB64:
                  //   !!propImageB64 && propImageB64 !== "imageB64"
                  //     ? propImageB64
                  //     : undefined,
                },
              },
            },
          })
            .then((res) => {
              toast.success(`successfully updated prop`);
              setSubmitting(false);
              setStartTime(null);
              setCloseTime(null);
              setTitle("");
              setPropLine("");
              setTooltipInfo("");
              setPropContent(undefined);
              setPropOption1("Yes");
              setPropOption2("No");
              setPropOption1DecorationOverride(null);
              setPropOption2DecorationOverride(null);
              setPropTags([]);
              setPropSpecial(null);
              setTelevisionNetwork("");
              setSelectedFile(null);
              if (onClose) {
                onClose();
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              if (onClose) {
                onClose();
              }
            });
        } else {
          var hasSamePropDecorationImage = false;
          if (
            propOption1DecorationOverride?.optionImageFileUrl &&
            propOption2DecorationOverride?.optionImageFileUrl &&
            propOption1DecorationOverride.optionImageFileUrl ==
              propOption2DecorationOverride.optionImageFileUrl
          ) {
            hasSamePropDecorationImage = true;
          } else if (
            propOption1DecorationOverride?.optionImageB64 &&
            propOption2DecorationOverride?.optionImageB64 &&
            propOption1DecorationOverride.optionImageB64 ==
              propOption2DecorationOverride.optionImageB64
          ) {
            hasSamePropDecorationImage = true;
          } else if (
            !!propOptions[propOption1]?.defaultDecoration?.imageUrl &&
            !!propOptions[propOption2]?.defaultDecoration?.imageUrl &&
            propOptions[propOption1]?.defaultDecoration?.imageUrl ==
              propOptions[propOption2]?.defaultDecoration?.imageUrl
          ) {
            hasSamePropDecorationImage = true;
          }
          createProp({
            variables: {
              input: {
                createPropRequest: {
                  startTime,
                  closeTime,
                  title,
                  propLine,
                  tooltipInfo,
                  authorId: authors[author].authorId,
                  propContent,
                  propOptions: [
                    {
                      id: propOptions[propOption1].propOptionId,
                      propDecoration: propOption1DecorationOverride,
                    },
                    {
                      id: propOptions[propOption2].propOptionId,
                      propDecoration: propOption2DecorationOverride,
                    },
                  ],
                  // TODO: Fix this and move it to a helper function
                  hasSamePropDecorationImage,
                  propTags: propTags.map((tag) => ({
                    id: tag,
                  })),
                  propSpecials:
                    !!propSpecial && specials
                      ? [
                          {
                            id: specialsArray.find(
                              (s) => s.name === propSpecial,
                            )?.propSpecialId,
                          },
                        ]
                      : [],
                  televisionNetwork,
                  // propImageB64,
                },
              },
            },
          })
            .then((res) => {
              if (res.data?.propCreate?.prop) {
                toast.success(`successfully created prop`);
                setSubmitting(false);
                setStartTime(null);
                setCloseTime(null);
                setTitle("");
                setPropLine("");
                setTooltipInfo("");
                setAuthor("Units Publishing Desk");
                setPropContent(undefined);
                setPropOption1("Yes");
                setPropOption2("No");
                setPropOption1DecorationOverride(null);
                setPropOption2DecorationOverride(null);
                setSelectedFile(null);
                // setPropImageB64("");
                setPropTags([]);
                setPropSpecial(null);
                setTelevisionNetwork("");
                setSelectedFile(null);
                if (onClose) {
                  onClose();
                }
              } else if (res.data?.propCreate?.errors?.length) {
                toast.error(
                  `We were unable to create this prop: ${res.data.propCreate.errors[0]}`,
                );
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              if (onClose) {
                onClose();
              }
            });
        }
      } else {
        setSubmitting(false);
      }
    }
  }, [submitting]);

  React.useEffect(() => {
    if (prop) {
      if (
        !!prop.propPropOptions[0].decoration &&
        !!prop.propPropOptions[0].decoration
      ) {
        // const image = new Image();
        // image.crossOrigin = "anonymous";
        // image.onload = () => {
        //   const canvas = document.createElement("canvas");
        //   const ctx = canvas.getContext("2d");
        //   canvas.height = image.naturalHeight;
        //   canvas.width = image.naturalWidth;
        //   if (!!ctx) {
        //     ctx.drawImage(image, 0, 0);
        //     const dataUrl = canvas.toDataURL();
        //     setPropOption1DecorationOverride({
        //       optionImageB64: dataUrl,
        //       backgroundColor: prop.propPropOptions[0].decoration
        //         ?.backgroundColor as string,
        //       fontColor: prop.propPropOptions[0].decoration
        //         ?.fontColor as string,
        //       propDecorationPlayerInfo: (prop.propPropOptions[0].decoration
        //         ?.propDecorationPlayerInfo ??
        //         []) as CreatePropDecorationPlayerInfoRequestInput[],
        //     });
        //   }
        // };
        // image.src = prop.propPropOptions[0].decoration.imageUrl as string;
        // const fileReader = new FileReader();
        // fileReader.readAsDataURL(
        //   prop.propPropOptions[0].decoration.imageUrl as any,
        // );
        // fileReader.onload = () => {
        //   setPropOption1DecorationOverride({
        //     optionImageB64: fileReader!.result!.toString(),
        //     backgroundColor: prop.propPropOptions[0].decoration
        //       ?.backgroundColor as string,
        //     fontColor: prop.propPropOptions[0].decoration?.fontColor as string,
        //   });
        // };
        // }
        // if (
        //   !!prop.propPropOptions[1].decoration &&
        //   !!prop.propPropOptions[1].decoration
        // ) {
        // const fileReader2 = new FileReader();
        // fileReader2.readAsDataURL(
        //   prop.propPropOptions[1].decoration.imageUrl as any,
        // );
        // fileReader2.onload = () => {
        //   setPropOption2DecorationOverride({
        //     optionImageB64: fileReader2!.result!.toString(),
        //     backgroundColor: prop.propPropOptions[1].decoration
        //       ?.backgroundColor as string,
        //     fontColor: prop.propPropOptions[1].decoration?.fontColor as string,
        //   });
        // };
      }
    }
  }, []);

  return (
    <div className={style.component}>
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={startTime}
        onChange={(date) => setStartTime(date)}
        label="Publish Time (prop opens)"
        error={startTimeError}
        disabled={isPublished}
      />
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={closeTime}
        onChange={(date) => setCloseTime(date)}
        label="Game Start Time (prop closes)"
        error={closeTimeError}
        disabled={isPublished}
      />
      <TextInput
        className={style.textInput}
        value={title}
        onChange={setTitle}
        label="Title"
        error={titleError}
        disabled={isPublished}
      />
      {!!tags && !!tagsArray.length && (
        <>
          <div className={style.propOptionContainer}>
            <Select
              multiple={true}
              value={propTags}
              onChange={(e) => setPropTags(e.target.value as number[])}
              autoWidth={true}
              label="Prop Tags"
              disabled={!!prop && !isDuplicating}
            >
              {tagsArray.map((tag) => (
                <MenuItem value={tag.propTagId} key={tag.propTagId}>
                  {decode(tag.name)}
                </MenuItem>
              ))}
            </Select>
            {!isPublished && (
              <div style={{ paddingLeft: 10 }}>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => setShowPropTagModal(true)}
                >
                  Create Prop Tag
                </div>
              </div>
            )}
            {!isPublished && (
              <div style={{ paddingLeft: 10 }}>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => setShowAllTags(true)}
                >
                  Show All Tags
                </div>
              </div>
            )}
          </div>
          {propTagsError && <div className={style.error}>{propTagsError}</div>}
        </>
      )}
      {!!specials && !!specialsArray.length && (
        <>
          <div className={style.propOptionContainer}>
            <Select
              value={propSpecial ?? ""}
              onChange={(e) =>
                setPropSpecial(
                  e.target.value === propSpecial ? null : e.target.value,
                )
              }
              autoWidth={true}
              label="Prop Specials"
              disabled={!!prop && !isDuplicating}
            >
              {specialsArray.map((special) => (
                <MenuItem value={special.name} key={special.propSpecialId}>
                  {special.name}
                </MenuItem>
              ))}
            </Select>
            {!prop && <div style={{ paddingLeft: 20 }}>Select Specials</div>}
            {!isPublished && (
              <div style={{ paddingLeft: 10 }}>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => setPropSpecial(null)}
                >
                  Clear
                </div>
              </div>
            )}
          </div>
          {propSpecialsError && (
            <div className={style.error}>{propSpecialsError}</div>
          )}
        </>
      )}
      {!!authors && !!authorsArray && (
        <>
          <div className={style.propOptionContainer}>
            <Select
              value={authors[author]?.name ?? ""}
              onChange={(e) => setAuthor(e.target.value)}
              label="Author"
              autoWidth={true}
              disabled={isPublished}
            >
              {authorsArray.map((author) => (
                <MenuItem value={author.name} key={author.authorId}>
                  {author.name}
                </MenuItem>
              ))}
            </Select>
            {!isPublished && (
              <div style={{ paddingLeft: 10 }}>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => setModalAuthor("not an author")}
                >
                  Create Author
                </div>
              </div>
            )}
            {!!authors[author] &&
              authors[author].authorId !== 1 &&
              !isPublished && (
                <div style={{ paddingLeft: 10 }}>
                  <div
                    className={style.addPropOptionButton}
                    onClick={() => setModalAuthor(author)}
                  >
                    Update Author
                  </div>
                </div>
              )}
          </div>
          {authorError && <div className={style.error}>{authorError}</div>}
        </>
      )}
      <TextInput
        className={style.textInput}
        value={propLine}
        onChange={setPropLine}
        label="Prop Line"
        error={propLineError}
        disabled={isPublished}
      />
      <TextInput
        className={style.textInput}
        value={tooltipInfo}
        onChange={setTooltipInfo}
        label="Tooltip Info (Optional)"
        error={tooltipError}
        disabled={isPublished}
      />
      {/* TODO: Refactor this into a component*/}
      {!!propOptionsArray && !!propOptions && (
        <>
          <div className={style.propOptionContainer}>
            <div className={style.dropDown}>
              <Select
                value={propOptions[propOption1]?.propText ?? ""}
                onChange={(e) => setPropOption1(e.target.value)}
                autoWidth={true}
                label="Prop Option 1"
                disabled={!!prop && !isDuplicating}
              >
                {propOptionsArray.map((option) => (
                  <MenuItem value={option.propText} key={option.propOptionId}>
                    {option.propText}
                  </MenuItem>
                ))}
              </Select>
              <div>Prop Option 1</div>
              {propOption1Error && (
                <div className={style.error}>{propOption1Error}</div>
              )}
            </div>
            {!!propOption1 && (
              <>
                <div
                  className={style.optionPreview}
                  style={{
                    backgroundColor:
                      propOption1DecorationOverride?.backgroundColor ??
                      propOptions[propOption1]?.defaultDecoration
                        ?.backgroundColor ??
                      "#000",
                    color:
                      propOption1DecorationOverride?.fontColor ??
                      propOptions[propOption1]?.defaultDecoration?.fontColor ??
                      "#FFF",
                  }}
                >
                  {propOptions[propOption1]?.propText}
                </div>
                <div className={style.optionImagePreviewContainer}>
                  <img
                    src={
                      propOption1DecorationOverride?.optionImageFileUrl ??
                      propOption1DecorationOverride?.optionImageB64 ??
                      propOptions[propOption1]?.defaultDecoration?.imageUrl ??
                      ""
                    }
                    alt="option image"
                    className={style.image}
                  />
                </div>
              </>
            )}
            <div style={{ padding: 6, fontWeight: 500, textAlign: "center" }}>
              <div style={{ fontSize: 14, fontWeight: 700 }}>Player Info</div>
              {(propOption1DecorationOverride
                ? propOption1DecorationOverride.propDecorationPlayerInfo
                : propOptions[propOption1]?.defaultDecoration
                    ?.propDecorationPlayerInfo ?? []
              ).map(
                (playerInfo) =>
                  !!players &&
                  !!teams && (
                    <div key={playerInfo.playerId + " " + playerInfo.teamId}>
                      {players[playerInfo.playerId]?.name} -{" "}
                      {teams[playerInfo.teamId]?.teamCode}
                    </div>
                  ),
              )}
            </div>
            {!!propOption1 && (!prop || isDuplicating) && (
              <div>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => {
                    setPropOptionDecorationToOverride(propOption1);
                    setShowPropOptionModal(true);
                  }}
                >
                  {`Override Prop Option Decoration (this prop only)`}
                </div>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => {
                    setPropOptionDecorationToUpdate(propOption1);
                  }}
                >
                  {`Update Prop Option Decoration (all future props using this prop option)`}
                </div>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => {
                    setShowPropOptionModal(true);
                    setPropOptionDecorationToOverride(undefined);
                  }}
                >
                  Add Prop Option
                </div>
              </div>
            )}
          </div>
          <div className={style.propOptionContainer}>
            <div className={style.dropDown}>
              <Select
                value={propOptions[propOption2]?.propText ?? ""}
                onChange={(e) => setPropOption2(e.target.value)}
                autoWidth={true}
                label="Prop Option 2"
                disabled={!!prop && !isDuplicating}
              >
                {propOptionsArray.map((option) => (
                  <MenuItem value={option.propText} key={option.propOptionId}>
                    {option.propText}
                  </MenuItem>
                ))}
              </Select>
              <div>Prop Option 2</div>
              {propOption2Error && (
                <div className={style.error}>{propOption2Error}</div>
              )}
            </div>
            {!!propOption2 && (
              <>
                <div
                  className={style.optionPreview}
                  style={{
                    backgroundColor:
                      propOption2DecorationOverride?.backgroundColor ??
                      propOptions[propOption2]?.defaultDecoration
                        ?.backgroundColor ??
                      "#000",
                    color:
                      propOption2DecorationOverride?.fontColor ??
                      propOptions[propOption2]?.defaultDecoration?.fontColor ??
                      "#FFF",
                  }}
                >
                  {propOptions[propOption2]?.propText}
                </div>
                <div className={style.optionImagePreviewContainer}>
                  <img
                    src={
                      propOption2DecorationOverride?.optionImageFileUrl ??
                      propOption2DecorationOverride?.optionImageB64 ??
                      propOptions[propOption2]?.defaultDecoration?.imageUrl ??
                      ""
                    }
                    alt="option image"
                    className={style.image}
                  />
                </div>
              </>
            )}
            <div style={{ padding: 6, fontWeight: 500, textAlign: "center" }}>
              <div style={{ fontSize: 14, fontWeight: 700 }}>Player Info</div>
              {(propOption2DecorationOverride
                ? propOption2DecorationOverride.propDecorationPlayerInfo
                : propOptions[propOption2]?.defaultDecoration
                    ?.propDecorationPlayerInfo ?? []
              ).map(
                (playerInfo) =>
                  !!players &&
                  !!teams && (
                    <div key={playerInfo.playerId + " " + playerInfo.teamId}>
                      {players[playerInfo.playerId]?.name} -{" "}
                      {teams[playerInfo.teamId]?.teamCode}
                    </div>
                  ),
              )}
            </div>
            {!!propOption2 && (!prop || isDuplicating) && (
              <div>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => {
                    setPropOptionDecorationToOverride(propOption2);
                    setShowPropOptionModal(true);
                  }}
                >
                  {`Override Prop Option Decoration (this prop only)`}
                </div>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => {
                    setPropOptionDecorationToUpdate(propOption2);
                  }}
                >
                  {`Update Prop Option Decoration (all future props using this prop option)`}
                </div>
                <div
                  className={style.addPropOptionButton}
                  onClick={() => {
                    setShowPropOptionModal(true);
                    setPropOptionDecorationToOverride(undefined);
                  }}
                >
                  Add Prop Option
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <TextInput
        className={style.textInput}
        value={televisionNetwork}
        onChange={setTelevisionNetwork}
        label="Game Info"
        error={tvNetworkError}
        disabled={isPublished}
      />
      {/* {!propImageB64 && (
        <div className={style.input}>
          <input
            type="file"
            name="image"
            onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
            accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
          />
          {propImageError && (
            <div className={style.error}>{propImageError}</div>
          )}
        </div>
      )}
      {!!propImageB64 && (
        <>
          <div className={style.imageContainer}>
            <img
              src={!selectedFile && !!prop ? prop.propImageUrl : propImageB64}
              alt="prop image"
              className={style.image}
            />
          </div>
          {!isPublished && (
            <div
              onClick={() => {
                setSelectedFile(null);
                setPropImageB64("");
              }}
              className={style.removeImageButton}
            >
              Remove image
            </div>
          )}
          {!!propImageB64 && propImageB64 !== "imageB64" && (
            <div
              className={style.removeImageButton}
              onClick={() => {
                navigator.clipboard.writeText(propImageB64);
                toast.success("b64 copied to clipboard");
              }}
            >
              Copy Image B64
            </div>
          )}
        </>
      )} */}
      <MDEditor
        value={propContent}
        onChange={setPropContent}
        className={style.mdWrapper}
        textareaProps={{
          disabled: isPublished,
        }}
      />
      {propContentError && (
        <div className={style.error}>{propContentError}</div>
      )}
      {!isPublished && (
        <div onClick={onSubmit} className={style.submit}>
          {submitting ? <Icon.Spinner size={15} /> : "Submit"}
        </div>
      )}
      {!!showPropOptionModal && (
        <Modal
          isOpen={showPropOptionModal}
          onRequestClose={() => setShowPropOptionModal(false)}
        >
          <CreatePropOption
            onClose={() => setShowPropOptionModal(false)}
            onOverride={
              !!propOptionDecorationToOverride
                ? (input) => {
                    if (propOptionDecorationToOverride === propOption1) {
                      setPropOption1DecorationOverride(input);
                    } else if (propOptionDecorationToOverride === propOption2) {
                      setPropOption2DecorationOverride(input);
                    }
                    setShowPropOptionModal(false);
                  }
                : undefined
            }
            selectedOption={
              !!propOptions && !!propOptionDecorationToOverride
                ? propOptions[propOptionDecorationToOverride]
                : undefined
            }
          />
        </Modal>
      )}
      {!!propOptionDecorationToUpdate && !!propOptions && (
        <Modal
          isOpen={!!propOptionDecorationToUpdate}
          onRequestClose={() => {
            setPropOptionDecorationToUpdate(undefined);
            setShowPropOptionModal(false);
          }}
        >
          <UpdatePropOption
            getTeams={getTeams}
            getPlayers={getPlayers}
            onClose={() => {
              setPropOptionDecorationToUpdate(undefined);
              setShowPropOptionModal(false);
              setPropOption1DecorationOverride(null);
              setPropOption2DecorationOverride(null);
            }}
            propOption={propOptions[propOptionDecorationToUpdate]}
          />
        </Modal>
      )}
      {!!authors && !!modalAuthor && (
        <Modal isOpen={!!modalAuthor} onRequestClose={() => setModalAuthor("")}>
          <Author
            onClose={() => setModalAuthor("")}
            author={authors[modalAuthor]}
          />
        </Modal>
      )}
      {!!showPropTagModal && (
        <Modal
          isOpen={!!showPropTagModal}
          onRequestClose={() => setShowPropTagModal(false)}
        >
          <CreatePropTag onClose={() => setShowPropTagModal(false)} />
        </Modal>
      )}
    </div>
  );
};

// const getDecorationOverride: Models.Props.CreatePropDecorationRequestInput | null = (propPropOption: Models.Props.PropPropOption) => {
//   if (!!propPropOption.decoration && !!propPropOption.decoration.imageUrl) {
//     const fileReader = new FileReader();
//     fileReader.readAsDataURL(propPropOption.decoration.imageUrl as any);
//     fileReader.onload = () => {
//       return {
//         optionImageB64: fileReader!.result!.toString(),
//         backgroundColor: propPropOption.decoration?.backgroundColor,
//         fontColor: propPropOption.decoration?.fontColor
//       }
//     };
//   } else {
//     return null
//   }
// }

export const CreateProp = connect(
  (state: RootState) => ({
    propOptions: state.props.propOptions,
    tags: state.props.propTags.items,
    tagsNextCursor: state.props.propTags.nextCursor,
    authorsArray: !!state.props.authors
      ? Object.values(state.props.authors)
      : [],
    specials: state.props.specials,
    players: state.players.players.items,
    teams: state.players.teams.items,
  }),
  {},
)(CreatePropInternal);
