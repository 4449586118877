import { gql } from "@apollo/client";

export const GET_SYSTEM_PROP_OPTIONS_QUERY = gql`
  query systemStatusPropOptions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [PropOptionSortInput!]
    $where: PropOptionFilterInput
  ) {
    systemStatusPropOptions(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      nodes {
        propOptionId
        propText
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
