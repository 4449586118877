import { style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({});

export const loggedOut = s({
  paddingTop: 50,
  width: "100%",
  textAlign: "center",
  fontSize: 40,
  fontWeight: 500,
});

export const tabs = s({
  textAlign: "center",
  display: "flex",
  color: Style.text.colors.main,
  marginBottom: 20,
});

export const pageTab = s({
  flex: 1,
  padding: 20,
  border: `2px solid black`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 24,
  fontWeight: 600,
  cursor: "pointer",
});

export const selectedPageTab = s({
  background: Style.colors.primary,
  color: Style.text.colors.main,
});

export const pageContainer = s({
  padding: 24,
});

export const inputWrapper = s({
  display: "flex",
  flexDirection: "row",
  gap: 16,
});

export const textInput = s({
  width: "100%",
  marginBottom: 16,
});

export const button = s({
  padding: 15,
  background: Style.colors.primary,
  color: Style.text.colors.main,
  border: "1px solid black",
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 600,
  width: 150,
});

export const disabled = s({
  backgroundColor: "gray !important",
  cursor: "not-allowed !important",
});

export const table = s({
  border: "1px solid black",
  marginBottom: 16,
});

export const item = s({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 12px",
  borderBottom: "1px solid black",
  $nest: {
    "&:last-child": {
      borderBottomWidth: 0,
    },
    "&:nth-child(even)": {
      backgroundColor: "rgba(0, 0, 0, .05)",
    },
  },
});

export const playerItem = s({
  cursor: "pointer",
  $nest: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, .1)",
    },
  },
});

export const searchInput = s({
  marginBottom: 8,
});
