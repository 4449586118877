import { gql } from "@apollo/client";

export const UPDATE_PROP_SORT_ORDER = gql`
  mutation updatePropSortOrder($input: UpdatePropSortOrderInput!) {
    updatePropSortOrder(input: $input) {
      prop {
        propExternalId
        sortOrder
      }
    }
  }
`;
