import { gql } from "@apollo/client";

export const PROP_UPDATE_MUTATION = gql`
  mutation PropUpdateMutation($input: UpdatePropInput!) {
    updateProp(input: $input) {
      prop {
        propExternalId
      }
    }
  }
`;
