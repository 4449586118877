import { gql } from "@apollo/client";

export const UPDATE_CAMPAIGN_MUTATION = gql`
  mutation UpdateCampaignMutation($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      campaign {
        id
        name
        description
        createdAtUtc
        updatedOnUtc
        updatedBy
      }
    }
  }
`;
