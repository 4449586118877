import { gql } from "@apollo/client";

export const GET_PARLAY_BET_PLACEMENTS_QUERY = gql`
  query GetParlayBetPlacements(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [ParlayBetPlacementSortInput!]
    $where: ParlayBetPlacementFilterInput
  ) {
    parlayBetPlacements(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
      nodes {
        transactionId
        wager
        createdOn
        userId
        legCount
        currencyTypeId
        betResultStatusId
        legs {
          propOptionId
          prop {
            resultId
            propLine
            propInternalId
            propPropOptions {
              propOptionId
              propOption {
                propText
              }
            }
          }
        }
      }
    }
  }
`;
