import { gql } from "@apollo/client";

export const WALLET_ZERO_ALL_USERS_SITE_CREDITS = gql`
  mutation WalletZeroAllUsersSiteCredits(
    $input: WalletZeroAllUsersSiteCreditsInput!
  ) {
    walletZeroAllUsersSiteCredits(input: $input) {
      boolean
      errors {
        ... on InvalidRefIdError {
          message
        }
      }
    }
  }
`;
