import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 20,
  paddingTop: 0,
  fontSize: 16,
});

export const messagesContainer = s({
  border: "1px solid black",
  borderBottomWidth: 0,
});

export const message = s({
  display: "flex",
  alignItems: "center",
  padding: `16px 0px`,
  borderBottom: "1px solid black",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent200,
      cursor: "pointer",
    },
  },
});

export const field = s({
  flexDirection: "column",
  flex: 1,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
