import { gql } from "@apollo/client";

export const PROP_CREATE_MUTATION = gql`
  mutation PropCreateMutation($input: PropCreateInput!) {
    propCreate(input: $input) {
      prop {
        propExternalId
      }
      errors {
        ... on MissingFieldsError {
          message
        }
      }
    }
  }
`;
