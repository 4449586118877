import { gql } from "@apollo/client";

export const GET_MESSAGES_QUERY = gql`
  query messages(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: MessageFilterInput
  ) {
    messages(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
    ) {
      nodes {
        messageId
        body
        title
        startDate
        endDate
        isActive
        category
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
