import { gql } from "@apollo/client";

export const GET_PROP_OPTIONS_QUERY = gql`
  query propOptions(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [PropOptionSortInput!]
    $where: PropOptionFilterInput
  ) {
    propOptions(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      nodes {
        propOptionId
        propText
        defaultDecorationId
        defaultDecoration {
          propDecorationId
          backgroundColor
          fontColor
          imageUrl
          propDecorationPlayerInfo {
            playerId
            teamId
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
