import React from "react";
import * as style from "./ZeroAllUsers.style";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../gql";
import { Icon, Modal, TextInput } from "../../components";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { colors } from "../../styles";

interface ComponentProps {
  onClose: () => void;
}

export const ZeroAllUsers: React.FC<ComponentProps> = ({ onClose }) => {
  const [clarifying, setClarifying] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [refId, setRefId] = React.useState(uuidv4());

  const [zeroAllUsers, { data, loading, error }] = useMutation(
    Mutation.WALLET_ZERO_ALL_USERS_SITE_CREDITS,
  );
  const onSubmit = () => {
    setSubmitting(true);
    zeroAllUsers({
      variables: {
        input: {
          refId,
        },
      },
    })
      .then((res) => {
        if (res.data.walletZeroAllUsersSiteCredits.boolean) {
          toast.success("successfully zeroed all users");
        } else {
          toast.error(
            !!res.data.walletZeroAllUsersSiteCredits.errors
              ? res.data.walletZeroAllUsersSiteCredits.errors[0].message
              : "Zeroing error",
          );
        }
        setSubmitting(false);
        onClose();
      })
      .catch((e) => {
        toast.error(
          "bulk zeroing error occurred, please close the modal and try again",
        );
        setSubmitting(false);
        console.error(e);
      });
  };

  return (
    <Modal isOpen={true} onRequestClose={onClose}>
      <div className={style.component}>
        <div className={style.title}>
          This will bulk zero <span style={{ fontWeight: 700 }}>all</span>{" "}
          users. Every user's Site Credit wallet will be set to 0.
        </div>
        <div
          onClick={!clarifying ? () => setClarifying(true) : onSubmit}
          className={style.submit}
        >
          {submitting ? (
            <Icon.Spinner
              size={20}
              fill={colors.black1}
              stroke={colors.black1}
            />
          ) : clarifying ? (
            "Are you sure?"
          ) : (
            "Submit"
          )}
        </div>
      </div>
    </Modal>
  );
};
