import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const prop = s({
  padding: 10,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  marginBottom: 20,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const propTitle = s({
  whiteSpace: "pre-wrap",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: "0 0 150px",
});

export const item = s({
  whiteSpace: "pre-wrap",
  textAlign: "center",
  flex: "0 0 150px",
});

export const buttons = s({});

export const buttonContainer = s({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: 4,
});

export const updateButton = s({
  borderRadius: 2,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  cursor: "pointer",
  padding: 6,
  backgroundColor: Style.colors.primary,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.subtle,
      transition: ".2s ease-in",
    },
  },
});

export const resultButton = s({
  borderRadius: 2,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  cursor: "pointer",
  padding: 6,
  backgroundColor: Style.colors.error,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.softError,
      transition: ".2s ease-in",
    },
  },
});

export const duplicateButton = s({
  borderRadius: 2,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  cursor: "pointer",
  padding: 6,
  backgroundColor: "lightBlue",
  $nest: {
    "&:hover": {
      backgroundColor: "aqua",
      transition: ".2s ease-in",
    },
  },
});

export const disabled = s({
  backgroundColor: Style.colors.darkTranslucent200,
  cursor: "not-allowed",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent200,
    },
  },
});

export const dropDown = s({
  textAlign: "right",
});

export const dropDownPublished = s({
  backgroundColor: "lightGreen",
});

export const dropDownDraft = s({
  backgroundColor: "lightYellow",
});

export const dropDownRemoved = s({
  backgroundColor: "pink",
});

export const filterRow = s({
  display: "flex",
  gap: 16,
  marginBottom: 12,
});
