import { gql } from "@apollo/client";

export const GET_AUTHORS_QUERY = gql`
  query authors(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: AuthorFilterInput
    $order: [AuthorSortInput!]
  ) {
    authors(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      order: $order
    ) {
      nodes {
        authorId
        authorExternalId
        name
        email
        discord
        tikTok
        instagram
        twitter
        imageUrl
        blurb
        title
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
