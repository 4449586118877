import * as React from "react";
import * as style from "./TextInput.style";
import { hooks } from "../../utility";
import { classes } from "typestyle";

interface ComponentProps {
  wrapperClassName?: string;
  className?: string;
  label: string;
  onChange?: (val: string) => void;
  value?: string;
  onBlur?: () => void;
  inputType?: React.HTMLInputTypeAttribute;
  error?: string;
  isTextArea?: boolean;
  disabled?: boolean;
}

const TextInput: React.FC<ComponentProps> = ({
  wrapperClassName,
  className,
  label,
  value,
  onBlur,
  onChange,
  inputType = "text",
  error,
  isTextArea = false,
  disabled = false,
}) => {
  const [isActive, setActive] = React.useState(false);
  const [inputRef, setInputFocus] = hooks.useFocus();
  return (
    <div className={classes(wrapperClassName, style.wrapper)}>
      {!isTextArea ? (
        <input
          ref={inputRef}
          type={inputType}
          className={classes(
            className,
            style.component,
            isActive && style.active,
            !!error && style.hasError,
            disabled && style.disabled,
          )}
          value={value || ""}
          onChange={!!onChange ? (e) => onChange(e.target.value) : undefined}
          onFocus={() => setActive(true)}
          onBlur={() => {
            if (!!onBlur) {
              onBlur();
            }
            setActive(false);
          }}
          disabled={disabled}
        />
      ) : (
        <textarea
          className={classes(
            className,
            style.component,
            isActive && style.active,
            !!error && style.hasError,
          )}
          value={value || ""}
          onChange={!!onChange ? (e) => onChange(e.target.value) : undefined}
          onFocus={() => setActive(true)}
          onBlur={() => {
            if (!!onBlur) {
              onBlur();
            }
            setActive(false);
          }}
          disabled={disabled}
        />
      )}
      <div
        className={classes(style.label, !!value ? style.filledLabel : "")}
        onClick={setInputFocus}
      >
        {label}
      </div>
      <div className={style.error}>{!!error ? error : " "}</div>
    </div>
  );
};

TextInput.displayName = "TextInput";
export { TextInput };
