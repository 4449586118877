import React, { useState, useEffect, FC } from "react";
import * as style from "./UserTransactions.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../../gql";
import { PropActions } from "../../../store/actions";
import { useDispatch } from "react-redux";
import * as Models from "../../../models";
import { Icon } from "../../../components";
import { date } from "../../../utility";
import { classes } from "typestyle";
import { toast } from "react-toastify";

interface StateProps {
  user: Models.Users.FirebaseUserEntity;
  onClose: () => void;
}

type TransactionMode = "USD" | "SITE_CREDIT" | "ALL";

export const UserTransactions: FC<StateProps> = ({ user, onClose }) => {
  /* Apollo */
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  /* Values */
  const [nextCursor, setNextCursor] = useState<string | undefined>();
  const [hasNext, setHasNext] = useState(false);
  const [transactions, setTransactions] = useState<
    Models.Transactions.UnitsTransaction[] | null
  >(null);
  const [transactionMode, setTransactionMode] =
    React.useState<TransactionMode>("ALL");
  const filteredTransactions = React.useMemo(() => {
    if (transactionMode === "ALL") {
      return transactions;
    } else {
      return (
        transactions?.filter((t) => t.unitsCurrencyId === transactionMode) ??
        null
      );
    }
  }, [transactions, transactionMode]);

  const getTransactions = () => {
    setLoading(true);
    client
      .query({
        query: Query.WALLET_USER_TRANSACTIONS_QUERY,
        variables: {
          userId: user.id,
          first: 10,
          after: nextCursor,
          order: [
            {
              transactionDateUtc: "DESC",
            },
          ],
        },
      })
      .then((res) => {
        setLoading(false);
        // PropActions.GetPropsSuccess({
        //     props: res.data.props,
        //     hasNext: res.data.props.pageInfo.hasNextPage,
        //     nextCursor: res.data.props.pageInfo.endCursor,
        //   }),
        setTransactions(
          !!transactions
            ? [...transactions, ...res.data.walletUserTransactions.nodes]
            : res.data.walletUserTransactions.nodes,
        );
        setHasNext(res.data.walletUserTransactions.pageInfo.hasNextPage);
        setNextCursor(res.data.walletUserTransactions.pageInfo.endCursor);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <>
      <div className={style.component}>
        <div className={style.title}>{user.email}</div>
        <div className={style.options}>
          <div
            className={
              transactionMode === "ALL" ? style.selectedOption : style.option
            }
            onClick={() => {
              setTransactionMode("ALL");
            }}
          >
            All
          </div>
          <div
            className={
              transactionMode === "SITE_CREDIT"
                ? style.selectedOption
                : style.option
            }
            onClick={() => {
              setTransactionMode("SITE_CREDIT");
            }}
          >
            Site Credit
          </div>
          <div
            className={
              transactionMode === "USD" ? style.selectedOption : style.option
            }
            onClick={() => {
              setTransactionMode("USD");
            }}
          >
            USD
          </div>
        </div>
        {!filteredTransactions ? (
          <div>
            Fetching Transactions <Icon.Spinner size={30} />
          </div>
        ) : (
          <>
            <div className={style.transaction}>
              <div className={style.columnLeft}>Receipt</div>
              <div className={style.columnLeft}>Reason</div>
              <div className={style.columnLeft}>Date</div>
              <div className={style.columnCenter}>Opening Balance</div>
              <div className={style.columnRight}>Transaction Amount</div>
              <div className={style.columnRight}>Current Balance</div>
            </div>
            {filteredTransactions.map((transaction) => {
              const refId = transaction.refId.split(":");
              return (
                <div className={style.transaction} key={transaction.refId}>
                  <div
                    onClick={() => {
                      navigator.clipboard.writeText(transaction.refId);
                      toast.success("refId copied to clipboard");
                    }}
                    className={classes(style.columnLeft, style.refId)}
                  >
                    {refId.length > 2
                      ? `${refId[1]}:${refId[2].slice(0, 5)}`
                      : transaction.refId.slice(0, 12)}
                    ...
                  </div>
                  <div className={style.columnLeft}>{transaction.reason}</div>
                  <div className={style.columnLeft}>
                    {date.toShortDateAndTimeUTC(
                      new Date(transaction.transactionDateUtc),
                    )}
                  </div>
                  <div className={style.columnCenter}>
                    <span>{transaction.openingBalance}</span>
                  </div>
                  <div className={style.columnRight}>
                    {transaction.transactionAmount}
                  </div>
                  <div className={style.columnRight}>
                    <span
                      className={
                        transaction.unitsCurrencyId === "USD"
                          ? style.usdLabel
                          : style.siteCreditLabel
                      }
                    >
                      {transaction.unitsCurrencyId === "USD" ? "USD" : "Credit"}
                    </span>
                    {transaction.currentBalance}
                  </div>
                </div>
              );
            })}
          </>
        )}
        <div className={style.buttonRow}>
          <div onClick={onClose} className={style.button}>
            Close
          </div>
          {hasNext && (
            <div onClick={getTransactions} className={style.button}>
              {loading ? <Icon.Spinner size={15} /> : "More"}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
