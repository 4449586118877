import { gql } from "@apollo/client";

export const PROP_DECORATION_UPDATE_MUTATION = gql`
  mutation PropDecorationUpdate($input: PropDecorationUpdateInput!) {
    propDecorationUpdate(input: $input) {
      propDecoration {
        propDecorationId
        backgroundColor
        fontColor
        imageUrl
        propDecorationPlayerInfo {
          playerId
          teamId
        }
      }
    }
  }
`;
