import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({});

export const loggedOut = s({
  paddingTop: 50,
  width: "100%",
  textAlign: "center",
  fontSize: 40,
  fontWeight: 500,
});

export const tabs = s({
  textAlign: "center",
  display: "flex",
  color: Style.text.colors.main,
  marginBottom: 20,
});

export const pageTab = s({
  flex: 1,
  padding: 20,
  border: `2px solid black`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 24,
  fontWeight: 600,
  cursor: "pointer",
});

export const selectedPageTab = s({
  background: Style.colors.primary,
  color: Style.text.colors.main,
});
