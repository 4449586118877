import * as React from "react";
import { useFirebaseContext } from "./FirebaseContext";
import * as style from "./LoginButton.style";
import googleLogo from "../assets/google.png";
import { classes } from "typestyle";

interface LoginButtonProps {
  className?: string;
}

export const LoginButton: React.FC<LoginButtonProps> = ({ className }) => {
  const { auth, login, logout, loading } = useFirebaseContext();

  return (
    <button
      className={classes(className, style.component)}
      onClick={!auth || !auth.currentUser ? login : logout}
    >
      <div className={style.content}>
        <div className={style.logoContainer}>
          <img
            className={style.logo}
            alt="google logo"
            src={googleLogo}
            width={27}
            height={27}
          />
        </div>
        <div>
          {!!auth && !!auth.currentUser ? "Log out" : "Login With Google"}
          {loading && "*"}
        </div>
      </div>
    </button>
  );
};
