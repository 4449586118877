import React, { useState, useEffect } from "react";
import * as style from "./CreateMessage.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { DateTimePicker, Icon, TextInput } from "../../components";
import { useDispatch } from "react-redux";
import { MessageActions } from "../../store/actions";
import { useFirebaseContext } from "../../auth";
import MDEditor from "@uiw/react-md-editor";
import * as Models from "../../models";
import { MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { colors } from "../../styles";
import { decode } from "html-entities";

interface OwnProps {
  message?: Models.Messages.Message | null;
  onClose?: () => void;
  isDuplicating?: boolean;
}

type ComponentProps = OwnProps;
export const CreateMessage: React.FC<ComponentProps> = ({
  message,
  onClose,
  isDuplicating,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();

  /* Values */
  const [startDate, setStartDate] = useState<Date | null>(
    message ? new Date(message.startDate) : null,
  );
  const [endDate, setEndDate] = useState<Date | null>(
    message ? new Date(message.endDate) : null,
  );

  const [title, setTitle] = useState<string>(
    message ? message.title : "Title here",
  );
  const [body, setBody] = useState<string | undefined>(
    message ? message.body : "Message body here",
  );
  const [category, setCategory] =
    useState<Models.Messages.MessageCategory | null>(
      message ? message.category : Models.Messages.MessageCategory.PROMOTION,
    );
  const [isActive, setIsActive] = React.useState<boolean>(
    message ? message.isActive : false,
  );

  /* Errors */
  const [startTimeError, setStartTimeError] = useState<string>("");
  const [endTimeError, setEndTimeError] = useState<string>("");
  const [titleError, setTitleError] = useState<string>("");
  const [bodyError, setBodyError] = useState<string>("");

  /** Submission */
  const [submitting, setSubmitting] = useState(false);
  const [createMessage, createMessageStatus] = useMutation(
    Mutation.CREATE_MESSAGE_MUTATION,
  );
  const [updateMessage, updateMessageStatus] = useMutation(
    Mutation.UPDATE_MESSAGE_MUTATION,
  );

  const onSubmit = () => {
    if (!startDate) {
      setStartTimeError("Select a valid start date");
    } else {
      setStartTimeError("");
    }
    if (!endDate) {
      setEndTimeError("Select a valid end date");
    } else {
      setEndTimeError("");
    }
    if (!title) {
      setTitleError("Input a valid title");
    } else if (title.length > 100) {
      setTitleError(
        "Title must be less than 100 characters. You currently have " +
          title.length,
      );
    } else {
      setTitleError("");
    }
    if (!body) {
      setBodyError("Input a valid message body");
    } else if (body.length > 1000) {
      setBodyError(
        "Body must be less than 85 characters. You currently have " +
          body.length,
      );
    } else {
      setBodyError("");
    }
    setSubmitting(true);
  };

  React.useEffect(() => {
    if (submitting) {
      if (!startTimeError && !startTimeError && !titleError && !bodyError) {
        if (!!message) {
          updateMessage({
            variables: {
              input: {
                request: {
                  messageId: message.messageId,
                  startDate,
                  endDate,
                  title,
                  body,
                  category,
                  isActive,
                },
              },
            },
          })
            .then((res) => {
              dispatch(
                MessageActions.UpdateMessageSuccess({
                  message: res.data.updateMessage.message,
                }),
              );
              toast.success(`successfully updated message`);
              setSubmitting(false);
              setStartDate(null);
              setEndDate(null);
              setTitle("");
              setBody("");
              setCategory(null);
              setIsActive(false);
              if (onClose) {
                onClose();
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              if (onClose) {
                onClose();
              }
            });
        } else {
          createMessage({
            variables: {
              input: {
                request: {
                  startDate,
                  endDate,
                  title,
                  body,
                  isActive,
                  category: category?.toString(),
                },
              },
            },
          })
            .then((res) => {
              if (res.data?.createMessage?.message) {
                dispatch(
                  MessageActions.UpdateMessageSuccess({
                    message: res.data.createMessage.message,
                  }),
                );
                toast.success(`successfully created message`);
                setSubmitting(false);
                setStartDate(null);
                setEndDate(null);
                setTitle("");
                setBody("");
                setCategory(Models.Messages.MessageCategory.PROMOTION);
                setIsActive(false);
                if (onClose) {
                  onClose();
                }
              } else {
                toast.error(`We were unable to create this message`);
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              if (onClose) {
                onClose();
              }
            });
        }
      } else {
        setSubmitting(false);
      }
    }
  }, [submitting]);

  return (
    <div className={style.component}>
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={startDate}
        onChange={(date) => setStartDate(date)}
        label="Start Date"
        error={startTimeError}
      />
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={endDate}
        onChange={(date) => setEndDate(date)}
        label="End Date"
        error={endTimeError}
      />
      <TextInput
        className={style.textInput}
        value={title}
        onChange={setTitle}
        label="Title"
        error={titleError}
      />
      <MDEditor value={body} onChange={setBody} className={style.mdWrapper} />
      <div className={style.inputContainer}>
        <Select
          value={category ?? ""}
          onChange={(e) =>
            setCategory(e.target.value as Models.Messages.MessageCategory)
          }
          autoWidth={true}
          label="Category"
        >
          <MenuItem value={Models.Messages.MessageCategory.PROMOTION}>
            {"Promotion"}
          </MenuItem>
          <MenuItem value={Models.Messages.MessageCategory.MAINTENANCE}>
            {"Maintenance"}
          </MenuItem>
          <MenuItem value={Models.Messages.MessageCategory.OUTAGE}>
            {"Outage"}
          </MenuItem>
        </Select>
        <div>Category</div>
      </div>
      <div className={style.inputContainer}>
        <div
          className={style.checkbox}
          style={{ backgroundColor: isActive ? colors.success : colors.error }}
          onClick={() => {
            setIsActive(!isActive);
          }}
        />
        <div>{`Is Active (${
          isActive
            ? "Yes - will appear in app between start and end date"
            : "No - will not appear in app until set to active"
        })`}</div>
      </div>
      <div onClick={onSubmit} className={style.submit}>
        {submitting ? <Icon.Spinner size={15} /> : "Submit"}
      </div>
    </div>
  );
};
