import { gql } from "@apollo/client";

export const GET_CONTESTS = gql`
  query contest(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: ContestFilterInput
  ) {
    contests(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
    ) {
      nodes {
        id
        name
        description
        reward1
        reward2
        reward3
        reward4
        reward5
        tags {
          name
          propTagId
        }
        isActive
        contestStartsAtUtc
        contestEndsAtUtc
        viewableStartsAtUtc
        viewableEndsAtUtc
        createdAtUtc
        updatedAtUtc
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
