import { gql } from "@apollo/client";

export const CREATE_AUTHOR_MUTATION = gql`
  mutation CreateAuthorMutation($input: CreateAuthorInput!) {
    createAuthor(input: $input) {
      author {
        authorId
        authorExternalId
        name
        email
        discord
        tikTok
        instagram
        twitter
        imageUrl
        blurb
        title
      }
    }
  }
`;
