import React, { useState, useEffect } from "react";
import * as style from "./Author.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation } from "../../gql";
import { Icon, TextInput } from "../../components";
import { connect, useDispatch } from "react-redux";
import { PropActions } from "../../store/actions";
import { RootState } from "../../store/reducers";
import * as Models from "../../models";
import { toast } from "react-toastify";

interface OwnProps {
  onClose: () => void;
  author?: Models.Props.Author;
}

type ComponentProps = OwnProps;

export const Author: React.FC<ComponentProps> = ({
  author,
  onClose,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  /* Values */
  const [name, setName] = useState<string>(author ? author.name : "");
  const [email, setEmail] = useState<string>(author ? author.email : "");
  const [discord, setDiscord] = useState<string>(author?.discord ?? "");
  const [tikTok, setTikTok] = useState<string>(author?.tikTok ?? "");
  const [instagram, setInstagram] = useState<string>(author?.instagram ?? "");
  const [twitter, setTwitter] = useState<string>(author?.twitter ?? "");
  const [blurb, setBlurb] = useState<string>(author ? author.blurb : "");
  const [title, setTitle] = useState<string>(author ? author.title : "");

  /* Errors */
  const [nameError, setNameError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [blurbError, setBlurbError] = useState<string>("");
  const [titleError, setTitleError] = useState<string>("");
  const [imageError, setImageError] = useState<string>("");

  /** Image  */
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageB64, setImageB64] = useState<string>(author ? "imageB64" : "");
  useEffect(() => {
    if (selectedFile != null) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        setImageB64(fileReader!.result!.toString());
      };
    }
  }, [selectedFile]);

  const [createAuthor, createAuthorStatus] = useMutation(
    Mutation.CREATE_AUTHOR_MUTATION,
  );
  const [updateAuthor, updateAuthorStatus] = useMutation(
    Mutation.UPDATE_AUTHOR_MUTATION,
  );
  const [deleteAuthor, deleteAuthorStatus] = useMutation(
    Mutation.DELETE_AUTHOR_MUTATION,
  );
  const onCreate = () => {
    let hasError = false;
    if (!name) {
      setNameError("name is required");
      hasError = true;
    } else {
      setNameError("");
    }
    if (!email) {
      setEmailError("email is required");
      hasError = true;
    } else {
      setEmailError("");
    }
    if (!title) {
      setTitleError("title is required");
      hasError = true;
    } else {
      setTitleError("");
    }
    if (!blurb) {
      setBlurbError("blurb is required");
      hasError = true;
    } else {
      setBlurbError("");
    }
    if (!imageB64) {
      setImageError("image is required");
      hasError = true;
    } else {
      setImageError("");
    }
    if (!hasError) {
      setLoading(true);
      createAuthor({
        variables: {
          input: {
            createRequest: {
              name,
              email,
              discord,
              tikTok,
              instagram,
              twitter,
              imageB64,
              blurb,
              title,
            },
          },
        },
      })
        .then((res) => {
          setLoading(false);
          dispatch(
            PropActions.UpdateAuthorSuccess({
              authorId: res.data.createAuthor.author.authorId,
              author: res.data.createAuthor.author,
            }),
          );
          toast.success(
            `successfully created author ${name}`,
          );
          onClose();
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
          onClose();
        });
    }
  };
  const onUpdate = () => {
    if (author) {
      setLoading(true);
      updateAuthor({
        variables: {
          input: {
            updateRequest: {
              authorId: author?.authorId,
              name,
              email,
              discord,
              tikTok,
              instagram,
              twitter,
              imageB64:
                !!imageB64 && imageB64 !== "imageB64" ? imageB64 : undefined,
              blurb,
              title,
            },
          },
        },
      })
        .then((res) => {
          setLoading(false);
          toast.success(
            `successfully updated author ${name}`,
          );
          dispatch(
            PropActions.UpdateAuthorSuccess({
              authorId: res.data.updateAuthor.author.authorId,
              author: res.data.updateAuthor.author,
            }),
          );
          onClose();
        })
        .catch((e) => {
          setLoading(false);
          console.error(e);
          onClose();
        });
    }
  };

  const onDelete = () => {
    if (author) {
      setDeleting(true);
      deleteAuthor({
        variables: {
          input: {
            authorId: author.authorId,
          },
        },
      })
        .then((res) => {
          setDeleting(false);
          toast.success(
            `successfully deleted author ${name}`,
          );
          dispatch(
            PropActions.UpdateAuthorSuccess({
              authorId: author.authorId,
              author: null,
            }),
          );
          onClose();
        })
        .catch((e) => {
          setDeleting(false);
          console.error(e);
          onClose();
        });
    }
  };
  return (
    <div className={style.component}>
      <div className={style.row}>
        <TextInput
          className={style.textInput}
          value={name}
          onChange={setName}
          label="name"
          error={nameError}
        />
        <TextInput
          className={style.textInput}
          value={email}
          onChange={setEmail}
          label="email"
          error={emailError}
        />
      </div>
      <div className={style.row}>
        <TextInput
          className={style.textInput}
          value={discord}
          onChange={setDiscord}
          label="discord"
        />
        <TextInput
          className={style.textInput}
          value={tikTok}
          onChange={setTikTok}
          label="tiktok"
        />
      </div>
      <div className={style.row}>
        <TextInput
          className={style.textInput}
          value={instagram}
          onChange={setInstagram}
          label="instagram"
        />
        <TextInput
          className={style.textInput}
          value={twitter}
          onChange={setTwitter}
          label="twitter"
        />
      </div>
      <div className={style.row}>
        <TextInput
          className={style.textArea}
          value={blurb}
          onChange={setBlurb}
          label="blurb"
          isTextArea
          error={blurbError}
        />
        <TextInput
          className={style.textInput}
          value={title}
          onChange={setTitle}
          label="title"
          error={titleError}
        />
      </div>
      {!imageB64 && (
        <>
          <div className={style.input}>
            <input
              type="file"
              name="image"
              onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
              accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
            />
          </div>
          {imageError && <div className={style.error}>image is required</div>}
        </>
      )}
      {!!imageB64 && (
        <>
          <div className={style.imageContainer}>
            <img
              src={!selectedFile && !!author ? author.imageUrl : imageB64}
              alt="prop image"
              className={style.image}
            />
          </div>
          <div
            onClick={() => {
              setSelectedFile(null);
              setImageB64("");
            }}
            className={style.removeImageButton}
          >
            Remove image
          </div>
          {!!imageB64 && imageB64 !== "imageB64" && (
            <div
              className={style.removeImageButton}
              onClick={() => {
                navigator.clipboard.writeText(imageB64);
                toast.success("b64 copied to clipboard");
              }}
            >
              Copy Image B64
            </div>
          )}
        </>
      )}
      <div className={style.row}>
        <div onClick={author ? onUpdate : onCreate} className={style.submit}>
          {loading ? <Icon.Spinner size={15} /> : author ? "Update" : "Create"}
        </div>
        {!!author && (
          <div onClick={onDelete} className={style.submit}>
            {deleting ? <Icon.Spinner size={15} /> : "Delete"}
          </div>
        )}
      </div>
    </div>
  );
};
