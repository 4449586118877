import { gql } from "@apollo/client";

export const GET_USER_BET_PLACEMENTS_QUERY = gql`
  query GetUserBetPlacements(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [ParlayBetPlacementSortInput!]
    $where: ParlayBetPlacementFilterInput
  ) {
    userBetPlacements(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      where: $where
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          parlayBetPlacementId
          transactionId
          userId
          createdOn
          updatedOn
          wager
          placementStatus {
            placementStatusId
            betPlacementStatusName
          }
          placementStatusId
          legs {
            legBetPlacementId
            parlayBetPlacementId
            propOptionId
            propInternalId
          }
          currencyTypeId
        }
      }
      nodes {
        parlayBetPlacementId
        transactionId
        createdOn
        updatedOn
        wager
        placementStatusId
        currencyTypeId
        legs {
          legBetPlacementId
          parlayBetPlacementId
          propOptionId
          propInternalId
        }
      }
    }
  }
`;
