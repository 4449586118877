import React from "react";
import * as style from "./AuthorizationSettings.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { Icon, Modal, TextInput } from "../../components";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { colors } from "../../styles";
import { useFirebaseContext } from "../../auth";

interface ComponentProps {
  onClose: () => void;
  isOpen: boolean;
}

export const AuthorizationSettings: React.FC<ComponentProps> = ({
  onClose,
  isOpen,
}) => {
  const client = useApolloClient();
  const [clarifying, setClarifying] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [isAllowListRequired, setIsAllowListRequired] = React.useState<
    null | boolean
  >(null);
  const { refreshToken } = useFirebaseContext();

  const getAuthSettings = () => {
    client
      .query({
        query: Query.GET_AUTHORIZATION_SETTINGS,
      })
      .then((res) => {
        setIsAllowListRequired(
          res.data.authorizationSettings.isAllowListRequired,
        );
      });
  };

  React.useEffect(() => {
    if (!!refreshToken) {
      getAuthSettings();
    }
  }, [refreshToken]);

  const [updateAuthorizationSettings, { data, loading, error }] = useMutation(
    Mutation.AUTHORIZATION_UPDATE_SETTINGS_MUTATION,
  );
  const onSubmit = () => {
    setSubmitting(true);
    updateAuthorizationSettings({
      variables: {
        input: {
          request: {
            isAllowListRequired: !isAllowListRequired,
          },
        },
      },
    })
      .then((res) => {
        if (res?.data?.authorizationUpdateSettings?.authorizationSettings) {
          getAuthSettings();
        }
        toast.success(
          `successfully set allowlist to ${
            res.data.authorizationUpdateSettings.authorizationSettings
              .isAllowListRequired
              ? "ON"
              : "OFF"
          }`,
        );
        setSubmitting(false);
        onClose();
      })
      .catch((e) => {
        toast.error("error updating allowlist settings, please try again");
        setSubmitting(false);
        console.error(e);
      });
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={style.component}>
        <div className={style.title}>
          The Beta allowlist is currently{" "}
          <span style={{ fontWeight: 700 }}>
            {isAllowListRequired ? "ON" : "OFF"}
          </span>
          . This means that{" "}
          {!isAllowListRequired
            ? "new signups will be immediately enabled as beta users"
            : "new signups will be added to the allowlist and will need to be manually let in to the beta"}
        </div>
        <div className={style.subTitle}>
          Click the button to toggle the Beta allowlist to be{" "}
          <span style={{ fontWeight: 700 }}>
            {!isAllowListRequired ? "ON" : "OFF"}
          </span>
          . This would mean that{" "}
          {isAllowListRequired
            ? "new signups will be immediately enabled as beta users"
            : "new signups will be added to the allowlist and will need to be manually let in to the beta"}
        </div>
        <div
          onClick={!clarifying ? () => setClarifying(true) : onSubmit}
          className={style.submit}
        >
          {submitting ? (
            <Icon.Spinner
              size={20}
              fill={colors.black1}
              stroke={colors.black1}
            />
          ) : clarifying ? (
            "Are you sure?"
          ) : (
            "Submit"
          )}
        </div>
      </div>
    </Modal>
  );
};
