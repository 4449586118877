import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  height: 90,
  background: "rgba(50,50,50)",
  padding: 20,
  display: "flex",
  color: "white",
  boxSizing: "border-box",
  alignItems: "center",
  justifyContent: "flex-end",
});

export const copyButton = s({
  cursor: "pointer",
  padding: "10px 20px",
  background: Style.colors.white,
  display: "flex",
  textAlign: "center",
  marginBottom: 10,
  justifyContent: "center",
  textDecoration: "none",
  borderRadius: 4,
  color: "black",
  marginRight: 10,
});

export const tokenInputWrapper = s({
  marginRight: 10,
  paddingTop: 10,
});

export const tokenInput = s({
  backgroundColor: "white",
});
