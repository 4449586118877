import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * Wallet Transactions
   */
  GET_WALLET_TRANSACTIONS_SUCCESS = "TRANSACTIONS/GET_WALLET_TRANSACTIONS_SUCCESS",
  /**
   * Bet Placements
   */
  GET_BET_PLACEMENTS_SUCCESS = "TRANSACTIONS/GET_BET_PLACEMENTS_SUCCESS",
}

export const GetTransactionsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_WALLET_TRANSACTIONS_SUCCESS,
  {
    items: Models.Transactions.UnitsTransaction[];
    totalCount: number;
    pageInfo: {
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }
> = ActionModels.createActionCreator(
  ActionTypes.GET_WALLET_TRANSACTIONS_SUCCESS
);

export const GetBetPlacementsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_BET_PLACEMENTS_SUCCESS,
  {
    pageInfo: Models.PageInfo;
    /** A list of edges. */
    edges?: Models.Bet.UserBetPlacementsEdge[];
    /** A flattened list of the nodes. */
    nodes?: Models.Bet.ParlayBetPlacement[];
    totalCount: number;
  }
> = ActionModels.createActionCreator(ActionTypes.GET_BET_PLACEMENTS_SUCCESS);

export type Actions =
  | ReturnType<typeof GetTransactionsSuccess>
  | ReturnType<typeof GetBetPlacementsSuccess>;
