import * as Models from "../../models";
import { PropActions } from "../actions";

export interface PropStore {
  props: {
    resulted: {
      items: Record<string, Models.Props.Prop> | null;
      hasNext: boolean;
      nextCursor?: string;
    };
    pending: {
      items: Record<string, Models.Props.Prop> | null;
      hasNext: boolean;
      nextCursor?: string;
    };
  };
  propOptions: Record<string, Models.Props.PropOption> | null;
  systemPropOptions: Record<string, Models.Props.PropOption> | null;
  propTags: {
    items: Record<string, Models.Props.PropTag> | null;
    hasNext: boolean;
    nextCursor?: string;
  };
  authors: Record<number, Models.Props.Author> | null;
  specials: Record<number, Models.Props.PropSpecial> | null;
}

export const initialState: PropStore = {
  props: {
    pending: {
      items: null,
      hasNext: false,
    },
    resulted: {
      items: null,
      hasNext: false,
    },
  },
  propOptions: null,
  systemPropOptions: null,
  propTags: {
    items: null,
    hasNext: false,
  },
  authors: null,
  specials: null,
};

export const props = (
  state: PropStore = initialState,
  action: PropActions.Actions,
): PropStore => {
  switch (action.type) {
    case PropActions.ActionTypes.GET_PROPS_SUCCESS: {
      let newDict: Record<string, Models.Props.Prop> = {};
      if (!!action.payload.isResulted) {
        newDict = state.props.resulted.items
          ? { ...state.props.resulted.items }
          : {};
      } else {
        newDict = state.props.pending.items
          ? { ...state.props.pending.items }
          : {};
      }

      action.payload.props.nodes.forEach((prop) => {
        newDict[prop.propExternalId] = prop;
      });
      return {
        ...state,
        props: !!action.payload.isResulted
          ? {
              ...state.props,
              resulted: {
                items: newDict,
                hasNext: action.payload.hasNext,
                nextCursor: action.payload.nextCursor,
              },
            }
          : {
              ...state.props,
              pending: {
                items: newDict,
                hasNext: action.payload.hasNext,
                nextCursor: action.payload.nextCursor,
              },
            },
      };
      // return {
      //   ...state,
      //   props: {
      //     items: newDict,
      //     hasNext: action.payload.hasNext,
      //     nextCursor: action.payload.nextCursor,
      //   },
      // };
    }
    case PropActions.ActionTypes.UPDATE_PROP_SUCCESS: {
      const { propExternalId } = action.payload.prop;
      return {
        ...state,
        props: {
          ...state.props,
          pending: {
            ...state.props.pending,
            items: {
              ...state.props.pending.items,
              [propExternalId]: {
                ...(state.props.pending.items &&
                  state.props.pending.items[propExternalId]),
                ...action.payload.prop,
              },
            },
          },
        },
      };
    }
    case PropActions.ActionTypes.GET_PROP_OPTIONS_SUCCESS: {
      const newDict: Record<string, Models.Props.PropOption> = {
        ...state.propOptions,
      };
      action.payload.props.nodes.forEach((propOption) => {
        newDict[propOption.propText] = propOption;
      });
      return {
        ...state,
        propOptions: newDict,
      };
    }
    case PropActions.ActionTypes.GET_SYSTEM_PROP_OPTIONS_SUCCESS: {
      const newDict: Record<string, Models.Props.PropOption> = {};
      action.payload.props.nodes.forEach((propOption) => {
        newDict[propOption.propText] = propOption;
      });
      return {
        ...state,
        systemPropOptions: newDict,
      };
    }
    case PropActions.ActionTypes.CREATE_PROP_OPTION_SUCCESS: {
      const newPropOptions = state.propOptions ? { ...state.propOptions } : {};
      newPropOptions[action.payload.propOption.propText] =
        action.payload.propOption;
      return {
        ...state,
        propOptions: newPropOptions,
      };
    }
    case PropActions.ActionTypes.UPDATE_PROP_DECORATION_SUCCESS: {
      const newPropOptions = state.propOptions ? { ...state.propOptions } : {};
      newPropOptions[action.payload.propOption.propText] = {
        ...action.payload.propOption,
        defaultDecoration: action.payload.newDecoration,
      };
      return {
        ...state,
        propOptions: newPropOptions,
      };
    }
    case PropActions.ActionTypes.GET_PROP_TAGS_SUCCESS: {
      const newDict: Record<string, Models.Props.PropTag> = !!state.propTags
        .items
        ? { ...state.propTags.items }
        : {};
      action.payload.tags.nodes.forEach((propTag) => {
        newDict[propTag.name] = propTag;
      });
      return {
        ...state,
        propTags: {
          items: newDict,
          hasNext: action.payload.hasNext,
          nextCursor: action.payload.nextCursor,
        },
      };
    }
    case PropActions.ActionTypes.CREATE_PROP_TAG_SUCCESS: {
      return {
        ...state,
        propTags: {
          ...state.propTags,
          [action.payload.tag.propTagId]: action.payload.tag,
        },
      };
    }
    case PropActions.ActionTypes.GET_AUTHORS_SUCCESS: {
      const newDict: Record<string, Models.Props.Author> = {};
      action.payload.authors.nodes.forEach((author) => {
        newDict[author.authorId] = author;
      });
      return {
        ...state,
        authors: newDict,
      };
    }
    case PropActions.ActionTypes.GET_PROP_SPECIALS_SUCCESS: {
      const newDict: Record<string, Models.Props.PropSpecial> = {};
      action.payload.specials.forEach((special) => {
        newDict[special.propSpecialId] = special;
      });
      return {
        ...state,
        specials: newDict,
      };
    }
    case PropActions.ActionTypes.UPDATE_AUTHOR_SUCCESS: {
      const newDict: Record<string, Models.Props.Author> = !!state.authors
        ? { ...state.authors }
        : {};
      if (!action.payload.author) {
        delete newDict[action.payload.authorId];
      } else {
        newDict[action.payload.authorId] = action.payload.author;
      }
      return {
        ...state,
        authors: newDict,
      };
    }
    default:
      return state;
  }
};
