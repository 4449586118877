import { gql } from "@apollo/client";

export const CREATE_PROMO_CODE_MUTATION = gql`
  mutation CreatePromoCodeMutation($input: CreatePromoCodeInput!) {
    createPromoCode(input: $input) {
      promoCode {
        id
        code
        promoAmount
        campaignId
        redemptionLimit
        totalRedeemed
        isActive
        createdAtUtc
        startedAtUtc
        endedAtUtc
      }
    }
  }
`;
