import { gql } from "@apollo/client";

export const UPDATE_MESSAGE_MUTATION = gql`
  mutation UpdateMessageMutation($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      message {
        messageId
        startDate
        endDate
        title
        body
        category
        isActive
      }
    }
  }
`;
