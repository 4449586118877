import React, { useState, useEffect } from "react";
import * as style from "./UpdatePropOption.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation } from "../../gql";
import { Icon, PlayerInfo } from "../../components";
import { connect, useDispatch } from "react-redux";
import { PropActions } from "../../store/actions";
import * as Models from "../../models";
import { toast } from "react-toastify";
import { HexAlphaColorPicker, HexColorInput } from "react-colorful";
import { RootState } from "../../store/reducers";

interface StateProps {
  players: Record<string, Models.Bet.Player> | null;
  teams: Record<string, Models.Bet.Team> | null;
  leagues: Record<string, Models.Bet.League> | null;
  hasNextTeams?: boolean;
  teamsNextCursor?: string;
  hasNextPlayers?: boolean;
  playersNextCursor?: string;
}

interface OwnProps {
  propOption: Models.Props.PropOption;
  onClose: () => void;
  getTeams: (after?: string) => void;
  getPlayers: (after?: string) => void;
}

type ComponentProps = OwnProps & StateProps;

export const UpdatePropOptionInternal: React.FC<ComponentProps> = ({
  propOption,
  onClose,
  players,
  teams,
  leagues,
  hasNextTeams,
  hasNextPlayers,
  teamsNextCursor,
  playersNextCursor,
  getTeams,
  getPlayers,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const teamsArray = React.useMemo(() => {
    return Object.values(teams ?? {}).sort((a, b) =>
      a.displayName > b.displayName ? 1 : -1,
    );
  }, [teams]);
  const playersArray = React.useMemo(() => {
    return Object.values(players ?? {}).sort((a, b) =>
      a.name > b.name ? 1 : -1,
    );
  }, [players]);
  const [teamId, setTeamId] = React.useState<number | undefined>(1);

  /* Values */
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState(
    propOption?.defaultDecoration?.backgroundColor ?? "#000000",
  );
  const [fontColor, setFontColor] = useState(
    propOption?.defaultDecoration?.fontColor ?? "#FFFFFF",
  );

  // player info values and functions
  const [playerInfo, setPlayerInfo] = useState<
    Record<string, { playerId: number; teamId: number }>
  >(getDictFromPlayerInfoArray(propOption));

  const onPlayerInfoChange = (
    key: string,
    playerId: number,
    teamId: number,
  ) => {
    const newDict = { ...playerInfo };
    newDict[key] = {
      playerId,
      teamId:
        playerId !== playerInfo[key].playerId && !!players
          ? players[playerId]?.teamId ?? teamId
          : teamId,
    };
    setPlayerInfo(newDict);
  };

  const onAddPlayer = () => {
    const newDict = { ...playerInfo };
    newDict[Object.values(playerInfo).length.toString()] = {
      playerId: 0,
      teamId: 0,
    };
    setPlayerInfo(newDict);
  };

  const onRemovePlayer = (key: string) => {
    const newDict = { ...playerInfo };
    delete newDict[key];
    setPlayerInfo(newDict);
  };

  /** Image  */
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [optionImageB64, setOptionImageB64] = useState<string>("");
  const [propImageError, setPropImageError] = useState<string>("");
  useEffect(() => {
    if (selectedFile != null) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(selectedFile);
      fileReader.onload = () => {
        setOptionImageB64(fileReader!.result!.toString());
      };
    }
  }, [selectedFile]);

  const [updateDecoration, { data, loading, error }] = useMutation(
    Mutation.PROP_DECORATION_UPDATE_MUTATION,
  );
  const onSubmit = () => {
    const propDecorationPlayerInfo = Object.values(playerInfo).filter(
      (p) => p.playerId && p.teamId,
    );
    if (
      !propDecorationPlayerInfo.length &&
      !propOption.defaultDecoration?.propDecorationPlayerInfo.length
    ) {
      toast.error("Please select player info for this prop");
    } else {
      setSubmitting(true);
      updateDecoration({
        variables: {
          input: {
            updateDecorationRequest: {
              propDecorationId: propOption.defaultDecorationId,
              fontColor,
              backgroundColor,
              optionImageB64,
              propDecorationPlayerInfo: propDecorationPlayerInfo.length
                ? propDecorationPlayerInfo
                : propOption.defaultDecoration?.propDecorationPlayerInfo,
            },
          },
        },
      })
        .then((res) => {
          setSubmitting(false);
          if (res.data.propDecorationUpdate.propDecoration) {
            toast.success("successfully updated prop option");
            dispatch(
              PropActions.UpdatePropDecorationSuccess({
                propOption,
                newDecoration: res.data.propDecorationUpdate.propDecoration,
              }),
            );
          }
          onClose();
        })
        .catch((e) => {
          setSubmitting(false);
          console.error(e);
        });
    }
    setSubmitting(true);
    updateDecoration({
      variables: {
        input: {
          updateDecorationRequest: {
            propDecorationId: propOption.defaultDecorationId,
            fontColor,
            backgroundColor,
            optionImageB64,
          },
        },
      },
    })
      .then((res) => {
        setSubmitting(false);
        dispatch(
          PropActions.UpdatePropDecorationSuccess({
            propOption,
            newDecoration: res.data.propDecorationUpdate.propDecoration,
          }),
        );
        onClose();
      })
      .catch((e) => {
        setSubmitting(false);
        console.error(e);
      });
  };

  return (
    <div className={style.component}>
      <div style={{ flex: 1 }}>
        <div className={style.exampleWrapper}>
          <div
            className={style.example}
            style={{ backgroundColor, color: fontColor }}
          >
            {propOption.propText}
          </div>
        </div>
        <div className={style.row}>
          <HexAlphaColorPicker
            className={style.colorBox}
            color={backgroundColor}
            onChange={setBackgroundColor}
            prefix="#"
          />
          <HexColorInput
            color={backgroundColor}
            onChange={setBackgroundColor}
            alpha
          />
          <div className={style.label}>Background Color</div>
        </div>
        <div className={style.row}>
          <HexAlphaColorPicker
            className={style.colorBox}
            color={fontColor}
            onChange={setFontColor}
            prefix="#"
          />
          <HexColorInput color={fontColor} onChange={setFontColor} alpha />
          <div className={style.label}>Font Color</div>
        </div>
        <div className={style.row}>
          <div className={style.imageContainer}>
            <img
              src={propOption?.defaultDecoration?.imageUrl ?? ""}
              alt="prop image"
              className={style.image}
            />
          </div>
          <div className={style.label}>Old Image</div>
        </div>
        {!optionImageB64 && (
          <div className={style.input}>
            <input
              type="file"
              name="image"
              onChange={(e) => setSelectedFile(e!.currentTarget!.files![0])}
              accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
            />
            {propImageError && (
              <div className={style.error}>{propImageError}</div>
            )}
          </div>
        )}
        {!!optionImageB64 && (
          <>
            <div className={style.imageContainer}>
              <img
                src={optionImageB64}
                alt="prop image"
                className={style.image}
              />
            </div>
            <div
              onClick={() => {
                setSelectedFile(null);
                setOptionImageB64("");
              }}
              className={style.removeImageButton}
            >
              Remove image
            </div>
            {!!optionImageB64 && (
              <div
                className={style.removeImageButton}
                onClick={() => {
                  navigator.clipboard.writeText(optionImageB64);
                  toast.success("b64 copied to clipboard");
                }}
              >
                Copy Image B64
              </div>
            )}
          </>
        )}
        <div onClick={onSubmit} className={style.submit}>
          {submitting ? <Icon.Spinner size={15} /> : "Update Decoration"}
        </div>
      </div>{" "}
      <div className={style.playerInfo}>
        {hasNextTeams && (
          <div
            className={style.fetchButton}
            onClick={() => getTeams(teamsNextCursor)}
          >
            Fetch More Teams
          </div>
        )}
        {hasNextPlayers && (
          <div
            className={style.fetchButton}
            onClick={() => getPlayers(playersNextCursor)}
          >
            Fetch More Players
          </div>
        )}
        <div className={style.addPlayerButton} onClick={onAddPlayer}>
          Add Player
        </div>
        {Object.keys(playerInfo).map((playerInfoKey) => (
          <PlayerInfo
            key={playerInfoKey}
            onChange={(playerId, teamId) =>
              onPlayerInfoChange(playerInfoKey, playerId, teamId)
            }
            onRemove={() => onRemovePlayer(playerInfoKey)}
            playerId={playerInfo[playerInfoKey].playerId}
            teamId={playerInfo[playerInfoKey].teamId}
            teamsArray={teamsArray}
            playersArray={playersArray}
            leagues={leagues ?? {}}
          />
        ))}
      </div>
    </div>
  );
};

const getDictFromPlayerInfoArray = (propOption: Models.Props.PropOption) => {
  if (propOption?.defaultDecoration?.propDecorationPlayerInfo?.length) {
    const dict: Record<string, { playerId: number; teamId: number }> = {};
    propOption.defaultDecoration.propDecorationPlayerInfo.forEach(
      (playerInfo, i) => {
        dict[i] = playerInfo;
      },
    );
    return dict;
  } else {
    return {} as Record<string, { playerId: number; teamId: number }>;
  }
};

export const UpdatePropOption = connect(
  (state: RootState) => ({
    propOptions: state.props.propOptions,
    players: state.players.players.items,
    teams: state.players.teams.items,
    leagues: state.players.leagues.items,
    hasNextTeams: state.players.teams.hasNext,
    hasNextPlayers: state.players.players.hasNext,
    teamsNextCursor: state.players.teams.nextCursor,
    playersNextCursor: state.players.players.nextCursor,
  }),
  {},
)(UpdatePropOptionInternal);
