import { combineReducers } from "redux";
import { props } from "./props";
import { transactions } from "./transactions";
import { users } from "./users";
import { messages } from "./messages";
import { players } from "./players";
import { promos } from "./promos";
import { entries } from "./entries";

export const rootReducer = combineReducers({
  props,
  transactions,
  users,
  messages,
  players,
  promos,
  entries,
});

export type RootState = ReturnType<typeof rootReducer>;
