import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * PROMOS
   */
  GET_PROMO_CODES_SUCCESS = "PROMOS/GET_PROMO_CODES_SUCCESS",
  UPDATE_PROMO_CODE_SUCCESS = "PROMOS/UPDATE_PROMO_CODE_SUCCESS",
  /**
   * CAMPAIGNS
   */
  GET_CAMPAIGNS_SUCCESS = "PROMOS/GET_CAMPAIGNS_SUCCESS",
  UPDATE_CAMPAIGN_SUCCESS = "PROMOS/UPDATE_CAMPAIGN_SUCCESS",
  /**
   * CONTESTS
   */
  GET_CONTESTS_SUCCESS = "CONTESTS/GET_CONTESTS_SUCCESS",
  UPDATE_CONTEST_SUCCESS = "CONTESTS/UPDATE_CONTEST_SUCCESS",
}

export const GetPromoCodesSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_PROMO_CODES_SUCCESS,
  {
    nodes: Models.Promos.PromoCode[];
    pageInfo: {
      hasNextPage: boolean;
      nextCursor?: string;
      hasPreviousPage: boolean;
      previousCursor?: string;
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_PROMO_CODES_SUCCESS);

export const UpdatePromoCodeSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_PROMO_CODE_SUCCESS,
  {
    promoCode: Models.Promos.PromoCode;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_PROMO_CODE_SUCCESS);

export const GetCampaignsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_CAMPAIGNS_SUCCESS,
  {
    nodes: Models.Promos.Campaign[];
    pageInfo: {
      hasNextPage: boolean;
      nextCursor?: string;
      hasPreviousPage: boolean;
      previousCursor?: string;
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_CAMPAIGNS_SUCCESS);

export const UpdateCampaignSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_CAMPAIGN_SUCCESS,
  {
    campaign: Models.Promos.Campaign;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_CAMPAIGN_SUCCESS);

export const GetContestsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_CONTESTS_SUCCESS,
  {
    nodes: Models.Bet.Contest[];
    pageInfo: {
      hasNextPage: boolean;
      nextCursor?: string;
      hasPreviousPage: boolean;
      previousCursor?: string;
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_CONTESTS_SUCCESS);

export const UpdateContestSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_CONTEST_SUCCESS,
  {
    contest: Models.Bet.Contest;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_CONTEST_SUCCESS);

export type Actions = ReturnType<
  | typeof GetPromoCodesSuccess
  | typeof UpdatePromoCodeSuccess
  | typeof GetCampaignsSuccess
  | typeof UpdateCampaignSuccess
  | typeof GetContestsSuccess
  | typeof UpdateContestSuccess
>;
