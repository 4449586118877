import { gql } from "@apollo/client";

export const GET_TEAMS_QUERY = gql`
  query teams(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: TeamFilterInput
    $order: [TeamSortInput!]
  ) {
    teams(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      order: $order
    ) {
      nodes {
        id
        name
        displayName
        teamCode
        leagueId
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
