import { gql } from "@apollo/client";

export const GET_PROP_SPECIALS_QUERY = gql`
  query propSpecials($first: Int, $last: Int, $after: String, $before: String) {
    propSpecials(first: $first, last: $last, after: $after, before: $before) {
      nodes {
        propSpecialId
        name
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
