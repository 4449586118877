import * as React from "react";
import * as style from "./Message.style";
import { classes } from "typestyle";
import { CreateMessage } from "./CreateMessage";
import { ViewMessages } from "./ViewMessages";

export const Message: React.FC = () => {
  const [tab, setTab] = React.useState("create");
  const [bulkCredit, setBulkCredit] = React.useState(false);
  const [zeroing, setZeroing] = React.useState(false);
  return (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("create")}
          className={classes(
            style.pageTab,
            tab === "create" ? style.selectedPageTab : "",
          )}
        >
          Create Message
        </div>
        <div
          onClick={() => setTab("view")}
          className={classes(
            style.pageTab,
            tab === "view" ? style.selectedPageTab : "",
          )}
        >
          View Messages
        </div>
      </div>
      {tab === "create" && <CreateMessage />}
      {tab === "view" && <ViewMessages />}
    </div>
  );
};
