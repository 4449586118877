import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const prop = s({
  padding: 10,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  marginBottom: 20,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const propTitle = s({
  whiteSpace: "pre-wrap",
  flex: 2,
  textAlign: "center",
});

export const buttonContainer = s({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: 8,
});

export const searchButton = s({
  borderRadius: 2,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  cursor: "pointer",
  padding: 6,
  backgroundColor: Style.colors.primary,
  width: 80,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 12,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.subtle,
      transition: ".2s ease-in",
    },
  },
});

export const updateButton = s({
  borderRadius: 2,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  cursor: "pointer",
  padding: 10,
  backgroundColor: Style.colors.primary,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.subtle,
      transition: ".2s ease-in",
    },
  },
});

export const disabled = s({
  backgroundColor: Style.colors.darkTranslucent200,
  cursor: "not-allowed",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent200,
    },
  },
});

export const externalId = s({
  flex: 1,
});

export const dropDown = s({
  flex: 1,
  textAlign: "right",
});

export const usersGrid = s({
  marginBottom: 20,
});

export const gridRow = s({
  display: "flex",
  border: "1px solid gray",
  transition: ".2s ease-in",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: "rgba(0, 0, 0, .05)",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, .2)",
      cursor: "pointer",
    },
  },
});

export const smallItem = s({
  flex: "0 0 80px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: 12,
});

export const gridItem = s({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: 12,
});

export const largeItem = s({
  flex: 2,
});

export const filterContainer = s({
  height: 16,
  width: 80,
  fontSize: 12,
});
