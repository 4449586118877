import { gql } from "@apollo/client";

export const UPDATE_PLAYER_PROFILE = gql`
  mutation updatePlayerProfile($input: UpdatePlayerProfileInput!) {
    updatePlayerProfile(input: $input) {
      playerProfile {
        kycStatus
        accountStatus
        selfExcludedStatus
        depositEnabled
        promoEnabled
        wageringEnabled
        accountLocked
        adminUpdatedBy
        updatedOnUtc
        featuresBeta
        featuresDebug
      }
    }
  }
`;
