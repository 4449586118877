import React from "react";
import * as style from "./User.style";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../../gql";
import { Icon, Modal } from "../../../components";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { colors } from "../../../styles";
import { useDispatch } from "react-redux";
import { UserActions } from "../../../store/actions";
import { FirebaseUserEntity } from "../../../models/user";

interface ComponentProps {
  onClose: (deletedUser?: number) => void;
  user: FirebaseUserEntity;
}

export const DeleteUser: React.FC<ComponentProps> = ({ onClose, user }) => {
  const [clarifying, setClarifying] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [refId, setRefId] = React.useState(uuidv4());

  const [deleteUser, { data, loading, error }] = useMutation(
    Mutation.DELETE_USER_ACCOUNT_MUTATION,
  );
  const onSubmit = () => {
    setSubmitting(true);
    deleteUser({
      variables: {
        input: {
          deleteRequest: {
            removeFirebaseRecord: true,
            userId: user.id,
          },
        },
      },
    })
      .then((res) => {
        if (res.data.deleteUserAccount.boolean) {
          toast.success("successfully deleted this user");
        } else {
          toast.error(
            !!res.data.deleteUserAccount.errors
              ? res.data.deleteUserAccount.errors[0].message
              : "Unable to delete this user error",
          );
        }
        setSubmitting(false);
        onClose(!!res.data.deleteUserAccount.boolean ? user.id : undefined);
      })
      .catch((e) => {
        toast.error(
          "There was an error deleting the user, please close the window and try again",
        );
        setSubmitting(false);
        console.error(e);
      });
  };

  return (
    <Modal isOpen={true} onRequestClose={onClose}>
      <div className={style.component}>
        <div className={style.deleteTitle}>
          This will delete user {user.email} account in it's entirety. This
          action is not reversible.
        </div>
        <div className={style.deleteWarning}>
          This is a destructive operation and should be used for testing
          purposes only! Do not ever perform this action on a real user's
          account
        </div>
        <div
          onClick={!clarifying ? () => setClarifying(true) : onSubmit}
          className={style.submit}
        >
          {submitting ? (
            <Icon.Spinner
              size={20}
              fill={colors.black1}
              stroke={colors.black1}
            />
          ) : clarifying ? (
            "Are you sure?"
          ) : (
            "Submit"
          )}
        </div>
      </div>
    </Modal>
  );
};
