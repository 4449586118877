import { gql } from "@apollo/client";

export const CREATE_TEAM_MUTATION = gql`
  mutation CreateTeamMutation($input: CreateTeamInput!) {
    createTeam(input: $input) {
      team {
        id
        leagueId
        name
        displayName
        teamCode
      }
      errors {
        ... on TeamExistsError {
          message
        }
      }
    }
  }
`;
