import { gql } from "@apollo/client";

export const WALLET_USER_TRANSACTIONS_QUERY = gql`
  query WalletUserTransactionsQuery(
    $userId: Int!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: UnitsTransactionFilterInput
    $order: [UnitsTransactionSortInput!]
  ) {
    walletUserTransactions(
      userId: $userId
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      order: $order
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        refId
        openingBalance
        transactionAmount
        transactionDateUtc
        currentBalance
        isLatestVersion
        reason
        unitsCurrencyId
        transactionType {
          description
        }
        sequence
      }
    }
  }
`;
