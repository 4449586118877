import { style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const row = s({
  display: "flex",
  marginBottom: 8,
  paddingBottom: 8,
  borderBottom: "1px solid black",
});

export const label = s({
  fontWeight: 600,
  marginRight: 16,
});

export const editButton = s({
  padding: 8,
  background: Style.colors.subtle,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 500,
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.primary,
    },
  },
  marginBottom: 12,
  width: "fit-content",
  marginRight: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 120,
});

export const input = s({
  height: 50,
  marginLeft: 12,
});
export const error = s({
  fontSize: 12,
  color: Style.colors.error,
  paddingTop: 4,
});

export const deleteTitle = s({
  fontSize: 36,
  lineHeight: "40px",
  marginBottom: 20,
});

export const deleteWarning = s({
  fontSize: 42,
  lineHeight: "48px",
  marginBottom: 20,
  fontWeight: 700,
  textTransform: "uppercase",
});

export const noPlayerProfile = s({
  fontSize: 42,
  lineHeight: "48px",
  marginBottom: 20,
  fontWeight: 700,
  textTransform: "uppercase",
  textAlign: "center",
  padding: 30,
});

export const submit = s({
  padding: 15,
  background: Style.colors.primary,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 600,
  width: 300,
  margin: "auto",
  $nest: {
    "&:hover": {
      background: Style.colors.softYellow,
    },
  },
});
