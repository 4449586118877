import React, { useState, useEffect, FC, useMemo, Dispatch } from "react";
import * as style from "./UpdateProp.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { PropActions } from "../../store/actions";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { classes } from "typestyle";
import { RootState } from "../../store/reducers";
import { useFirebaseContext } from "../../auth";
import { Icon, Modal } from "../../components";
import { CreateProp } from "../CreateProp";
import { ResultProp } from "../ResultProp";
import { toast } from "react-toastify";
import { date } from "../../utility";
import { decode } from "html-entities";

interface StateProps {
  props: Record<string, Models.Props.Prop> | null;
  hasNext: boolean;
  nextCursor?: string;
  getTeams: (after?: string) => void;
  getPlayers: (after?: string) => void;
}

export const UpdatePropInternal: FC<StateProps> = ({
  props,
  hasNext,
  nextCursor,
  getTeams,
  getPlayers,
}) => {
  /* Apollo */
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedProp, setSelectedProp] =
    React.useState<Models.Props.Prop | null>(null);
  const [duplicatingProp, setDuplicatingProp] =
    React.useState<Models.Props.Prop | null>(null);
  const [resultingProp, setResultingProp] =
    React.useState<Models.Props.Prop | null>(null);
  const [loading, setLoading] = React.useState(false);
  const { userJwt } = useFirebaseContext();
  const [propLineFilter, setPropLineFilter] = React.useState("");

  const getProps = (includeAllProps?: boolean) => {
    if (userJwt) {
      setLoading(true);
      const nltDate = new Date();
      nltDate.setDate(nltDate.getDate() - 30);
      client
        .query({
          query: Query.GET_PROPS_QUERY,
          variables: {
            first: 10,
            after: !!nextCursor ? nextCursor : undefined,
            where: {
              propStatus: {
                propStatusName: {
                  eq: "Pending",
                },
              },
              // ...(!!includeAllProps
              //   ? {}
              //   : {
              //       publishedOnUtc: {
              //         nlt: nltDate.toISOString(),
              //       },
              //     }),
            },
          },
        })
        .then((res) => {
          setLoading(false);
          dispatch(
            PropActions.GetPropsSuccess({
              props: res.data.props,
              hasNext: res.data.props.pageInfo.hasNextPage,
              nextCursor: res.data.props.pageInfo.endCursor,
              isResulted: false,
            }),
          );
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getProps();
  }, [userJwt]);

  useEffect(() => {
    if (hasNext && nextCursor) {
      getProps();
    }
  }, [nextCursor, hasNext]);

  const propsArray = React.useMemo(() => {
    return !props
      ? null
      : // : Object.values(props).sort((a, b) =>
        //     new Date(a.closeTimeUtc) > new Date(b.closeTimeUtc) ? 1 : -1,
        //   );
        Object.values(props)
          .filter(
            (prop) =>
              !prop.result &&
              (!propLineFilter ||
                prop.propLine
                  .toLowerCase()
                  .includes(propLineFilter.toLowerCase())),
          )
          .sort((a, b) => (a.propLine > b.propLine ? 1 : -1));
  }, [props, propLineFilter]);

  return (
    <>
      <div className={style.component}>
        <div className={style.filterRow}>
          <div style={{ fontWeight: 600 }}>Prop Line Filter</div>
          <input
            value={propLineFilter}
            onChange={(e) => setPropLineFilter(e.target.value)}
            placeholder="prop line"
          />
        </div>
        {!!propsArray ? (
          propsArray.map((prop) => (
            <Prop
              setSelectedProp={setSelectedProp}
              setResultingProp={setResultingProp}
              setDuplicatingProp={setDuplicatingProp}
              prop={prop}
              key={prop.propExternalId}
            />
          ))
        ) : (
          <div>
            Loading Props <Icon.Spinner size={30} />
          </div>
        )}
        {props && (
          <div className={style.buttonContainer}>
            <div
              className={classes(
                style.updateButton,
                !hasNext ? style.disabled : "",
              )}
              onClick={!hasNext ? undefined : () => getProps()}
            >
              {loading ? <Icon.Spinner size={15} /> : "Get More Props"}
            </div>
            {/* <div
              className={classes(style.updateButton)}
              onClick={() => getProps(true)}
            >
              {loading ? <Icon.Spinner size={15} /> : "Get Older Props"}
            </div> */}
          </div>
        )}
      </div>
      <Modal
        isOpen={!!selectedProp}
        onRequestClose={() => setSelectedProp(null)}
      >
        <CreateProp
          prop={selectedProp}
          getTeams={getTeams}
          getPlayers={getPlayers}
          onClose={() => {
            setLoading(true);
            client
              .query({
                query: Query.GET_PROPS_QUERY,
                variables: {
                  where: {
                    propExternalId: {
                      eq: selectedProp ? selectedProp.propExternalId : "",
                    },
                  },
                },
              })
              .then((res) => {
                setLoading(false);
                dispatch(
                  PropActions.GetPropsSuccess({
                    props: res.data.props,
                    hasNext: res.data.props.pageInfo.hasNextPage,
                    nextCursor: res.data.props.pageInfo.endCursor,
                  }),
                );
              })
              .catch(() => {
                setLoading(false);
              });
            setSelectedProp(null);
          }}
        />
      </Modal>
      <Modal
        isOpen={!!duplicatingProp}
        onRequestClose={() => setDuplicatingProp(null)}
      >
        <CreateProp
          getTeams={getTeams}
          getPlayers={getPlayers}
          prop={duplicatingProp}
          isDuplicating={true}
          onClose={() => {
            setLoading(true);
            client
              .query({
                query: Query.GET_PROPS_QUERY,
                variables: {
                  where: {
                    propExternalId: {
                      eq: duplicatingProp ? duplicatingProp.propExternalId : "",
                    },
                  },
                },
              })
              .then((res) => {
                setLoading(false);
                dispatch(
                  PropActions.GetPropsSuccess({
                    props: res.data.props,
                    hasNext: res.data.props.pageInfo.hasNextPage,
                    nextCursor: res.data.props.pageInfo.endCursor,
                  }),
                );
              })
              .catch(() => {
                setLoading(false);
              });
            setDuplicatingProp(null);
          }}
        />
      </Modal>
      <Modal
        isOpen={!!resultingProp}
        onRequestClose={() => setResultingProp(null)}
      >
        {!!resultingProp && (
          <ResultProp
            prop={resultingProp}
            onClose={() => setResultingProp(null)}
          />
        )}
      </Modal>
    </>
  );
};

const statusDict: Record<string, Models.Props.PublishingStatusType> = {
  Published: Models.Props.PublishingStatusType.Published,
  Draft: Models.Props.PublishingStatusType.Draft,
  Removed: Models.Props.PublishingStatusType.Removed,
};

const Prop: FC<{
  prop: Models.Props.Prop;
  setSelectedProp: (prop: Models.Props.Prop) => void;
  setResultingProp: (prop: Models.Props.Prop) => void;
  setDuplicatingProp: (prop: Models.Props.Prop) => void;
}> = ({ prop, setSelectedProp, setResultingProp, setDuplicatingProp }) => {
  const [publishingStatus, setPublishingStatus] = useState<string>(
    statusDict[prop.publishingStatus.publishingStatusName],
  );
  const disabled = useMemo(
    () =>
      statusDict[prop.publishingStatus.publishingStatusName] ===
      publishingStatus,
    [publishingStatus, prop],
  );

  const [updateProp, { data, loading, error }] = useMutation(
    Mutation.PROP_UPDATE_PUBLISHING_STATUS,
  );

  const submit = () => {
    updateProp({
      variables: {
        input: {
          propExternalId: prop.propExternalId,
          newStatus: publishingStatus,
        },
      },
    }).then((res) => {
      if (!res.data.propUpdatePublishingStatus.errors) {
        toast.success("Successfully updated prop");
        PropActions.UpdatePropSuccess({
          prop: res.data.propUpdatePublishingStatus.prop,
        });
      } else {
        toast.error("Failed to update prop");
      }
    });
  };

  const isResultingDisabled =
    prop.publishingStatus.publishingStatusName === "Draft" ||
    prop.propStatus.propStatusName !== "Pending";

  return (
    <div className={style.prop}>
      <div className={style.propTitle}>{decode(prop.propLine)}</div>
      <div className={style.item}>
        {prop.propSpecials.length ? prop.propSpecials[0].name : ""}
      </div>
      <div className={style.item}>
        <div style={{ fontWeight: 700 }}>Publish Time</div>
        <div>{date.toShortDateAndTimeUTC(new Date(prop.startTimeUtc))}</div>
      </div>
      <div className={style.item}>
        <div style={{ fontWeight: 700 }}>Close Time</div>
        <div>
          <div>{prop.recordUpdatedBy}</div>
          <div>{date.toShortDateAndTimeUTC(new Date(prop.closeTimeUtc))}</div>
        </div>
      </div>
      <div
        className={classes(
          style.dropDown,
          publishingStatus === "DRAFT"
            ? style.dropDownDraft
            : publishingStatus === "PUBLISHED"
            ? style.dropDownPublished
            : publishingStatus === "REMOVED"
            ? style.dropDownRemoved
            : "",
        )}
      >
        <Select
          value={publishingStatus}
          onChange={(e) =>
            setPublishingStatus(
              (e.target.value as Models.Props.PublishingStatusType) ||
                publishingStatus,
            )
          }
          label="Publishing Status"
        >
          <MenuItem value="DRAFT">Draft</MenuItem>
          <MenuItem value="PUBLISHED">Published</MenuItem>
          <MenuItem value="REMOVED">Removed</MenuItem>
        </Select>
      </div>
      <div className={style.buttons}>
        <div className={style.buttonContainer}>
          <div
            className={classes(
              style.updateButton,
              !!disabled ? style.disabled : "",
            )}
            onClick={disabled ? undefined : submit}
          >
            {loading ? <Icon.Spinner size={15} /> : "Update Status"}
          </div>
        </div>
        <div className={style.buttonContainer}>
          <div
            className={classes(style.updateButton)}
            onClick={() => setSelectedProp(prop)}
          >
            Details
          </div>
        </div>
        <div className={style.buttonContainer}>
          <div
            className={classes(
              style.resultButton,
              isResultingDisabled ? style.disabled : "",
            )}
            onClick={
              isResultingDisabled ? undefined : () => setResultingProp(prop)
            }
          >
            Result
          </div>
        </div>
        <div className={style.buttonContainer}>
          <div
            className={classes(style.duplicateButton)}
            onClick={() => setDuplicatingProp(prop)}
          >
            Duplicate
          </div>
        </div>
      </div>
    </div>
  );
};

export const UpdateProp = connect(
  (state: RootState) => ({
    props: state.props.props.pending.items,
    hasNext: state.props.props.pending.hasNext,
    nextCursor: state.props.props.pending.nextCursor,
  }),
  {},
)(UpdatePropInternal);
