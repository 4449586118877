import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const entry = s({
  padding: 10,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  borderRadius: 4,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Style.colors.darkTranslucent100,
      borderTopWidth: 0,
      borderBottomWidth: 0,
    },
  },
});

export const item = s({
  whiteSpace: "pre-wrap",
  textAlign: "center",
  flex: "0 0 150px",
});

export const smallItem = s({
  whiteSpace: "pre-wrap",
  textAlign: "center",
  flex: "0 0 100px",
});

export const legs = s({
  padding: "4px 12px",
  flex: "0 0 250px",
});

export const leg = s({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 8,
  fontSize: 16,
  marginBottom: 4,
  paddingBottom: 4,
  borderBottom: "1px solid gray",
  $nest: {
    "&:last-child": {
      borderBottom: "none",
      marginBottom: 0,
    },
  },
});

export const legSelectedOption = s({
  fontWeight: 700,
  backgroundColor: Style.colors.subtle,
  padding: 2,
  borderRadius: 4,
});

export const buttonContainer = s({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: 4,
});

export const updateButton = s({
  borderRadius: 2,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  cursor: "pointer",
  padding: 6,
  backgroundColor: Style.colors.primary,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.subtle,
      transition: ".2s ease-in",
    },
  },
});

export const disabled = s({
  backgroundColor: Style.colors.darkTranslucent200,
  cursor: "not-allowed",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent200,
    },
  },
});

export const tabs = s({
  textAlign: "center",
  display: "flex",
  color: Style.text.colors.main,
  marginBottom: 20,
});

export const pageTab = s({
  flex: 1,
  padding: 20,
  border: `2px solid black`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 24,
  fontWeight: 600,
  cursor: "pointer",
});

export const selectedPageTab = s({
  background: Style.colors.primary,
  color: Style.text.colors.main,
});

export const table = s({
  width: "80%",
  margin: "auto",
});
