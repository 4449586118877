import React, { useState, useEffect } from "react";
import * as style from "./Contests.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { DateTimePicker, Icon, TextInput } from "../../components";
import { connect, useDispatch } from "react-redux";
import { MessageActions, PromoActions } from "../../store/actions";
import { useFirebaseContext } from "../../auth";
import MDEditor from "@uiw/react-md-editor";
import * as Models from "../../models";
import { MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { colors } from "../../styles";
import { decode } from "html-entities";
import { RootState } from "../../store/reducers";
import { bet } from "../../utility";

interface OwnProps {
  contest?: Models.Bet.Contest | null;
  onClose?: () => void;
  isDuplicating?: boolean;
}

interface StateProps {
  tags: Record<string, Models.Props.PropTag> | null;
}

type ComponentProps = OwnProps & StateProps;
export const CreateContestInternal: React.FC<ComponentProps> = ({
  contest,
  onClose,
  tags,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();

  /* Values */
  const [name, setName] = useState<string>(contest ? contest.name : "");
  const [description, setDescription] = useState<string>(
    contest ? contest.description : "",
  );
  const [contestStartsAt, setContestStartsAt] = useState<Date | null>(
    contest ? new Date(contest.contestStartsAtUtc) : null,
  );
  const [contestEndsAt, setContestEndsAt] = useState<Date | null>(
    contest ? new Date(contest.contestEndsAtUtc) : null,
  );
  const [viewableStartsAt, setViewableStartsAt] = useState<Date | null>(
    contest ? new Date(contest.viewableStartsAtUtc) : null,
  );
  const [viewableEndsAt, setViewableEndsAt] = useState<Date | null>(
    contest ? new Date(contest.viewableEndsAtUtc) : null,
  );
  const [isActive, setIsActive] = React.useState<boolean>(
    contest ? contest.isActive : false,
  );
  const [contestPropTags, setContestPropTags] = useState<number[]>(
    contest ? contest.tags.map((tag) => tag.propTagId) : [],
  );
  const [showAllTags, setShowAllTags] = useState(false);
  const [reward1, setReward1] = React.useState<string | undefined>(
    contest ? contest.reward1?.toString() : undefined,
  );
  const [reward2, setReward2] = React.useState<string | undefined>(
    contest ? contest.reward2?.toString() : undefined,
  );
  const [reward3, setReward3] = React.useState<string | undefined>(
    contest ? contest.reward3?.toString() : undefined,
  );
  const [reward4, setReward4] = React.useState<string | undefined>(
    contest ? contest.reward4?.toString() : undefined,
  );
  const [reward5, setReward5] = React.useState<string | undefined>(
    contest ? contest.reward5?.toString() : undefined,
  );

  const tagsArray = React.useMemo(() => {
    if (!tags) {
      return [];
    } else if (showAllTags || !!contest) {
      return Object.values(tags).sort((a, b) =>
        a.propTagId > b.propTagId ? -1 : 1,
      );
    } else {
      const tagsList = Object.values(tags);
      const leagueTags: Models.Props.PropTag[] = [];
      const recentTags: Models.Props.PropTag[] = [];
      tagsList.forEach((tag) => {
        if (bet.isLeague(tag.name)) {
          leagueTags.push(tag);
        } else if (recentTags.length < 30) {
          recentTags.push(tag);
        }
      });
      return [...leagueTags, ...recentTags];
    }
  }, [tags, showAllTags]);

  /* Errors */
  const [contestStartTimeError, setContestStartTimeError] =
    useState<string>("");
  const [contestEndTimeError, setContestEndTimeError] = useState<string>("");
  const [viewableStartTimeError, setViewableStartTimeError] =
    useState<string>("");
  const [viewableEndTimeError, setViewableEndTimeError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [descriptionError, setDescriptionError] = useState<string>("");
  const [tagsError, setTagsError] = useState<string>("");
  const [reward1Error, setReward1Error] = useState<string>("");
  const [reward2Error, setReward2Error] = useState<string>("");
  const [reward3Error, setReward3Error] = useState<string>("");
  const [reward4Error, setReward4Error] = useState<string>("");
  const [reward5Error, setReward5Error] = useState<string>("");

  /** Submission */
  const [submitting, setSubmitting] = useState(false);
  const [createContest, createContestStatus] = useMutation(
    Mutation.CREATE_CONTEST_MUTATION,
  );
  const [updateContest, updateContestStatus] = useMutation(
    Mutation.UPDATE_CONTEST_MUTATION,
  );

  const onSubmit = () => {
    if (!contestStartsAt) {
      setContestStartTimeError("Select a valid contest start date");
    } else if (new Date(contestStartsAt) < new Date()) {
      setContestStartTimeError("Contest start time must be after current time");
    } else {
      setContestStartTimeError("");
    }
    if (!contestEndsAt) {
      setContestEndTimeError("Select a valid contest end date");
    } else if (!!contestStartsAt && contestEndsAt < contestStartsAt) {
      setContestEndTimeError(
        "Contest end time must be after contest start time",
      );
    } else {
      setContestEndTimeError("");
    }
    if (!viewableStartsAt) {
      setViewableStartTimeError("Select a valid viewable start date");
    } else if (!!contestStartsAt && viewableStartsAt > contestStartsAt) {
      setContestEndTimeError(
        "Viewable start time must be before contest start time",
      );
    } else {
      setViewableStartTimeError("");
    }
    if (!viewableEndsAt) {
      setViewableEndTimeError("Select a valid viewable end date");
    } else if (!!contestEndsAt && viewableEndsAt < contestEndsAt) {
      setContestEndTimeError(
        "Viewable end time must be after contest end time",
      );
    } else {
      setViewableEndTimeError("");
    }
    if (!name) {
      setNameError("Input a valid name");
    } else {
      setNameError("");
    }
    if (!description) {
      setDescriptionError("Input a valid name");
    } else {
      setDescriptionError("");
    }
    if (!!reward1) {
      const reward1Value = Number.parseInt(reward1);
      if (isNaN(reward1Value) || reward1Value < 0) {
        setReward1Error("Please enter a valid reward value");
      } else {
        setReward1Error("");
      }
    }
    if (!!reward2) {
      const reward2Value = Number.parseInt(reward2);
      if (isNaN(reward2Value) || reward2Value < 0) {
        setReward2Error("Please enter a valid reward value");
      } else {
        setReward2Error("");
      }
    }
    if (!!reward3) {
      const reward3Value = Number.parseInt(reward3);
      if (isNaN(reward3Value) || reward3Value < 0) {
        setReward3Error("Please enter a valid reward value");
      } else {
        setReward3Error("");
      }
    }
    if (!!reward4) {
      const reward4Value = Number.parseInt(reward4);
      if (isNaN(reward4Value) || reward4Value < 0) {
        setReward4Error("Please enter a valid reward value");
      } else {
        setReward4Error("");
      }
    }
    if (!!reward5) {
      const reward5Value = Number.parseInt(reward5);
      if (isNaN(reward5Value) || reward5Value < 0) {
        setReward5Error("Please enter a valid reward value");
      } else {
        setReward5Error("");
      }
    }
    setSubmitting(true);
  };

  React.useEffect(() => {
    if (submitting) {
      if (
        !contestStartTimeError &&
        !contestEndTimeError &&
        !viewableStartTimeError &&
        !viewableEndTimeError &&
        !nameError &&
        !descriptionError &&
        !tagsError &&
        !reward1Error &&
        !reward2Error &&
        !reward3Error &&
        !reward4Error &&
        !reward5Error
      ) {
        if (!!contest) {
          updateContest({
            variables: {
              input: {
                updateContestRequest: {
                  id: contest.id,
                  name,
                  description,
                  tags: contestPropTags.map((tag) => ({ propTagId: tag })),
                  isActive,
                  reward1: !!reward1 ? Number.parseInt(reward1) : 0,
                  reward2: !!reward2 ? Number.parseInt(reward2) : 0,
                  reward3: !!reward3 ? Number.parseInt(reward3) : 0,
                  reward4: !!reward4 ? Number.parseInt(reward4) : 0,
                  reward5: !!reward5 ? Number.parseInt(reward5) : 0,
                  contestStartsAtUtc: contestStartsAt,
                  contestEndsAtUtc: contestEndsAt,
                  viewableStartsAtUtc: viewableStartsAt,
                  viewableEndsAtUtc: viewableEndsAt,
                },
              },
            },
          })
            .then((res) => {
              dispatch(
                PromoActions.UpdateContestSuccess({
                  contest: res.data.updateContest.contest,
                }),
              );
              toast.success(`successfully updated contest`);
              setSubmitting(false);
              setContestStartsAt(null);
              setContestEndsAt(null);
              setViewableStartsAt(null);
              setViewableEndsAt(null);
              setName("");
              setDescription("");
              setContestPropTags([]);
              setReward1(undefined);
              setReward2(undefined);
              setReward3(undefined);
              setReward4(undefined);
              setReward5(undefined);
              setIsActive(false);
              if (onClose) {
                onClose();
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              if (onClose) {
                onClose();
              }
              toast.error(
                "something went wrong - please check your values and try again",
              );
            });
        } else {
          createContest({
            variables: {
              input: {
                createContestRequest: {
                  name,
                  description,
                  tags: contestPropTags.map((tag) => ({ propTagId: tag })),
                  isActive,
                  reward1: !!reward1 ? Number.parseInt(reward1) : 0,
                  reward2: !!reward2 ? Number.parseInt(reward2) : 0,
                  reward3: !!reward3 ? Number.parseInt(reward3) : 0,
                  reward4: !!reward4 ? Number.parseInt(reward4) : 0,
                  reward5: !!reward5 ? Number.parseInt(reward5) : 0,
                  contestStartsAtUtc: contestStartsAt,
                  contestEndsAtUtc: contestEndsAt,
                  viewableStartsAtUtc: viewableStartsAt,
                  viewableEndsAtUtc: viewableEndsAt,
                },
              },
            },
          })
            .then((res) => {
              if (res.data?.createContest?.contest) {
                dispatch(
                  PromoActions.UpdateContestSuccess({
                    contest: res.data.createContest.contest,
                  }),
                );
                toast.success(
                  `successfully created contest ${res.data.createContest.contest.name}`,
                );
                setSubmitting(false);
                setContestStartsAt(null);
                setContestEndsAt(null);
                setViewableStartsAt(null);
                setViewableEndsAt(null);
                setName("");
                setDescription("");
                setContestPropTags([]);
                setReward1(undefined);
                setReward2(undefined);
                setReward3(undefined);
                setReward4(undefined);
                setReward5(undefined);
                setIsActive(false);
                if (onClose) {
                  onClose();
                }
              } else {
                toast.error(`We were unable to create this contest`);
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              toast.error(`We were unable to create this contest`);
              if (onClose) {
                onClose();
              }
            });
        }
      } else {
        setSubmitting(false);
      }
    }
  }, [submitting]);

  const getContests = (before?: string) => {
    client
      .query({
        query: Query.GET_CAMPAIGNS,
        variables: {
          last: 50,
          before,
        },
      })
      .then((res) => {
        dispatch(PromoActions.GetCampaignsSuccess(res.data.campaigns));
      });
  };

  React.useEffect(() => {
    getContests();
  }, []);

  return (
    <div className={style.component}>
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={viewableStartsAt}
        onChange={(date) => setViewableStartsAt(date)}
        label="Contest Viewable Start Date"
        error={viewableStartTimeError}
      />
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={contestStartsAt}
        onChange={(date) => setContestStartsAt(date)}
        label="Contest Start Date"
        error={contestStartTimeError}
      />
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={contestEndsAt}
        onChange={(date) => setContestEndsAt(date)}
        label="Contest End Date"
        error={contestEndTimeError}
      />
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={viewableEndsAt}
        onChange={(date) => setViewableEndsAt(date)}
        label="Contest Viewable End Date"
        error={viewableEndTimeError}
      />
      <TextInput
        className={style.textInput}
        value={name}
        onChange={setName}
        label="Name"
        error={nameError}
        disabled={!!contest}
      />
      <TextInput
        value={description}
        onChange={setDescription}
        label="Description"
        className={style.textInput}
        error={descriptionError}
        disabled={!!contest}
      />
      <div className={style.propOptionContainer}>
        <Select
          multiple={true}
          value={contestPropTags}
          onChange={(e) => setContestPropTags(e.target.value as number[])}
          autoWidth={true}
          label="Prop Tags"
        >
          {tagsArray.map((tag) => (
            <MenuItem value={tag.propTagId} key={tag.propTagId}>
              {decode(tag.name)}
            </MenuItem>
          ))}
        </Select>
        {
          <div style={{ paddingLeft: 10 }}>
            <div
              className={style.addPropOptionButton}
              onClick={() => setShowAllTags(true)}
            >
              Show All Tags
            </div>
          </div>
        }
      </div>
      <TextInput
        value={reward1}
        onChange={setReward1}
        label="Reward 1"
        className={style.textInput}
        inputType="number"
      />
      <TextInput
        value={reward2}
        onChange={setReward2}
        label="Reward 2"
        className={style.textInput}
        inputType="number"
      />
      <TextInput
        value={reward3}
        onChange={setReward3}
        label="Reward 3"
        className={style.textInput}
        inputType="number"
      />
      <TextInput
        value={reward4}
        onChange={setReward4}
        label="Reward 4"
        className={style.textInput}
        inputType="number"
      />
      <TextInput
        value={reward5}
        onChange={setReward5}
        label="Reward 5"
        className={style.textInput}
        inputType="number"
      />
      <div className={style.inputContainer}>
        <div
          className={style.checkbox}
          style={{ backgroundColor: isActive ? colors.success : colors.error }}
          onClick={() => {
            setIsActive(!isActive);
          }}
        />
        <div>{`Is Active`}</div>
      </div>
      <div onClick={onSubmit} className={style.submit}>
        {submitting ? <Icon.Spinner size={15} /> : "Submit"}
      </div>
    </div>
  );
};

export const CreateContest = connect((state: RootState) => ({
  tags: state.props.propTags.items ?? {},
}))(CreateContestInternal);
