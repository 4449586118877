import { gql } from "@apollo/client";

export const PROP_UPDATE_RESULT = gql`
  mutation PropUpdateResult($input: PropUpdateResultInput!) {
    propUpdateResult(input: $input) {
      prop {
        propExternalId
        recordUpdatedBy
        updatedOnUtc
        publishingStatus {
          publishingStatusName
        }
        propStatus {
          propStatusName
        }
      }
      errors {
        ... on UnableToSettleNonPublishedPropsError {
          message
        }
        ... on BetResettlementIsNotAllowedError {
          message
        }
        ... on InvalidPropOptionError {
          message
        }
      }
    }
  }
`;
