import React, { useState, useEffect } from "react";
import * as style from "./PromoCodes.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../gql";
import { DateTimePicker, Icon, TextInput } from "../../components";
import { connect, useDispatch } from "react-redux";
import { MessageActions, PromoActions } from "../../store/actions";
import { useFirebaseContext } from "../../auth";
import MDEditor from "@uiw/react-md-editor";
import * as Models from "../../models";
import { MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { colors } from "../../styles";
import { decode } from "html-entities";
import { RootState } from "../../store/reducers";

interface OwnProps {
  promoCode?: Models.Promos.PromoCode | null;
  onClose?: () => void;
  isDuplicating?: boolean;
}

interface StateProps {
  campaigns: Models.Promos.Campaign[];
}

type ComponentProps = OwnProps & StateProps;
export const CreatePromoCodeInternal: React.FC<ComponentProps> = ({
  promoCode,
  onClose,
  campaigns,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();

  /* Values */
  const [startedAtUtc, setStartedAtUtc] = useState<Date | null>(
    promoCode ? new Date(promoCode.startedAtUtc) : null,
  );
  const [endedAtUtc, setEndedAtUtc] = useState<Date | null>(
    promoCode ? new Date(promoCode.endedAtUtc) : null,
  );
  const [campaign, setCampaign] = useState<string | null>(
    promoCode ? promoCode.campaignId?.toString() ?? null : null,
  );

  const [code, setCode] = useState<string>(promoCode ? promoCode.code : "");
  const [redemptionLimit, setRedemptionLimit] = useState<string | undefined>(
    promoCode ? promoCode.redemptionLimit.toString() : "",
  );
  const [promoAmount, setPromoAmount] = useState<string | undefined>(
    promoCode ? promoCode.promoAmount.toString() : "",
  );

  const [isActive, setIsActive] = React.useState<boolean>(
    promoCode ? promoCode.isActive : false,
  );

  /* Errors */
  const [startTimeError, setStartTimeError] = useState<string>("");
  const [endTimeError, setEndTimeError] = useState<string>("");
  const [codeError, setCodeError] = useState<string>("");
  const [redemptionLimitError, setRedemptionLimitError] = useState<string>("");
  const [promoAmountError, setPromoAmountError] = useState<string>("");

  /** Submission */
  const [submitting, setSubmitting] = useState(false);
  const [createPromoCode, createPromoCodeStatus] = useMutation(
    Mutation.CREATE_PROMO_CODE_MUTATION,
  );
  const [updatePromoCode, updatePromoCodeStatus] = useMutation(
    Mutation.UPDATE_PROMO_CODE_MUTATION,
  );

  const onSubmit = () => {
    if (!startedAtUtc) {
      setStartTimeError("Select a valid start date");
    } else {
      setStartTimeError("");
    }
    if (!endedAtUtc) {
      setEndTimeError("Select a valid end date");
    } else {
      setEndTimeError("");
    }
    if (!code) {
      setCodeError("Input a valid title");
    } else if (code.length > 100) {
      setCodeError(
        "Title must be less than 100 characters. You currently have " +
          code.length,
      );
    } else {
      setCodeError("");
    }
    const parsedLimit = Number.parseInt(redemptionLimit || "");
    if (!parsedLimit || isNaN(parsedLimit)) {
      setRedemptionLimitError("Input a valid redemption limit");
    } else if (parsedLimit >= 1000) {
      setRedemptionLimitError(
        "Redemption limit must be less than 1000. You currently have " +
          parsedLimit,
      );
    } else {
      setRedemptionLimitError("");
    }
    const parsedAmount = Number.parseInt(promoAmount || "");
    if (!parsedAmount || isNaN(parsedAmount)) {
      setPromoAmountError("Input a valid promo amount");
    } else if (parsedAmount >= 1000) {
      setPromoAmountError(
        "Promo Amount must be less than 1000. You currently have " +
          parsedAmount,
      );
    } else {
      setPromoAmountError("");
    }
    setSubmitting(true);
  };

  React.useEffect(() => {
    if (submitting) {
      if (
        !startTimeError &&
        !startTimeError &&
        !codeError &&
        !redemptionLimitError
      ) {
        if (!!promoCode) {
          updatePromoCode({
            variables: {
              input: {
                updatePromoCodeRequest: {
                  promoCodeId: promoCode.id,
                  startedAtUtc,
                  endedAtUtc,
                  campaignId: !!campaign
                    ? Number.parseInt(campaign)
                    : undefined,
                  redemptionLimit: Number.parseInt(redemptionLimit || ""),
                  isActive,
                },
              },
            },
          })
            .then((res) => {
              dispatch(
                PromoActions.UpdatePromoCodeSuccess({
                  promoCode: res.data.updatePromoCode.promoCode,
                }),
              );
              toast.success(`successfully updated promo Code`);
              setSubmitting(false);
              setStartedAtUtc(null);
              setEndedAtUtc(null);
              setCode("");
              setPromoAmount("");
              setRedemptionLimit("");
              setIsActive(false);
              if (onClose) {
                onClose();
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              if (onClose) {
                onClose();
              }
            });
        } else {
          createPromoCode({
            variables: {
              input: {
                promoCode: {
                  startedAtUtc,
                  endedAtUtc,
                  code,
                  campaignId: !!campaign
                    ? Number.parseInt(campaign)
                    : undefined,
                  redemptionLimit: Number.parseInt(redemptionLimit || ""),
                  promoAmount: Number.parseInt(promoAmount || ""),
                  isActive,
                },
              },
            },
          })
            .then((res) => {
              if (res.data?.createPromoCode?.promoCode) {
                dispatch(
                  PromoActions.UpdatePromoCodeSuccess({
                    promoCode: res.data.createPromoCode.promoCode,
                  }),
                );
                toast.success(
                  `successfully created promo code ${res.data.createPromoCode.promoCode.code}`,
                );
                setSubmitting(false);
                setStartedAtUtc(null);
                setEndedAtUtc(null);
                setCode("");
                setPromoAmount("");
                setRedemptionLimit("");
                setIsActive(false);
                if (onClose) {
                  onClose();
                }
              } else {
                toast.error(`We were unable to create this promo code`);
              }
            })
            .catch((e) => {
              console.error(e);
              setSubmitting(false);
              toast.error(`We were unable to create this promo code`);
              if (onClose) {
                onClose();
              }
            });
        }
      } else {
        setSubmitting(false);
      }
    }
  }, [submitting]);

  const getCampaigns = (before?: string) => {
    client
      .query({
        query: Query.GET_CAMPAIGNS,
        variables: {
          last: 50,
          before,
        },
      })
      .then((res) => {
        dispatch(PromoActions.GetCampaignsSuccess(res.data.campaigns));
      });
  };

  React.useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <div className={style.component}>
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={startedAtUtc}
        onChange={(date) => setStartedAtUtc(date)}
        label="Start Date"
        error={startTimeError}
      />
      <DateTimePicker
        wrapperClassName={style.input}
        className={style.picker}
        value={endedAtUtc}
        onChange={(date) => setEndedAtUtc(date)}
        label="End Date"
        error={endTimeError}
      />
      <div className={style.propOptionContainer}>
        <Select
          value={campaign ?? ""}
          onChange={(e) =>
            setCampaign(e.target.value === campaign ? null : e.target.value)
          }
          autoWidth={true}
          label="Campaign"
        >
          {campaigns.map((campaign) => (
            <MenuItem value={campaign.id.toString()} key={campaign.id}>
              {campaign.name}
            </MenuItem>
          ))}
        </Select>
        <div style={{ paddingLeft: 20 }}>Select Campaign</div>
        {!!campaign && (
          <div style={{ paddingLeft: 10 }}>
            <div
              className={style.addPropOptionButton}
              onClick={() => setCampaign(null)}
            >
              Clear
            </div>
          </div>
        )}
      </div>
      <TextInput
        className={style.textInput}
        value={code}
        onChange={setCode}
        label="Code"
        error={codeError}
        disabled={!!promoCode}
      />
      <TextInput
        value={promoAmount}
        onChange={setPromoAmount}
        label="Promo Amount"
        className={style.textInput}
        error={promoAmountError}
        inputType="number"
        disabled={!!promoCode}
      />
      <TextInput
        value={redemptionLimit}
        onChange={setRedemptionLimit}
        label="Redemption Limit"
        className={style.textInput}
        error={redemptionLimitError}
        inputType="number"
      />
      {!!promoCode && (
        <div style={{ marginBottom: 12 }}>
          Total Redeemed: {promoCode.totalRedeemed}
        </div>
      )}
      <div className={style.inputContainer}>
        <div
          className={style.checkbox}
          style={{ backgroundColor: isActive ? colors.success : colors.error }}
          onClick={() => {
            setIsActive(!isActive);
          }}
        />
        <div>{`Is Active (${
          isActive
            ? "Yes - will work in app between start and end date, before redemption limit is reached"
            : "No - will not work in app until set to active"
        })`}</div>
      </div>
      <div onClick={onSubmit} className={style.submit}>
        {submitting ? <Icon.Spinner size={15} /> : "Submit"}
      </div>
    </div>
  );
};

export const CreatePromoCode = connect((state: RootState) => ({
  campaigns: Object.values(state.promos.campaigns.items ?? {}),
}))(CreatePromoCodeInternal);
