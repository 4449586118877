// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Druk-Wide-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./circular-std.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Druk Wide\";\n  src: local(\"Druk Wide\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Circular Std\";\n  src: local(\"Circular Std\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n", "",{"version":3,"sources":["webpack://./styles/fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,mFAAqE;AACvE;;AAEA;EACE,2BAA2B;EAC3B,sFAAsE;AACxE","sourcesContent":["@font-face {\n  font-family: \"Druk Wide\";\n  src: local(\"Druk Wide\"), url(./Druk-Wide-Bold.ttf) format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Circular Std\";\n  src: local(\"Circular Std\"), url(./circular-std.ttf) format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
