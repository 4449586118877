import * as Models from "../../models";
import { MessageActions } from "../actions";

export interface MessageStore {
  messages: {
    items: Record<string, Models.Messages.Message> | null;
    hasNext: boolean;
    nextCursor?: string;
    hasPrevious: boolean;
    previousCursor?: string;
  };
}

export const initialState: MessageStore = {
  messages: {
    items: null,
    hasNext: false,
    hasPrevious: false,
  },
};

export const messages = (
  state: MessageStore = initialState,
  action: MessageActions.Actions,
): MessageStore => {
  switch (action.type) {
    case MessageActions.ActionTypes.GET_MESSAGES_SUCCESS: {
      const newDict: Record<string, Models.Messages.Message> = state.messages
        .items
        ? { ...state.messages.items }
        : {};
      action.payload.nodes.forEach((message) => {
        newDict[message.messageId] = message;
      });
      return {
        ...state,
        messages: {
          items: newDict,
          hasNext: action.payload.pageInfo.hasNextPage,
          nextCursor: action.payload.pageInfo.nextCursor,
          hasPrevious: action.payload.pageInfo.hasPreviousPage,
          previousCursor: action.payload.pageInfo.previousCursor,
        },
      };
    }
    case MessageActions.ActionTypes.UPDATE_MESSAGE_SUCCESS: {
      const { messageId } = action.payload.message;
      return {
        ...state,
        messages: {
          ...state.messages,
          items: {
            ...state.messages.items,
            [messageId]: {
              ...(state.messages.items && state.messages.items[messageId]),
              ...action.payload.message,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};
