import { gql } from "@apollo/client";

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      userProfile {
        firstName
        lastName
        dateOfBirth
        cellPhoneNumber
        adminUpdatedBy
        updatedOnUtc
      }
      errors {
        ... on IncompleteUserProfileError {
          message
        }
      }
    }
  }
`;
