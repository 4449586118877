import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const picker = s({
  marginBottom: 20,
  width: "30%",
});

export const input = s({
  marginBottom: 20,
  width: "90%",
});

export const propOptionContainer = s({
  display: "flex",
  marginBottom: 20,
  alignItems: "center",
});

export const dropDown = s({
  marginRight: 40,
});

export const addPropOptionButton = s({
  padding: `4px 7px`,
  background: Style.colors.subtle,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 500,
  marginBottom: 8,
  width: "auto",
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.primary,
    },
  },
});

export const removeImageButton = s({
  padding: `4px 12px`,
  fontSize: 16,
  background: Style.colors.darkTranslucent650,
  color: Style.text.colors.inverse,
  cursor: "pointer",
  borderRadius: 8,
  width: "fit-content",
  textAlign: "center",
  fontWeight: 500,
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent500,
    },
  },
  marginBottom: 10,
});

export const textInput = s({ width: "100%" });

export const inputContainer = s({
  display: "flex",
  alignItems: "center",
  gap: 12,
  marginBottom: 12,
});

export const checkbox = s({
  width: 24,
  height: 24,
  border: `1px solid black`,
});

export const mdWrapper = s({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  whiteSpace: "pre-wrap",
  marginBottom: 10,
});

export const error = s({
  paddingTop: 2,
  color: Style.colors.error,
  fontSize: 12,
  marginBottom: 8,
});

export const submit = s({
  padding: 15,
  background: Style.colors.primary,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 600,
  width: 300,
  margin: "auto",
});

export const imageContainer = s({
  height: "200px",
  marginBottom: 15,
});

export const image = s({
  height: "100%",
});

export const optionPreview = s({
  padding: 40,
  marginRight: 8,
  fontSize: 16,
});

export const optionImagePreviewContainer = s({
  height: 80,
  marginRight: 16,
});
