import { Maybe, PageInfo, Scalars } from "./shared";

export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};

export type Author = {
  __typename?: "Author";
  authorId: Scalars["Int"];
  authorExternalId: Scalars["UUID"];
  name: Scalars["String"];
  email: Scalars["String"];
  discord?: Maybe<Scalars["String"]>;
  tikTok?: Maybe<Scalars["String"]>;
  instagram?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  imageUrl: Scalars["String"];
  blurb: Scalars["String"];
  title: Scalars["String"];
};

export type PropSpecial = {
  __typename?: "PropSpecial";
  propSpecialId: Scalars["Int"];
  name: Scalars["String"];
};

export type Prop = {
  __typename?: "Prop";
  propInternalId: Scalars["Long"];
  propExternalId: Scalars["UUID"];
  hasSamePropDecorationImage: Scalars["Boolean"];
  publishingPublishingStatusTypeId: PublishingStatusType;
  publishingStatus: PublishingStatus;
  propPropStatusTypeId: PropStatusType;
  propStatus: PropStatus;
  startTimeUtc: Scalars["DateTime"];
  closeTimeUtc: Scalars["DateTime"];
  title: Scalars["String"];
  sortOrder: Maybe<Scalars["Int"]>;
  propLine: Scalars["String"];
  tooltipInfo: Scalars["String"];
  televisionNetwork: Scalars["String"];
  propPropOptions: Array<PropPropOption>;
  propTags: Array<PropTag>;
  propSpecials: Array<PropSpecial>;
  resultId?: Maybe<Scalars["Long"]>;
  result?: Maybe<PropOption>;
  author: Author;
  publishedOnUtc: Scalars["DateTime"];
  recordUpdatedBy?: Maybe<Scalars["String"]>;
  updatedOnUtc: Scalars["DateTime"];
  versions: Array<Scalars["String"]>;
  propImageUrl: Scalars["String"];
  propContent: Scalars["String"];
  propContentFromVersion: Scalars["String"];
};

export type PropPropContentFromVersionArgs = {
  versionId: Scalars["String"];
};

export type PropAssociateTagToPropPayload = {
  __typename?: "PropAssociateTagToPropPayload";
  propTag?: Maybe<PropTag>;
};

export type PropCreatePayload = {
  __typename?: "PropCreatePayload";
  prop?: Maybe<Prop>;
};

export type PropDecoration = {
  __typename?: "PropDecoration";
  propDecorationId: Scalars["Long"];
  backgroundColor?: Maybe<Scalars["String"]>;
  fontColor?: Maybe<Scalars["String"]>;
  imageUrl?: Maybe<Scalars["String"]>;
  propDecorationPlayerInfo: PlayerInfo[];
};

export type PropDeleteDraftPropPayload = {
  __typename?: "PropDeleteDraftPropPayload";
  boolean?: Maybe<Scalars["Boolean"]>;
};

export type PropOption = {
  __typename?: "PropOption";
  propOptionId: Scalars["Long"];
  propText: Scalars["String"];
  propPropOptions: Array<PropPropOption>;
  defaultDecorationId?: Maybe<Scalars["Long"]>;
  defaultDecoration?: Maybe<PropDecoration>;
};

export interface PlayerInfo {
  playerId: number;
  teamId: number;
}

export type PropOptionCreatePayload = {
  __typename?: "PropOptionCreatePayload";
  propOption?: Maybe<PropOption>;
};

/** A connection to a list of items. */
export type PropOptionsConnection = {
  __typename?: "PropOptionsConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PropOptionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PropOption>>;
};

/** An edge in a connection. */
export type PropOptionsEdge = {
  __typename?: "PropOptionsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: PropOption;
};

export type PropPropOption = {
  __typename?: "PropPropOption";
  propInternalId: Scalars["Long"];
  propOptionId: Scalars["Long"];
  propOption: PropOption;
  propDecorationId?: Maybe<Scalars["Long"]>;
  decoration?: Maybe<PropDecoration>;
};

export type PropStatus = {
  __typename?: "PropStatus";
  propPropStatusTypeId: PropStatusType;
  propStatusName: Scalars["String"];
};

export type PropTag = {
  __typename?: "PropTag";
  propTagId: Scalars["Int"];
  name: Scalars["String"];
  props: Array<Prop>;
};

export interface CreatePropDecorationPlayerInfoRequestInput {
  playerId: number;
  teamId: number;
}

export type CreatePropDecorationRequestInput = {
  fontColor: string;
  backgroundColor: string;
  optionImageB64?: string;
  optionImageFileUrl?: string;
  propDecorationPlayerInfo: CreatePropDecorationPlayerInfoRequestInput[];
};

/** A connection to a list of items. */
export type PropTagsConnection = {
  __typename?: "PropTagsConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PropTagsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<PropTag>>;
};

/** An edge in a connection. */
export type PropTagsEdge = {
  __typename?: "PropTagsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: PropTag;
};

export type PropUpdateContentPayload = {
  __typename?: "PropUpdateContentPayload";
  prop?: Maybe<Prop>;
};

/** A connection to a list of items. */
export type PropsConnection = {
  __typename?: "PropsConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PropsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Prop>>;
};

/** An edge in a connection. */
export type PropsEdge = {
  __typename?: "PropsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Prop;
};

/** A connection to a list of items. */
export type PropsForTagConnection = {
  __typename?: "PropsForTagConnection";
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<PropsForTagEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Array<Prop>>>;
};

/** An edge in a connection. */
export type PropsForTagEdge = {
  __typename?: "PropsForTagEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Array<Prop>;
};

export type PublishingStatus = {
  __typename?: "PublishingStatus";
  publishingPublishingStatusTypeId: PublishingStatusType;
  publishingStatusName: Scalars["String"];
};

export enum ApplyPolicy {
  BeforeResolver = "BEFORE_RESOLVER",
  AfterResolver = "AFTER_RESOLVER",
}

export enum PropStatusType {
  Pending = "PENDING",
  Settling = "SETTLING",
  Settled = "SETTLED",
  Canceling = "CANCELING",
  Canceled = "CANCELED",
}

export enum PublishingStatusType {
  Draft = "DRAFT",
  Published = "PUBLISHED",
  Removed = "REMOVED",
}

export enum SortEnumType {
  Asc = "ASC",
  Desc = "DESC",
}
