import React, { useEffect, FC } from "react";
import * as style from "./User.style";
import { useApolloClient, useMutation } from "@apollo/client";
import { Mutation, Query } from "../../../gql";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../../models";
import { RootState } from "../../../store/reducers";
import { useFirebaseContext } from "../../../auth";
import { Icon, TextInput } from "../../../components";
import { MenuItem, Select } from "@mui/material";
import { date } from "../../../utility";
import { EditUser } from "../EditUser";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { UserTransactions } from "../UserTransactions";
import { DeleteUser } from "./DeleteUser";

interface StateProps {
  users: Record<string, Models.Users.FirebaseUserEntity> | null;
}

interface OwnProps {
  onClose: (deletedUser?: number) => void;
  userId: number;
}

type ComponentProps = StateProps & OwnProps;

const transactionReasons = [
  "Community Card",
  "Featured Community Card",
  "Risk Free",
  "Entry Match",
  "Deposit Match",
  "Entry Bonus",
  "Referral",
  "Entry Refund",
  "Bad Beat",
  "Deposit Refund",
  "Make Good",
  "User Feedback",
  "Campus Ambassador",
  "Streak Milestone",
  "Mission Accomplished",
  "Gift from Units",
  "Test",
  "Site Credit Expired",
];
export const UserInternal: FC<ComponentProps> = ({
  users,
  onClose,
  userId,
}) => {
  /* Apollo */
  const client = useApolloClient();
  const [loading, setLoading] = React.useState(false);
  const [loadingUsd, setLoadingUsd] = React.useState(false);
  const dispatch = useDispatch();
  const { userJwt } = useFirebaseContext();
  const [editMode, setEditMode] = React.useState(false);
  const [deleteMode, setDeleteMode] = React.useState(false);
  const [transactionsMode, setTransactionsMode] = React.useState(false);
  const [creditAmount, setCreditAmount] = React.useState("");
  const [creditAmountError, setCreditAmountError] = React.useState("");
  const [creditReason, setCreditReason] = React.useState("");
  const [creditReasonError, setCreditReasonError] = React.useState("");
  const [usdAmount, setUsdAmount] = React.useState("");
  const [usdAmountError, setUsdAmountError] = React.useState("");
  const [usdReason, setUsdReason] = React.useState("");
  const [usdReasonError, setUsdReasonError] = React.useState("");
  const { auth } = useFirebaseContext();

  if (!users || !users[userId] || !userJwt) {
    onClose();
    return null;
  }

  /**
   * Give Site Credit
   */
  const [addWalletTransaction, addWalletTransactionStatus] = useMutation(
    Mutation.ADD_WALLET_TRANSACTION_MUTATION,
  );
  const onAddSiteCredit = () => {
    if (!creditReason) {
      setCreditReasonError("You must select a reason to add site credit");
    } else {
      setCreditReasonError("");
      const transactionAmount = parseInt(creditAmount);
      if (typeof transactionAmount === "number" && !isNaN(transactionAmount)) {
        setLoading(true);
        addWalletTransaction({
          variables: {
            input: {
              userid: user.id,
              transactionAmount,
              currencyType: "SITE_CREDIT",
              refId: uuidv4(),
              reason: creditReason,
            },
          },
        })
          .then((res) => {
            toast.success(
              `successfully credited $${transactionAmount} of Site Credit to ${user.email}`,
            );
            setLoading(false);
            onClose();
          })
          .catch((e) => {
            setLoading(false);
          });
      } else {
        setCreditAmountError("Please enter a valid number");
      }
    }
  };
  const onAddUsd = () => {
    if (auth?.currentUser?.email === "torey@playunits.co") {
      const transactionAmount = parseInt(usdAmount);
      if (typeof transactionAmount === "number" && !isNaN(transactionAmount)) {
        setLoadingUsd(true);
        addWalletTransaction({
          variables: {
            input: {
              userid: user.id,
              transactionAmount,
              currencyType: "USD",
              refId: uuidv4(),
              reason: usdReason,
            },
          },
        })
          .then((res) => {
            toast.success(
              `successfully credited $${transactionAmount} of USD to ${user.email}`,
            );
            setLoadingUsd(false);
            onClose();
          })
          .catch((e) => {
            setLoadingUsd(false);
          });
      } else {
        setUsdAmountError("Please enter a valid number");
      }
    } else {
      toast.error(`Sorry, you do not have permission to perform this action.`);
    }
  };

  const user = users[userId];

  return editMode ? (
    <EditUser user={user} onClose={() => setEditMode(false)} />
  ) : transactionsMode ? (
    <UserTransactions user={user} onClose={() => setTransactionsMode(false)} />
  ) : deleteMode ? (
    <DeleteUser
      user={user}
      onClose={(deletedUser?: number) => {
        setDeleteMode(false);
        if (!!deletedUser) {
          onClose(deletedUser);
        }
      }}
    />
  ) : !!user.playerProfile ? (
    <div className={style.component}>
      <div className={style.row}>
        <div className={style.label}>Id</div>
        <div>{user.id}</div>
      </div>

      <div className={style.row}>
        <div className={style.label}>UserExternalId</div>
        <div>{user.userExternalId}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Name</div>
        <div>
          {!!user.userProfile
            ? `${user.userProfile.firstName} ${user.userProfile.lastName}`
            : "N/A"}
        </div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Email</div>
        <div>{user.email}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Address</div>
        <div>{user.userProfile?.mailingAddress?.addressLine1 ?? "N/A"}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Phone</div>
        <div>{user.userProfile?.cellPhoneNumber ?? "N/A"}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Account Status</div>
        <div>{user.playerProfile.accountStatus}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>KYC Status</div>
        <div>{user.playerProfile.kycStatus}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>T&amp;C Status</div>
        <div>{user.playerProfile.termsAndConditionsStatus}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Self Exclusion Status</div>
        <div>{user.playerProfile.selfExcludedStatus}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Deposit Enabled</div>
        <div>{user.playerProfile.depositEnabled.toString()}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Wagering Enabled</div>
        <div>{user.playerProfile.wageringEnabled.toString()}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Promo Enabled</div>
        <div>{user.playerProfile.promoEnabled.toString()}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Account Locked</div>
        <div>{user.playerProfile.accountLocked.toString()}</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Last Sign In</div>
        <div>
          {!!user.lastSignInDateUtc
            ? date.toShortDateAndTimeUTC(new Date(user.lastSignInDateUtc))
            : "N/A"}
        </div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Sign Up Date</div>
        <div>
          {!!user.signUpDateUtc
            ? date.toShortDateAndTimeUTC(new Date(user.signUpDateUtc))
            : "N/A"}
        </div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Current Streak</div>
        <div>
          {user.userStreaks.find(
            (s) => s.streakType === Models.Users.StreakKind.PLACEMENT_STREAK,
          )?.currentStreak ?? 0}
        </div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Longest Streak</div>
        <div>
          {user.userStreaks.find(
            (s) => s.streakType === Models.Users.StreakKind.PLACEMENT_STREAK,
          )?.longestStreak ?? 0}
        </div>
      </div>
      {/* <div className={style.row}>
        <div className={style.label}>Lifetime Turnover</div>
        <div>N/A</div>
      </div> */}
      {/* <div className={style.row}>
        <div className={style.label}>Account Activity</div>
        <div>N/A</div>
      </div>
      <div className={style.row}>
        <div className={style.label}>Notes</div>
        <div>N/A</div>
      </div> */}
      <div className={style.row}>
        <div className={style.editButton} onClick={() => setEditMode(true)}>
          Edit User
        </div>
        <div
          className={style.editButton}
          onClick={() => setTransactionsMode(true)}
        >
          View User Transactions
        </div>
      </div>
      <div className={style.row}>
        <div className={style.editButton} onClick={onAddSiteCredit}>
          {loading ? <Icon.Spinner size={16} /> : "Add Site Credit"}
        </div>
        <TextInput
          wrapperClassName={style.input}
          value={creditAmount}
          onChange={setCreditAmount}
          label="Credit Amount"
          error={creditAmountError}
        />
        <div>
          <Select
            className={style.input}
            value={creditReason}
            onChange={(e) => setCreditReason(e.target.value)}
            autoWidth={true}
            label="Reason"
          >
            {transactionReasons.map((reason) => (
              <MenuItem value={reason} key={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
          {creditReasonError && (
            <div className={style.error}>{creditReasonError}</div>
          )}
        </div>
      </div>
      <div className={style.row}>
        <div className={style.editButton} onClick={onAddUsd}>
          {loadingUsd ? <Icon.Spinner size={16} /> : "Add USD"}
        </div>
        <TextInput
          wrapperClassName={style.input}
          value={usdAmount}
          onChange={setUsdAmount}
          label="USD Amount"
          error={usdAmountError}
        />
        {/* <div>
          <Select
            className={style.input}
            value={usdReason}
            onChange={(e) => setUsdReason(e.target.value)}
            autoWidth={true}
            label="Reason"
          >
            {transactionReasons.map((reason) => (
              <MenuItem value={reason} key={reason}>
                {reason}
              </MenuItem>
            ))}
          </Select>
          {usdReasonError && (
            <div className={style.error}>{usdReasonError}</div>
          )}
        </div> */}
      </div>
      <div className={style.row}>
        <div className={style.editButton} onClick={() => setDeleteMode(true)}>
          Delete User
        </div>
      </div>
    </div>
  ) : (
    <div className={style.noPlayerProfile}>
      Player profile doesn't exist which is an unexpected state
    </div>
  );
};

export const User = connect(
  (state: RootState) => ({
    users: state.users.users.items,
  }),
  {},
)(UserInternal);
