import * as React from "react";
import * as style from "./PromoCodes.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../gql";
import { connect, useDispatch } from "react-redux";
import { PromoActions } from "../../store/actions";
import { RootState } from "../../store/reducers";
import { Icon, Modal } from "../../components";
import * as Models from "../../models";
import { colors } from "../../styles";
import { CreateCampaign } from "./CreateCampaign";

interface StateProps {
  campaigns: Record<string, Models.Promos.Campaign> | null;
  hasPrevious: boolean;
  startCursor?: string;
}

export const ViewCampaignsInternal: React.FC<StateProps> = ({
  campaigns,
  hasPrevious,
  startCursor,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedCampaign, setSelectedCampaign] = React.useState<number | null>(
    null,
  );

  // /** Get Promo Codes */
  const getCampaigns = (before?: string) => {
    client
      .query({
        query: Query.GET_CAMPAIGNS,
        variables: {
          last: 50,
          before,
        },
      })
      .then((res) => {
        dispatch(PromoActions.GetCampaignsSuccess(res.data.campaigns));
      });
  };
  React.useEffect(() => {
    getCampaigns();
  }, []);

  const campaignsArray = !campaigns ? null : Object.values(campaigns);

  return (
    <div className={style.component}>
      {!campaignsArray ? (
        <Icon.Spinner size={16} />
      ) : (
        <div className={style.messagesContainer}>
          <div
            className={style.message}
            style={{ backgroundColor: colors.subtle, fontWeight: 600 }}
          >
            <div className={style.field}>Id</div>
            <div className={style.field}>Name</div>
            <div className={style.field}>Description</div>
          </div>
          {campaignsArray.map((campaign) => (
            <div
              className={style.message}
              key={campaign.id}
              onClick={() => setSelectedCampaign(campaign.id)}
            >
              <div className={style.field}>{campaign.id}</div>
              <div className={style.field}>{campaign.name}</div>
              <div className={style.field}>{campaign.description}</div>
            </div>
          ))}
        </div>
      )}
      {selectedCampaign && !!campaigns && (
        <Modal
          onRequestClose={() => {
            setSelectedCampaign(null);
          }}
          isOpen={!!selectedCampaign}
        >
          <CreateCampaign
            campaign={campaigns[selectedCampaign]}
            onClose={() => setSelectedCampaign(null)}
          />
        </Modal>
      )}
    </div>
  );
};

export const ViewCampaigns = connect((state: RootState) => ({
  campaigns: state.promos.campaigns.items,
  hasPrevious: state.promos.campaigns.hasPrevious,
  startCursor: state.promos.campaigns.previousCursor,
}))(ViewCampaignsInternal);
