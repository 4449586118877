import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const title = s({
  fontSize: 32,
  fontWeight: 600,
  textAlign: "center",
  marginBottom: 12,
});

export const propSelection = s({
  border: "solid black 1px",
  borderBottomWidth: 0,
  marginBottom: 24,
  maxHeight: 200,
  overflowY: "scroll",
});

export const propSelectionItem = s({
  borderBottom: "solid black 1px",
  padding: "6px 12px",
  display: "flex",
  justifyContent: "space-between",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: "rgba(0, 0, 0, .1)",
    },
  },
});

export const selectedItem = s({
  backgroundColor: `${Style.colors.subtle} !important`,
});

export const resultItem = s({
  display: "flex",
  gap: 8,
});

export const result = s({
  fontWeight: 700,
});

export const button = s({
  borderRadius: 2,
  border: `1px solid ${Style.colors.darkTranslucent650}`,
  cursor: "pointer",
  padding: 6,
  height: 20,
  backgroundColor: Style.colors.primary,
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.subtle,
      transition: ".2s ease-in",
    },
  },
});
