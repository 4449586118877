import * as Models from "../../models";
import * as ActionModels from "./ActionCreator";

export enum ActionTypes {
  /**
   * PROPS
   */
  GET_PROPS_SUCCESS = "PROPS/GET_PROPS_SUCCESS",
  UPDATE_PROP_SUCCESS = "PROPS/UPDATE_PROP",
  /**
   * PROP OPTIONS
   */
  GET_PROP_OPTIONS_SUCCESS = "PROPS/GET_PROP_OPTIONS_SUCCESS",
  GET_SYSTEM_PROP_OPTIONS_SUCCESS = "PROPS/GET_SYSTEM_PROP_OPTIONS_SUCCESS",
  CREATE_PROP_OPTION_SUCCESS = "PROPS/CREATE_PROP_OPTION_SUCCESS",
  UPDATE_PROP_DECORATION_SUCCESS = "PROPS/UPDATE_PROP_DECORATION_SUCCESS",
  /**
   * PROP TAGS
   */
  GET_PROP_TAGS_SUCCESS = "PROPS/GET_PROP_TAGS_SUCCESS",
  CREATE_PROP_TAG_SUCCESS = "PROPS/CREATE_PROP_TAG_SUCCESS",
  /**
   * AUTHORS
   */
  GET_AUTHORS_SUCCESS = "PROPS/GET_AUTHORS_SUCCESS",
  UPDATE_AUTHOR_SUCCESS = "PROPS/UPDATE_AUTHOR_SUCCESS",
  /**
   * SPECIALS
   */
  GET_PROP_SPECIALS_SUCCESS = "PROPS/GET_PROP_SPECIALS_SUCCESS",
}

export const GetPropsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_PROPS_SUCCESS,
  {
    props: {
      nodes: Models.Props.Prop[];
    };
    hasNext: boolean;
    nextCursor?: string;
    isResulted?: boolean;
  }
> = ActionModels.createActionCreator(ActionTypes.GET_PROPS_SUCCESS);

export const UpdatePropSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_PROP_SUCCESS,
  {
    prop: Models.Props.Prop;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_PROP_SUCCESS);

export const GetPropOptionsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_PROP_OPTIONS_SUCCESS,
  {
    props: {
      nodes: Models.Props.PropOption[];
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_PROP_OPTIONS_SUCCESS);

export const GetSystemPropOptionsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_SYSTEM_PROP_OPTIONS_SUCCESS,
  {
    props: {
      nodes: Models.Props.PropOption[];
    };
  }
> = ActionModels.createActionCreator(
  ActionTypes.GET_SYSTEM_PROP_OPTIONS_SUCCESS,
);

export const CreatePropOptionSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.CREATE_PROP_OPTION_SUCCESS,
  {
    propOption: Models.Props.PropOption;
  }
> = ActionModels.createActionCreator(ActionTypes.CREATE_PROP_OPTION_SUCCESS);

export const UpdatePropDecorationSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_PROP_DECORATION_SUCCESS,
  {
    propOption: Models.Props.PropOption;
    newDecoration: Models.Props.PropDecoration;
  }
> = ActionModels.createActionCreator(
  ActionTypes.UPDATE_PROP_DECORATION_SUCCESS,
);

export const GetPropTagsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_PROP_TAGS_SUCCESS,
  {
    tags: {
      nodes: Models.Props.PropTag[];
    };
    hasNext: boolean;
    nextCursor?: string;
  }
> = ActionModels.createActionCreator(ActionTypes.GET_PROP_TAGS_SUCCESS);

export const CreatePropTagSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.CREATE_PROP_TAG_SUCCESS,
  {
    tag: Models.Props.PropTag;
  }
> = ActionModels.createActionCreator(ActionTypes.CREATE_PROP_TAG_SUCCESS);

export const GetAuthorsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_AUTHORS_SUCCESS,
  {
    authors: {
      nodes: Models.Props.Author[];
    };
  }
> = ActionModels.createActionCreator(ActionTypes.GET_AUTHORS_SUCCESS);

export const UpdateAuthorSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.UPDATE_AUTHOR_SUCCESS,
  {
    authorId: number;
    author: Models.Props.Author | null;
  }
> = ActionModels.createActionCreator(ActionTypes.UPDATE_AUTHOR_SUCCESS);

export const GetPropSpecialsSuccess: ActionModels.ActionCreator<
  typeof ActionTypes.GET_PROP_SPECIALS_SUCCESS,
  {
    specials: Models.Props.PropSpecial[];
  }
> = ActionModels.createActionCreator(ActionTypes.GET_PROP_SPECIALS_SUCCESS);

export type Actions = ReturnType<
  | typeof GetPropsSuccess
  | typeof UpdatePropSuccess
  | typeof GetPropOptionsSuccess
  | typeof CreatePropOptionSuccess
  | typeof GetPropTagsSuccess
  | typeof GetSystemPropOptionsSuccess
  | typeof GetAuthorsSuccess
  | typeof UpdateAuthorSuccess
  | typeof UpdatePropDecorationSuccess
  | typeof GetPropSpecialsSuccess
  | typeof CreatePropTagSuccess
>;
