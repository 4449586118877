import "date-fns";
import React from "react";
import TextField from "@mui/material/TextField";
import { DateTimePicker as MUIDateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as style from "./DateTimePicker.style";

export interface ComponentProps {
  /**
   * Optional className to apply to the component
   */
  className?: string;
  wrapperClassName?: string;
  value: Date | null;
  label: string;
  onChange: (date: any) => void;
  error?: string;
  disabled?: boolean;
}
/**
 * DatePicker component
 * Standard MUI Date Picker Wrapper
 */
const DateTimePicker: React.FC<ComponentProps> = ({
  wrapperClassName,
  className,
  value,
  label,
  onChange,
  error,
  disabled,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={wrapperClassName}>
        <MUIDateTimePicker
          className={className}
          label={label}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
          disabled={disabled}
        />
        {error && <div className={style.error}>{error}</div>}
      </div>
    </LocalizationProvider>
  );
};

DateTimePicker.displayName = "DateTimePicker";

export { DateTimePicker };
