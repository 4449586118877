import React, { useEffect, FC } from "react";
import * as style from "./ResultedProps.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../gql";
import { PropActions } from "../../store/actions";
import { connect, useDispatch } from "react-redux";
import * as Models from "../../models";
import { classes } from "typestyle";
import { RootState } from "../../store/reducers";
import { useFirebaseContext } from "../../auth";
import { Icon, Modal } from "../../components";
import { CreateProp } from "../CreateProp";
import { date } from "../../utility";
import { decode } from "html-entities";

interface StateProps {
  props: Record<string, Models.Props.Prop> | null;
  hasNext: boolean;
  nextCursor?: string;
}

export const ResultedPropsInternal: FC<StateProps> = ({
  props,
  hasNext,
  nextCursor,
}) => {
  /* Apollo */
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedProp, setSelectedProp] =
    React.useState<Models.Props.Prop | null>(null);
  const [loading, setLoading] = React.useState(false);
  const { userJwt } = useFirebaseContext();
  /* Values */

  const getProps = () => {
    if (userJwt) {
      setLoading(true);
      client
        .query({
          query: Query.GET_PROPS_QUERY,
          variables: {
            first: 10,
            after: !!nextCursor ? nextCursor : undefined,
            where: {
              propStatus: {
                propStatusName: {
                  neq: "Pending",
                },
              },
            },
            order: {
              closeTimeUtc: "DESC",
            },
          },
        })
        .then((res) => {
          setLoading(false);
          dispatch(
            PropActions.GetPropsSuccess({
              props: res.data.props,
              hasNext: res.data.props.pageInfo.hasNextPage,
              nextCursor: res.data.props.pageInfo.endCursor,
              isResulted: true,
            }),
          );
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getProps();
  }, [userJwt]);

  const propsArray = React.useMemo(() => {
    return !props
      ? null
      : Object.values(props)
          .filter((prop) => !!prop.result)
          .sort((a, b) =>
            new Date(a.closeTimeUtc) < new Date(b.closeTimeUtc) ? 1 : -1,
          );
  }, [props]);

  return (
    <>
      <div className={style.component}>
        {!!propsArray ? (
          propsArray.map((prop) => (
            <ResultedProp
              setSelectedProp={setSelectedProp}
              prop={prop}
              key={prop.propExternalId}
            />
          ))
        ) : (
          <div>
            Loading Props <Icon.Spinner size={30} />
          </div>
        )}
        {props && (
          <div className={style.buttonContainer}>
            <div
              className={classes(
                style.updateButton,
                !hasNext ? style.disabled : "",
              )}
              onClick={!hasNext ? undefined : getProps}
            >
              {loading ? <Icon.Spinner size={15} /> : "Get More Props"}
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={!!selectedProp}
        onRequestClose={() => setSelectedProp(null)}
      >
        <CreateProp
          prop={selectedProp}
          onClose={() => {
            setSelectedProp(null);
          }}
        />
      </Modal>
    </>
  );
};

const ResultedProp: FC<{
  prop: Models.Props.Prop;
  setSelectedProp: (prop: Models.Props.Prop) => void;
}> = ({ prop, setSelectedProp }) => {
  const result = React.useMemo(() => {
    return prop.propPropOptions.filter(
      (option) => option.propInternalId == prop.resultId,
    );
  }, [prop]);
  return (
    <div className={style.prop}>
      <div className={style.propTitle}>{decode(prop.title)}</div>
      <div className={style.propTitle}>{decode(prop.propLine)}</div>
      <div className={style.item}>
        {prop.propSpecials.length ? prop.propSpecials[0].name : ""}
      </div>
      <div className={style.item}>
        <div style={{ fontWeight: 700 }}>Publish Time</div>
        <div>{date.toShortDateAndTimeUTC(new Date(prop.startTimeUtc))}</div>
      </div>
      <div className={style.item}>
        <div style={{ fontWeight: 700 }}>Close Time</div>
        <div>
          <div>{prop.recordUpdatedBy}</div>
          <div>{date.toShortDateAndTimeUTC(new Date(prop.closeTimeUtc))}</div>
        </div>
      </div>
      <div className={style.propTitle}>{prop?.result?.propText}</div>
      <div className={style.buttons}>
        {/* <div className={style.buttonContainer}>
          <div
            className={classes(
              style.updateButton,
              !!disabled ? style.disabled : "",
            )}
            onClick={disabled ? undefined : submit}
          >
            {loading ? <Icon.Spinner size={15} /> : "Update Status"}
          </div>
        </div>
        <div className={style.buttonContainer}>
          <div
            className={classes(style.updateButton)}
            onClick={() => setSelectedProp(prop)}
          >
            Details
          </div>
        </div>
        <div className={style.buttonContainer}>
          <div
            className={classes(
              style.resultButton,
              isResultingDisabled ? style.disabled : "",
            )}
            onClick={
              isResultingDisabled ? undefined : () => setResultingProp(prop)
            }
          >
            Result
          </div>
        </div>
        <div className={style.buttonContainer}>
          <div
            className={classes(style.duplicateButton)}
            onClick={() => setDuplicatingProp(prop)}
          >
            Duplicate
          </div>
        </div> */}
      </div>
    </div>
  );
};

export const ResultedProps = connect(
  (state: RootState) => ({
    props: state.props.props.resulted.items,
    hasNext: state.props.props.resulted.hasNext,
    nextCursor: state.props.props.resulted.nextCursor,
  }),
  {},
)(ResultedPropsInternal);
