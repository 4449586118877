import { gql } from "@apollo/client";

export const WALLET_BULK_ADD_SITE_CREDITS = gql`
  mutation WalletBulkAddSiteCredits($input: WalletBulkAddSiteCreditsInput!) {
    walletBulkAddSiteCredits(input: $input) {
      boolean
      errors {
        ... on InvalidTransactionAmountError {
            message
        }
        ... on InvalidRefIdError {
            message
        }
      }
    }
  }
`;
