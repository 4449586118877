import { gql } from "@apollo/client";

export const GET_USERS_QUERY = gql`
  query users($first: Int, $last: Int, $after: String, $before: String) {
    users(first: $first, last: $last, after: $after, before: $before) {
      nodes {
        id
        email
        signUpDateUtc
        lastSignInDateUtc
        userExternalId
        userProfile {
          firstName
          lastName
          dateOfBirth
          cellPhoneNumber
          adminUpdatedBy
          updatedOnUtc
        }
        playerProfile {
          kycStatus
          accountStatus
          termsAndConditionsStatus
          selfExcludedStatus
          depositEnabled
          promoEnabled
          wageringEnabled
          accountLocked
          userId
          adminUpdatedBy
          updatedOnUtc
          featuresBeta
          featuresDebug
        }
        userStreaks {
          currentStreak
          longestStreak
          updatedOnUtc
          streakType
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
