import {
  GoogleAuthProvider,
  getAuth,
  Auth,
  signInWithPopup,
  browserLocalPersistence,
  User,
} from "firebase/auth";
import { initializeApp } from "firebase/app";

import * as React from "react";

interface IFirebaseContext {
  googleOAuthProvider: GoogleAuthProvider | null;
  auth: Auth | null;
  login: () => void;
  logout: () => void;
  loading: boolean;
  userJwt: string | undefined;
}

const defaultFirebaseContext: IFirebaseContext = {
  googleOAuthProvider: null,
  auth: null,
  login: () => {},
  logout: () => {},
  loading: false,
  userJwt: undefined,
};

export const FirebaseContext = React.createContext(defaultFirebaseContext);

export const FirebaseContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  initializeApp(firebaseConfig);
  const googleOAuthProvider = new GoogleAuthProvider();
  googleOAuthProvider.addScope(
    "https://www.googleapis.com/auth/userinfo.email",
  );
  googleOAuthProvider.addScope(
    "https://www.googleapis.com/auth/userinfo.profile",
  );
  const auth = getAuth();
  auth.useDeviceLanguage();
  const [userJwt, setUserJwt] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const login = () => {
    setLoading(true);
    signInWithPopup(auth, googleOAuthProvider)
      .then(() => {
        getAuth()
          .currentUser?.getIdToken()
          .then((token) => {
            localStorage.setItem("userJwt", token);
            setUserJwt(token);
            setLoading(false);
          });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const logout = async () => {
    if (!!auth) {
      await auth.signOut().then(() => {
        localStorage.removeItem("userJwt");
        setUserJwt(undefined);
      });
    }
  };

  const value = {
    googleOAuthProvider,
    auth,
    login,
    logout,
    loading,
    userJwt,
  };

  const refreshToken = (user: User | null) => {
    if (user) {
      user.getIdToken().then((token) => {
        localStorage.setItem("userJwt", token);
        setUserJwt(token);
      });
      setTimeout(() => {
        refreshToken(user);
      }, 59 * 60 * 1000);
    }
  };
  // on auth state change, attempt token refresh
  React.useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      refreshToken(user);
    });
  }, [auth]);

  React.useEffect(() => {
    auth.setPersistence(browserLocalPersistence);
  }, [auth]);

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

export const useFirebaseContext: any = () => {
  const context = React.useContext(FirebaseContext);
  return context;
};
