import * as Models from "../../models";
import { PlayerActions } from "../actions";

export interface PlayerStore {
  leagues: {
    items: Record<string, Models.Bet.League> | null;
    hasNext: boolean;
    nextCursor?: string;
    hasPrevious: boolean;
    previousCursor?: string;
  };
  teams: {
    items: Record<string, Models.Bet.Team> | null;
    hasNext: boolean;
    nextCursor?: string;
    hasPrevious: boolean;
    previousCursor?: string;
  };
  players: {
    items: Record<string, Models.Bet.Player> | null;
    hasNext: boolean;
    nextCursor?: string;
    hasPrevious: boolean;
    previousCursor?: string;
  };
}

export const initialState: PlayerStore = {
  leagues: {
    items: null,
    hasNext: false,
    hasPrevious: false,
  },
  teams: {
    items: null,
    hasNext: false,
    hasPrevious: false,
  },
  players: {
    items: null,
    hasNext: false,
    hasPrevious: false,
  },
};

export const players = (
  state: PlayerStore = initialState,
  action: PlayerActions.Actions,
): PlayerStore => {
  switch (action.type) {
    case PlayerActions.ActionTypes.GET_LEAGUES_SUCCESS: {
      const newDict: Record<string, Models.Bet.League> = state.leagues.items
        ? { ...state.leagues.items }
        : {};
      action.payload.nodes.forEach((league) => {
        newDict[league.id] = league;
      });
      return {
        ...state,
        leagues: {
          items: newDict,
          hasNext: !!action.payload.pageInfo.hasNextPage,
          nextCursor: action.payload.pageInfo.endCursor,
          hasPrevious: !!action.payload.pageInfo.hasPreviousPage,
          previousCursor: action.payload.pageInfo.startCursor,
        },
      };
    }
    case PlayerActions.ActionTypes.UPDATE_LEAGUE_SUCCESS: {
      const { id } = action.payload.league;
      return {
        ...state,
        leagues: {
          ...state.leagues,
          items: {
            ...state.leagues.items,
            [id]: {
              ...(state.leagues.items && state.leagues.items[id]),
              ...action.payload.league,
            },
          },
        },
      };
    }
    case PlayerActions.ActionTypes.GET_TEAMS_SUCCESS: {
      const newDict: Record<string, Models.Bet.Team> = state.teams.items
        ? { ...state.teams.items }
        : {};
      action.payload.nodes.forEach((team) => {
        newDict[team.id] = team;
      });
      return {
        ...state,
        teams: {
          items: newDict,
          hasNext: !!action.payload.pageInfo.hasNextPage,
          nextCursor: action.payload.pageInfo.endCursor,
          hasPrevious: !!action.payload.pageInfo.hasPreviousPage,
          previousCursor: action.payload.pageInfo.startCursor,
        },
      };
    }
    case PlayerActions.ActionTypes.UPDATE_TEAM_SUCCESS: {
      const { id } = action.payload.team;
      return {
        ...state,
        teams: {
          ...state.teams,
          items: {
            ...state.teams.items,
            [id]: {
              ...(state.teams.items && state.teams.items[id]),
              ...action.payload.team,
            },
          },
        },
      };
    }
    case PlayerActions.ActionTypes.GET_PLAYERS_SUCCESS: {
      const newDict: Record<string, Models.Bet.Player> = state.players.items
        ? { ...state.players.items }
        : {};
      action.payload.nodes.forEach((player) => {
        newDict[player.id] = player;
      });
      return {
        ...state,
        players: {
          items: newDict,
          hasNext: !!action.payload.pageInfo.hasNextPage,
          nextCursor: action.payload.pageInfo.endCursor,
          hasPrevious: !!action.payload.pageInfo.hasPreviousPage,
          previousCursor: action.payload.pageInfo.startCursor,
        },
      };
    }
    case PlayerActions.ActionTypes.UPDATE_PLAYER_SUCCESS: {
      const { id } = action.payload.player;
      return {
        ...state,
        players: {
          ...state.players,
          items: {
            ...state.players.items,
            [id]: {
              ...(state.players.items && state.players.items[id]),
              ...action.payload.player,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};
