import * as React from "react";
import * as style from "./Home.style";
import { classes } from "typestyle";
import { CreateProp } from "../CreateProp";
import { UpdateProp } from "../UpdateProp";
import { Message } from "../Message";
import { PromoCodes } from "../PromoCodes";
import { Users } from "../Users";
import { useFirebaseContext } from "../../auth";
import { BulkCredit } from "../BulkCredit";
import { ZeroAllUsers } from "../ZeroAllUsers";
import { ResultedProps } from "../ResultedProps";
import { SortProps } from "../SortProps";
import { GetData } from "../GetData";
import { PlayerZone } from "../PlayerZone";
import { Query } from "../../gql";
import { useApolloClient } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import { PlayerActions } from "../../store/actions";
import { RootState } from "../../store/reducers";
import { players } from "../../store/reducers/players";
import { Risk } from "../Risk";
import { Analytics } from "../Analytics";
import { EntryTracker } from "../EntryTracker";
import { Contests } from "../Contests";

interface StateProps {
  hasNextTeams?: boolean;
  teamsNextCursor?: string;
  hasNextPlayers?: boolean;
  playersNextCursor?: string;
}

export const HomeInternal: React.FC<StateProps> = ({
  hasNextPlayers,
  teamsNextCursor,
  hasNextTeams,
  playersNextCursor,
}) => {
  const [tab, setTab] = React.useState("createProp");
  const [bulkCredit, setBulkCredit] = React.useState(false);
  const [zeroing, setZeroing] = React.useState(false);
  const { userJwt } = useFirebaseContext();
  const client = useApolloClient();
  const dispatch = useDispatch();

  // /** Get Players/Teams/Leagues */
  const getLeagues = (after?: string) => {
    client
      .query({
        query: Query.GET_LEAGUES_QUERY,
        variables: {
          first: 50,
          after,
        },
      })
      .then((res) => {
        dispatch(PlayerActions.GetLeaguesSuccess(res.data.leagues));
      });
  };
  const getTeams = (after?: string) => {
    client
      .query({
        query: Query.GET_TEAMS_QUERY,
        variables: {
          first: 50,
          after,
        },
      })
      .then((res) => {
        dispatch(PlayerActions.GetTeamsSuccess(res.data.teams));
      });
  };
  const getPlayers = (after?: string) => {
    client
      .query({
        query: Query.GET_PLAYERS_QUERY,
        variables: {
          first: 50,
          after,
        },
      })
      .then((res) => {
        dispatch(PlayerActions.GetPlayersSuccess(res.data.players));
      });
  };
  React.useEffect(() => {
    if (userJwt) {
      getLeagues();
      getTeams();
      getPlayers();
    }
  }, [userJwt]);

  React.useEffect(() => {
    if (hasNextPlayers && playersNextCursor) {
      getPlayers(playersNextCursor);
    }
  }, [hasNextPlayers, playersNextCursor]);

  React.useEffect(() => {
    if (hasNextTeams && teamsNextCursor) {
      getTeams(teamsNextCursor);
    }
  }, [hasNextTeams, teamsNextCursor]);

  return !userJwt ? (
    <div className={style.loggedOut}>Please log in to your Units email</div>
  ) : (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("createProp")}
          className={classes(
            style.pageTab,
            tab === "createProp" ? style.selectedPageTab : "",
          )}
        >
          Create Prop
        </div>
        <div
          onClick={() => setTab("users")}
          className={classes(
            style.pageTab,
            tab === "users" ? style.selectedPageTab : "",
          )}
        >
          Users
        </div>
        <div
          onClick={() => setTab("updateProp")}
          className={classes(
            style.pageTab,
            tab === "updateProp" ? style.selectedPageTab : "",
          )}
        >
          Update Prop Status
        </div>
        <div
          onClick={() => setTab("resultedProps")}
          className={classes(
            style.pageTab,
            tab === "resultedProps" ? style.selectedPageTab : "",
          )}
        >
          Resulted Props
        </div>
        <div
          onClick={() => setTab("sortProps")}
          className={classes(
            style.pageTab,
            tab === "sortProps" ? style.selectedPageTab : "",
          )}
        >
          Sort Props
        </div>
        <div
          onClick={() => setTab("messages")}
          className={classes(
            style.pageTab,
            tab === "messages" ? style.selectedPageTab : "",
          )}
        >
          In App Messages
        </div>
        <div
          onClick={() => setTab("promoCodes")}
          className={classes(
            style.pageTab,
            tab === "promoCodes" ? style.selectedPageTab : "",
          )}
        >
          Promo Codes
        </div>
        <div
          onClick={() => setTab("contests")}
          className={classes(
            style.pageTab,
            tab === "contests" ? style.selectedPageTab : "",
          )}
        >
          Contests
        </div>
        <div
          onClick={() => setTab("playerZone")}
          className={classes(
            style.pageTab,
            tab === "playerZone" ? style.selectedPageTab : "",
          )}
        >
          Player Zone
        </div>
        <div
          onClick={() => setTab("risk")}
          className={classes(
            style.pageTab,
            tab === "risk" ? style.selectedPageTab : "",
          )}
        >
          Risk
        </div>
        <div
          onClick={() => setTab("analytics")}
          className={classes(
            style.pageTab,
            tab === "analytics" ? style.selectedPageTab : "",
          )}
        >
          Analytics
        </div>
        <div
          onClick={() => setTab("entries")}
          className={classes(
            style.pageTab,
            tab === "entries" ? style.selectedPageTab : "",
          )}
        >
          Entries
        </div>
        {/* <div
          onClick={() => setZeroing(true)}
          className={classes(
            style.pageTab,
            !!zeroing ? style.selectedPageTab : "",
          )}
        >
          Zero Users Site Credit
        </div> */}
        {/* <div
          onClick={() => setTab("getData")}
          className={classes(
            style.pageTab,
            tab === "getData" ? style.selectedPageTab : "",
          )}
        >
          Get Data
        </div> */}
      </div>
      {tab === "createProp" && (
        <CreateProp getPlayers={getPlayers} getTeams={getTeams} />
      )}
      {tab === "resultedProps" && <ResultedProps />}
      {tab === "updateProp" && (
        <UpdateProp getTeams={getTeams} getPlayers={getPlayers} />
      )}
      {tab === "users" && <Users />}
      {tab === "messages" && <Message />}
      {tab === "promoCodes" && <PromoCodes />}
      {tab === "contests" && <Contests />}
      {tab === "sortProps" && <SortProps />}
      {tab === "risk" && <Risk />}
      {tab === "analytics" && <Analytics />}
      {tab === "entries" && <EntryTracker />}
      {tab === "playerZone" && (
        <PlayerZone
          getTeams={getTeams}
          getPlayers={getPlayers}
          getLeagues={getLeagues}
        />
      )}
      {/* {tab === "getData" && <GetData />} */}
      {!!bulkCredit && <BulkCredit onClose={() => setBulkCredit(false)} />}
      {!!zeroing && <ZeroAllUsers onClose={() => setZeroing(false)} />}
    </div>
  );
};

export const Home = connect(
  (state: RootState) => ({
    hasNextTeams: state.players.teams.hasNext,
    hasNextPlayers: state.players.players.hasNext,
    teamsNextCursor: state.players.teams.nextCursor,
    playersNextCursor: state.players.players.nextCursor,
  }),
  {},
)(HomeInternal);
