import * as React from "react";
import * as style from "./PromoCodes.style";
import { classes } from "typestyle";
import { CreatePromoCode } from "./CreatePromoCode";
import { ViewPromoCodes } from "./ViewPromoCode";
import { CreateCampaign } from "./CreateCampaign";
import { ViewCampaigns } from "./ViewCampaign";

export const PromoCodes: React.FC = () => {
  const [tab, setTab] = React.useState("promos");
  const [subtab, setSubTab] = React.useState("create");
  return (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("promos")}
          className={classes(
            style.pageTab,
            tab === "promos" ? style.selectedPageTab : "",
          )}
        >
          Promo Codes
        </div>
        <div
          onClick={() => setTab("campaigns")}
          className={classes(
            style.pageTab,
            tab === "campaigns" ? style.selectedPageTab : "",
          )}
        >
          Campaigns
        </div>
      </div>
      <div className={style.tabs}>
        <div
          onClick={() => setSubTab("create")}
          className={classes(
            style.pageTab,
            subtab === "create" ? style.selectedPageTab : "",
          )}
        >
          Create {tab == "promos" ? "Promo Code" : "Campaign"}
        </div>
        <div
          onClick={() => setSubTab("view")}
          className={classes(
            style.pageTab,
            subtab === "view" ? style.selectedPageTab : "",
          )}
        >
          View {tab == "promos" ? "Promo Codes" : "Campaigns"}
        </div>
      </div>
      {tab == "promos" && subtab === "create" && <CreatePromoCode />}
      {tab == "promos" && subtab === "view" && <ViewPromoCodes />}
      {tab == "campaigns" && subtab === "create" && <CreateCampaign />}
      {tab == "campaigns" && subtab === "view" && <ViewCampaigns />}
    </div>
  );
};
