import { gql } from "@apollo/client";

export const UPDATE_PLAYER_MUTATION = gql`
  mutation UpdatePlayerMutation($input: UpdatePlayerInput!) {
    updatePlayer(input: $input) {
      player {
        id
        teamId
        name
      }
      errors {
        ... on PlayerExistsError {
          message
        }
      }
    }
  }
`;
