import { gql } from "@apollo/client";

export const GET_CAMPAIGNS = gql`
  query campaigns(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: CampaignFilterInput
  ) {
    campaigns(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
    ) {
      nodes {
        id
        name
        description
        createdAtUtc
        updatedOnUtc
        updatedBy
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
