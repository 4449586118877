/**
 * Redux Action with Payload
 */
export interface Action<T extends string, P extends {}> {
  type: T;
  payload: P;
}

/**
 * Redux Action without Payload
 */
export interface EmptyAction<T extends string> {
  type: T;
}

/**
 * Creates a Redux Action with a payload
 */
export type ActionCreator<T extends string, P extends {}> = (
  payload: P
) => Action<T, P>;

/**
 * Creates a Redux Action without a payload
 */
export type EmptyActionCreator<T extends string> = () => EmptyAction<T>;

/**
 * Creates an ActionCreator
 */
export const createActionCreator =
  <T extends string, P extends {}>(type: T): ActionCreator<T, P> =>
  (payload: P) => ({
    type,
    payload,
  });

/**
 * Creates an EmptyActionCreator
 */
export const createEmptyActionCreator =
  <T extends string>(type: T): EmptyActionCreator<T> =>
  () => ({
    type,
  });
