import { gql } from "@apollo/client";

export const GET_USER_BY_ID_QUERY = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      email
      signUpDateUtc
      lastSignInDateUtc
      userExternalId
      userProfile {
        firstName
        lastName
        dateOfBirth
        cellPhoneNumber
        adminUpdatedBy
        updatedOnUtc
      }
      playerProfile {
        kycStatus
        accountStatus
        termsAndConditionsStatus
        selfExcludedStatus
        depositEnabled
        promoEnabled
        wageringEnabled
        accountLocked
        userId
        adminUpdatedBy
        updatedOnUtc
        featuresBeta
        featuresDebug
      }
      userStreaks {
        currentStreak
        longestStreak
        updatedOnUtc
        streakType
      }
    }
  }
`;
