import * as color from "./colors";

export const fontSize = {
  /** 10 */
  xxxs: 10,
  /** 12 */
  xxs: 12,
  /** 14 */
  xs: 14,
  /** 16 */
  small: 16,
  /** 20 */
  smallMed: 20,
  /** 24 */
  medium: 24,
  /** 28 */
  medLarge: 28,
  /** 32 */
  large: 32,
  /** 40 */
  xl: 40,
  /** 48 */
  xxl: 48,
  /** 58 */
  xxxl: 58,
};

export const colors = {
  main: "#000000",
  subtle: "#688BA4",
  dark: "#071842",
  inverse: color.white2,
  inverseSubtle: color.black4,
};

export const font = {
  /** Circular Std */
  main: "Circular Std",
  /** Druk Wide */
  second: "Druk Wide",
};
