import { gql } from "@apollo/client";

export const SEARCH_USER_BY_EMAIL_QUERY = gql`
  query SearchUserByEmail($email: String!) {
    searchUserByEmail(email: $email) {
      id
      email
      signUpDateUtc
      lastSignInDateUtc
      userExternalId
      userProfile {
        firstName
        lastName
        dateOfBirth
        cellPhoneNumber
        adminUpdatedBy
        updatedOnUtc
      }
      playerProfile {
        kycStatus
        accountStatus
        termsAndConditionsStatus
        selfExcludedStatus
        depositEnabled
        promoEnabled
        wageringEnabled
        accountLocked
        userId
        adminUpdatedBy
        updatedOnUtc
        featuresBeta
        featuresDebug
      }
      userStreaks {
        currentStreak
        longestStreak
        updatedOnUtc
        streakType
      }
    }
  }
`;
