import { gql } from "@apollo/client";

export const GET_LEAGUES_QUERY = gql`
  query leagues(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $where: LeagueFilterInput
    $order: [LeagueSortInput!]
  ) {
    leagues(
      first: $first
      last: $last
      after: $after
      before: $before
      where: $where
      order: $order
    ) {
      nodes {
        id
        name
        leagueCode
        country
        logoUrl
        teams {
          id
          teamCode
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
