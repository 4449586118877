import * as React from "react";
import * as style from "./PromoCodes.style";
import { useApolloClient } from "@apollo/client";
import { Query } from "../../gql";
import { connect, useDispatch } from "react-redux";
import { MessageActions, PromoActions } from "../../store/actions";
import { RootState } from "../../store/reducers";
import { Icon, Modal } from "../../components";
import * as Models from "../../models";
import { date } from "../../utility";
import { CreatePromoCode } from "./CreatePromoCode";
import { decode } from "html-entities";
import { colors } from "../../styles";

interface StateProps {
  promoCodes: Record<string, Models.Promos.PromoCode> | null;
  hasPrevious: boolean;
  startCursor?: string;
}

export const ViewPromoCodesInternal: React.FC<StateProps> = ({
  promoCodes,
  hasPrevious,
  startCursor,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [selectedPromoCode, setSelectedPromoCode] = React.useState<
    number | null
  >(null);

  // /** Get Promo Codes */
  const getPromoCodes = (before?: string) => {
    client
      .query({
        query: Query.GET_PROMO_CODES,
        variables: {
          last: 50,
          before,
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(PromoActions.GetPromoCodesSuccess(res.data.promoCodes));
      });
  };
  React.useEffect(() => {
    getPromoCodes();
  }, []);

  const promoCodesArray = !promoCodes
    ? null
    : Object.values(promoCodes).sort((a, b) =>
        new Date(a.startedAtUtc) > new Date(b.endedAtUtc) ? -1 : 1,
      );

  return (
    <div className={style.component}>
      {!promoCodesArray ? (
        <Icon.Spinner size={16} />
      ) : (
        <div className={style.messagesContainer}>
          <div
            className={style.message}
            style={{ backgroundColor: colors.subtle, fontWeight: 600 }}
          >
            <div className={style.field}>Code</div>
            <div className={style.field}>Amount</div>
            <div className={style.field}>Redemption Limit</div>
            <div className={style.field}>Active</div>
            <div className={style.field}>
              <div>Start Date</div>
            </div>
            <div className={style.field}>
              <div>End Date</div>
            </div>
          </div>
          {promoCodesArray.map((promoCode) => (
            <div
              className={style.message}
              key={promoCode.id}
              onClick={() => setSelectedPromoCode(promoCode.id)}
            >
              <div className={style.field}>{promoCode.code}</div>
              <div className={style.field}>{promoCode.promoAmount}</div>
              <div className={style.field}>{promoCode.redemptionLimit}</div>
              <div className={style.field}>
                {promoCode.isActive ? "Active" : "Inactive"}
              </div>
              <div className={style.field}>
                <div>
                  {date.toShortDateAndTimeUTC(new Date(promoCode.startedAtUtc))}
                </div>
              </div>
              <div className={style.field}>
                <div>
                  {date.toShortDateAndTimeUTC(new Date(promoCode.endedAtUtc))}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {selectedPromoCode && !!promoCodes && (
        <Modal
          onRequestClose={() => {
            setSelectedPromoCode(null);
          }}
          isOpen={!!selectedPromoCode}
        >
          <CreatePromoCode
            promoCode={promoCodes[selectedPromoCode]}
            onClose={() => setSelectedPromoCode(null)}
          />
        </Modal>
      )}
    </div>
  );
};

export const ViewPromoCodes = connect((state: RootState) => ({
  promoCodes: state.promos.promoCodes.items,
  hasPrevious: state.promos.promoCodes.hasPrevious,
  startCursor: state.promos.promoCodes.previousCursor,
}))(ViewPromoCodesInternal);
