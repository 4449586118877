import { gql } from "@apollo/client";

export const CREATE_PROP_TAG_MUTATION = gql`
  mutation CreatePropTagMutation($input: CreatePropTagInput!) {
    createPropTag(input: $input) {
      propTag {
        propTagId
        name
      }
      errors {
        ... on PropTagExistsError {
          message
        }
      }
    }
  }
`;
