import { style as s } from "typestyle";
import * as Style from "../../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const title = s({
  fontWeight: 500,
  marginBottom: 10,
  paddingBottom: 10,
  borderBottom: `1px solid ${Style.colors.darkTranslucent650}`,
});

export const options = s({
  padding: `12px 0px`,
  display: "flex",
});

export const option = s({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 24,
  fontWeight: 600,
  border: `1px solid black`,
  cursor: "pointer",
});

export const selectedOption = s({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 24,
  fontWeight: 600,
  border: `1px solid black`,
  backgroundColor: Style.colors.primary,
  cursor: "pointer",
});

export const transaction = s({
  marginBottom: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "4px 8px",
});

export const usdLabel = s({
  backgroundColor: Style.colors.success,
  padding: `2px 4px`,
  marginRight: 6,
});

export const siteCreditLabel = s({
  backgroundColor: Style.colors.primary,
  padding: "2px 4px",
  marginRight: 6,
});

export const columnCenter = s({
  flex: 1,
  textAlign: "center",
});

export const columnLeft = s({
  flex: 1,
  textAlign: "left",
});

export const columnRight = s({
  flex: 1,
  textAlign: "right",
});

export const label = s({
  fontWeight: 600,
  marginRight: 16,
});

export const buttonRow = s({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const button = s({
  padding: 8,
  background: Style.colors.subtle,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 500,
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.primary,
    },
  },
  marginBottom: 12,
  width: "fit-content",
  marginRight: 12,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 120,
});

export const refId = s({
  cursor: "pointer",
});
