import { gql } from "@apollo/client";

export const CREATE_CONTEST_MUTATION = gql`
  mutation CreateContestMutation($input: CreateContestInput!) {
    createContest(input: $input) {
      contest {
        id
        name
        description
        reward1
        reward2
        reward3
        reward4
        reward5
        tags {
          name
          propTagId
        }
        isActive
        contestStartsAtUtc
        contestEndsAtUtc
        viewableStartsAtUtc
        viewableEndsAtUtc
        createdAtUtc
        updatedAtUtc
      }
      errors {
        ... on ContestExistsError {
          message
        }
      }
    }
  }
`;
