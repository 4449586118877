import * as React from "react";
import * as style from "./Contests.style";
import { classes } from "typestyle";
import { CreateContest } from "./CreateContest";
import { ViewContests } from "./ViewContests";
export const Contests: React.FC = () => {
  const [tab, setTab] = React.useState("create");
  return (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("create")}
          className={classes(
            style.pageTab,
            tab === "create" ? style.selectedPageTab : "",
          )}
        >
          Create Contest
        </div>
        <div
          onClick={() => setTab("view")}
          className={classes(
            style.pageTab,
            tab === "view" ? style.selectedPageTab : "",
          )}
        >
          View Contest
        </div>
      </div>
      {tab === "create" && <CreateContest />}
      {tab === "view" && <ViewContests />}
    </div>
  );
};
