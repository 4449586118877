import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 20,
  fontSize: 16,
});

export const title = s({
  fontSize: 32,
  fontWeight: 600,
  textAlign: "center",
  marginBottom: 12,
});

export const table = s({
  border: "solid black 1px",
  borderBottomWidth: 0,
  marginBottom: 24,
  maxHeight: 800,
  overflowY: "scroll",
  margin: "auto",
  width: 900,
});

export const tableRow = s({
  borderBottom: "solid black 1px",
  padding: "6px 12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: "rgba(0, 0, 0, .1)",
    },
  },
});

export const rowItem = s({
  flex: 1,
  justifyContent: "center",
});
