export const toShortDateUTC = (date: Date) => {
  const shortTime = new Intl.DateTimeFormat("en", {
    dateStyle: "short",
  });
  return shortTime.format(date);
};
export const toShortDateAndTimeUTC = (date: Date) => {
  const shortTime = new Intl.DateTimeFormat("en", {
    dateStyle: "short",
    timeStyle: "short",
  });
  return shortTime.format(date);
};

export const toLocalDateTime = (date: Date) => {
  return (
    date.toLocaleDateString("en-us") + " " + date.toLocaleTimeString("en-us")
  );
};
