import { style as s } from "typestyle";
import * as Style from "../../styles";

export const component = s({
  padding: 80,
  fontSize: 16,
  fontFamily: Style.text.font.main,
  display: "flex",
  justifyContent: "space-between",
});

export const exampleWrapper = s({
  marginBottom: 20,
  width: 150,
});

export const example = s({
  padding: 60,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const input = s({
  marginBottom: 20,
  width: "50%",
});

export const row = s({
  marginBottom: 20,
  display: "flex",
  alignItems: "center",
});

export const label = s({
  fontWeight: 500,
  paddingLeft: 12,
});

export const colorBox = s({
  height: "100px !important",
  width: "100px !important",
  marginRight: 12,
  $nest: {
    "& .react-colorful__pointer": {
      width: 21,
      height: 21,
    },
  },
});

export const dropDown = s({
  minWidth: 400,
  width: "100%",
  marginBottom: 20,
});

export const textInput = s({ width: "100%" });

export const mdWrapper = s({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",

  marginBottom: 10,
});

export const error = s({
  paddingTop: 5,
  color: Style.colors.error,
  fontSize: 12,
});

export const submit = s({
  padding: 15,
  background: Style.colors.primary,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 600,
  width: 300,
  margin: "auto",
  $nest: {
    "&:hover": {
      background: Style.colors.softYellow,
    },
  },
});

export const imageContainer = s({
  height: "200px",
  marginBottom: 15,
});

export const image = s({
  height: "100%",
});

export const removeImageButton = s({
  padding: `4px 12px`,
  fontSize: 16,
  background: Style.colors.darkTranslucent650,
  color: Style.text.colors.inverse,
  cursor: "pointer",
  borderRadius: 8,
  width: "fit-content",
  textAlign: "center",
  fontWeight: 500,
  transition: ".2s ease-in",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent500,
    },
  },
  marginBottom: 10,
});

// SGP

export const playerInfo = s({
  flex: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});

export const addPlayerButton = s({
  padding: 8,
  background: Style.colors.primary,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontWeight: 600,
  width: 150,
  marginBottom: 12,
  $nest: {
    "&:hover": {
      background: Style.colors.softYellow,
    },
  },
});

export const fetchButton = s({
  padding: 8,
  background: Style.colors.softYellow,
  color: Style.text.colors.main,
  cursor: "pointer",
  borderRadius: 8,
  textAlign: "center",
  fontSize: 14,
  fontWeight: 600,
  width: 100,
  marginBottom: 12,
  $nest: {
    "&:hover": {
      background: Style.colors.white,
    },
  },
});

export const prefillTitle = s({
  paddingTop: 80,
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 20,
  width: 200,
});

export const prefillTable = s({
  border: "1px solid black",
  height: 400,
  overflowY: "scroll",
  $nest: {
    "&:nth-child(even)": {
      backgroundColor: Style.colors.darkTranslucent100,
    },
  },
});

export const prefillItem = s({
  padding: `8px 12px`,
  cursor: "pointer",
  transition: ".2s ease-in",
  borderBottom: "1px solid black",
  $nest: {
    "&:hover": {
      backgroundColor: Style.colors.darkTranslucent300,
    },
  },
});
