import * as React from "react";
import * as style from "./Players.style";
import * as Components from "../../../components";
import * as Models from "../../../models";
import { classes } from "typestyle";
import { useMutation } from "@apollo/client";
import { Mutation } from "../../../gql";
import { toast } from "react-toastify";
import { MenuItem, Select } from "@mui/material";
import { connect } from "react-redux";
import { RootState } from "../../../store/reducers";
import { useDispatch } from "react-redux";
import { PlayerActions } from "../../../store/actions";

interface StateProps {
  players: Record<string, Models.Bet.Player> | null;
  teams: Record<string, Models.Bet.Team> | null;
  hasNextTeams?: boolean;
  teamsNextCursor?: string;
  hasNextPlayers?: boolean;
  playersNextCursor?: string;
  leagues: Record<string, Models.Bet.League> | null;
}

interface ComponentProps {
  getTeams: (after?: string) => void;
  getPlayers: (after?: string) => void;
}

export const PlayersInternal: React.FC<StateProps & ComponentProps> = ({
  players,
  teams,
  hasNextTeams,
  hasNextPlayers,
  teamsNextCursor,
  playersNextCursor,
  getTeams,
  getPlayers,
  leagues,
}) => {
  const [tab, setTab] = React.useState("create");
  const [name, setName] = React.useState("");
  const [teamId, setTeamId] = React.useState<number | undefined>(1);
  const [playerSearch, setPlayerSearch] = React.useState("");
  const [playerToUpdate, setPlayerToUpdate] = React.useState<
    number | undefined
  >();
  const dispatch = useDispatch();

  const playersArray = React.useMemo(() => {
    return Object.values(players ?? {}).sort((a, b) =>
      a.name > b.name ? 1 : -1,
    );
  }, [players]);

  const teamsArray = React.useMemo(() => {
    return Object.values(teams ?? {}).sort((a, b) =>
      a.displayName > b.displayName ? 1 : -1,
    );
  }, [teams]);

  /** Submission */
  const [loading, setLoading] = React.useState(false);
  const [createPlayer, createPlayerStatus] = useMutation(
    Mutation.CREATE_PLAYER_MUTATION,
  );
  const [updatePlayer, updatePlayerStatus] = useMutation(
    Mutation.UPDATE_PLAYER_MUTATION,
  );

  const onSubmit = () => {
    if (!name || !teamId) {
      toast.error("Player name and Team Id are required");
    }
    setLoading(true);
    createPlayer({
      variables: {
        input: {
          playerRequest: {
            teamId,
            name,
          },
        },
      },
    })
      .then((res) => {
        if (!!res?.data?.createPlayer?.player?.name) {
          toast.success(
            `Congrats! You have created Player ${res.data.createPlayer.player.name}`,
          );
          dispatch(
            PlayerActions.UpdatePlayerSuccess({
              player: res.data.createPlayer.player,
            }),
          );
          setName("");
        } else {
          toast.error(
            res?.data?.createPlayer?.errors?.length
              ? res.data.createPlayer.errors[0].message
              : "Something went wrong creating this player - please try again",
          );
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error(
          "Something went wrong creating this player - please try again",
        );
        setLoading(false);
      });
  };
  const onUpdate = () => {
    if (!name || !teamId || !playerToUpdate) {
      toast.error("Player Id, player name, and Team Id are required");
    }
    setLoading(true);
    updatePlayer({
      variables: {
        input: {
          updateRequest: {
            id: playerToUpdate,
            teamId,
            name,
          },
        },
      },
    })
      .then((res) => {
        if (!!res?.data?.updatePlayer?.player?.name) {
          toast.success(
            `Congrats! You have updated Player ${res.data.updatePlayer.player.name}`,
          );
          dispatch(
            PlayerActions.UpdatePlayerSuccess({
              player: res.data.updatePlayer.player,
            }),
          );
          setName("");
          setTeamId(1);
          setPlayerToUpdate(undefined);
        } else {
          toast.error(
            res?.data?.updatePlayer?.errors?.length
              ? res.data.updatePlayer.errors[0].message
              : "Something went wrong updating this player - please try again",
          );
        }
        setLoading(false);
      })
      .catch(() => {
        toast.error(
          "Something went wrong updating this player - please try again",
        );
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (tab === "create") {
      setName("");
      setTeamId(1);
    } else if (!!playerToUpdate && !!players) {
      setName(players[playerToUpdate].name);
      setTeamId(players[playerToUpdate].teamId);
    }
  }, [tab, playerToUpdate]);

  return (
    <div className={style.component}>
      <div className={style.tabs}>
        <div
          onClick={() => setTab("create")}
          className={classes(
            style.pageTab,
            tab === "create" ? style.selectedPageTab : "",
          )}
        >
          Create Player
        </div>
        <div
          onClick={() => setTab("view")}
          className={classes(
            style.pageTab,
            tab === "view" ? style.selectedPageTab : "",
          )}
        >
          View Players
        </div>
        <div
          onClick={() => setTab("update")}
          className={classes(
            style.pageTab,
            tab === "update" ? style.selectedPageTab : "",
          )}
        >
          Update Player
        </div>
      </div>
      <div className={style.pageContainer}>
        {tab === "create" ? (
          <>
            <div style={{ marginBottom: 24, display: "flex", gap: 12 }}>
              {teamsArray.length && (
                <Select
                  value={teamId}
                  onChange={(e) => setTeamId(e.target.value as number)}
                  autoWidth={true}
                  label="Team"
                >
                  {teamsArray.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      {team.displayName +
                        (!!leagues && leagues[team.leagueId]
                          ? ` - (${leagues[team.leagueId].leagueCode})`
                          : "")}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
            <Components.TextInput
              value={name}
              onChange={setName}
              className={style.textInput}
              label="Player Name - Lamar Jackson"
            />
            <div
              className={style.button}
              onClick={loading ? undefined : onSubmit}
            >
              {loading ? (
                <Components.Icon.Spinner size={20} />
              ) : (
                "Create Player"
              )}
            </div>
          </>
        ) : tab === "view" ? (
          <>
            <div className={style.table}>
              <div
                className={style.item}
                style={{ fontWeight: 700, textDecoration: "underline" }}
              >
                <div style={{ flex: 1 }}>ID</div>
                <div style={{ flex: 1, textAlign: "center" }}>Name</div>
                <div style={{ flex: 1, textAlign: "right" }}>Team Code</div>
              </div>
              {playersArray.map((player) => (
                <div
                  className={classes(style.item, style.playerItem)}
                  key={player.id}
                >
                  <div style={{ flex: 1 }}>{player.id}</div>
                  <div style={{ flex: 1, textAlign: "center" }}>
                    {player.name}
                  </div>
                  <div style={{ flex: 1, textAlign: "right" }}>
                    {teams && teams[player.teamId]?.teamCode}
                  </div>
                </div>
              ))}
            </div>
            <div
              className={classes(
                style.button,
                !hasNextPlayers ? style.disabled : "",
              )}
              style={{ margin: "auto" }}
              onClick={() => getPlayers(playersNextCursor)}
            >
              {loading ? (
                <Components.Icon.Spinner size={20} />
              ) : (
                "Get More Players"
              )}
            </div>
          </>
        ) : (
          <div
            style={{ display: "flex", justifyContent: "space-between", gap: 8 }}
          >
            <div style={{ flex: 1 }}>
              <input
                value={playerSearch}
                onChange={(e) => setPlayerSearch(e.target.value)}
                placeholder="search"
                className={style.searchInput}
              />
              <div
                className={style.table}
                style={{ width: 500, height: 600, overflowY: "scroll" }}
              >
                {playersArray
                  .filter(
                    (p) =>
                      !!p.name
                        .toLowerCase()
                        .includes(playerSearch.toLowerCase()),
                  )
                  .map((player) => (
                    <div
                      className={classes(style.item, style.playerItem)}
                      style={{ padding: "4px 8px" }}
                      key={player.id}
                      onClick={() => {
                        setPlayerToUpdate(player.id);
                      }}
                    >
                      <div style={{ flex: 1 }}>{player.id}</div>
                      <div style={{ flex: 1, textAlign: "center" }}>
                        {player.name}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {playerToUpdate && (
              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: 24, display: "flex", gap: 12 }}>
                  {teamsArray.length && (
                    <Select
                      value={teamId}
                      onChange={(e) => setTeamId(e.target.value as number)}
                      autoWidth={true}
                      label="Team"
                    >
                      {teamsArray.map((team) => (
                        <MenuItem key={team.id} value={team.id}>
                          {team.displayName +
                            (!!leagues && leagues[team.leagueId]
                              ? ` - (${leagues[team.leagueId].leagueCode})`
                              : "")}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
                <Components.TextInput
                  value={name}
                  onChange={setName}
                  className={style.textInput}
                  label="Player Name - Lamar Jackson"
                />
                <div
                  className={classes(
                    style.button,
                    !!playerToUpdate &&
                      !!players &&
                      players[playerToUpdate].name === name &&
                      players[playerToUpdate].teamId === teamId
                      ? style.disabled
                      : "",
                  )}
                  onClick={
                    loading ||
                    (!!playerToUpdate &&
                      !!players &&
                      players[playerToUpdate].name === name &&
                      players[playerToUpdate].teamId === teamId)
                      ? undefined
                      : onUpdate
                  }
                >
                  {loading ? (
                    <Components.Icon.Spinner size={20} />
                  ) : (
                    "Update Player"
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const Players = connect(
  (state: RootState) => ({
    players: state.players.players.items,
    teams: state.players.teams.items,
    hasNextTeams: state.players.teams.hasNext,
    hasNextPlayers: state.players.players.hasNext,
    teamsNextCursor: state.players.teams.nextCursor,
    playersNextCursor: state.players.players.nextCursor,
    leagues: state.players.leagues.items,
  }),
  {},
)(PlayersInternal);
