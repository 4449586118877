import { gql } from "@apollo/client";

export const CREATE_CAMPAIGN_MUTATION = gql`
  mutation CreateCampaignMutation($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      campaign {
        id
        name
        description
        createdAtUtc
        updatedOnUtc
        updatedBy
      }
    }
  }
`;
