import { Maybe, PageInfo, Scalars } from "./shared";

export type UserFinancials = {
  __typename?: "UserFinancials";
  lifetimeDeposits: Scalars["Decimal"];
  lifetimeSpendUSD: Scalars["Decimal"];
  lifetimeSpendCredits: Scalars["Decimal"];
  lifetimeWinningsCredits: Scalars["Decimal"];
  lifetimeWinningsUSD: Scalars["Decimal"];
  lifetimeWithdrawals: Scalars["Decimal"];
  /** Lifetime amount gambled minus winnings, in credits */
  lifetimeNetExpenditureCredits: Scalars["Decimal"];
  /** Lifetime amount gambled minus winnings, in usd */
  lifetimeNetExpenditureUSD: Scalars["Decimal"];
};

export type FirebaseUserEntity = {
  __typename?: "FirebaseUserEntity";
  id: Scalars["Int"];
  uid: Scalars["String"];
  email: Scalars["String"];
  userExternalId: Scalars["String"];
  signUpDateUtc?: Maybe<Scalars["DateTime"]>;
  userStreaks: [Streak];
  userProfile?: Maybe<UserProfile>;
  playerProfile: PlayerProfile;
  lastSignInDateUtc?: Maybe<Scalars["DateTime"]>;
};

export type Streak = {
  currentStreak: number;
  longestStreak: number;
  minAmount?: number;
  maxAmount?: number;
  beganOnUtc: Date;
  updatedOnUtc: Date;
  streakType: StreakKind;
};

export enum StreakKind {
  PLACEMENT_STREAK = "PLACEMENT_STREAK",
  LOGIN_STREAK = "LOGIN_STREAK",
}

export type IncompleteUserProfileError = Error & {
  __typename?: "IncompleteUserProfileError";
  message: Scalars["String"];
};

export type PlayerProfile = {
  __typename?: "PlayerProfile";
  kycStatus: Scalars["String"];
  accountStatus: Scalars["String"];
  termsAndConditionsStatus: Scalars["String"];
  selfExcludedStatus: Scalars["String"];
  depositEnabled: Scalars["Boolean"];
  promoEnabled: Scalars["Boolean"];
  wageringEnabled: Scalars["Boolean"];
  accountLocked: Scalars["Boolean"];
  userId: Scalars["Int"];
  adminUpdatedBy?: Maybe<Scalars["String"]>;
  updatedOnUtc: Scalars["DateTime"];
  featuresBeta: boolean;
  featuresDebug: boolean;
};

export type UserAddress = {
  __typename?: "UserAddress";
  friendlyName: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2: Scalars["String"];
  city: Scalars["String"];
  stateCode: Scalars["String"];
  zipCode: Scalars["String"];
};

export type UserProfile = {
  __typename?: "UserProfile";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  dateOfBirth: Scalars["DateTime"];
  mailingAddress: UserAddress;
  cellPhoneNumber: Scalars["String"];
  adminUpdatedBy?: Maybe<Scalars["String"]>;
  updatedOnUtc: Scalars["DateTime"];
};

export type UpdateUserProfileError = IncompleteUserProfileError;

export type IUserInfo = {
  uid?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  photoUrl?: Maybe<Scalars["String"]>;
  providerId?: Maybe<Scalars["String"]>;
};

export type UnitsClaimValue = {
  __typename?: "UnitsClaimValue";
  unitsClaimValueId: Scalars["Int"];
  unitsClaimId: Scalars["Int"];
  unitsClaimDescription?: Maybe<Scalars["String"]>;
  unitsClaimEnumString?: Maybe<Scalars["String"]>;
  unitsClaim: UnitsClaimsEntity;
};

export type UnitsClaimsEntity = {
  __typename?: "UnitsClaimsEntity";
  unitsClaimId: Scalars["Int"];
  claimName: Scalars["String"];
  claimDefaultValue?: Maybe<Scalars["String"]>;
  assignClaimOnUserCreation: Scalars["Boolean"];
  unitsClaimType: any;
  claimValues: Array<UnitsClaimValue>;
};

export type UserClaimsEntity = {
  __typename?: "UserClaimsEntity";
  userClaimId: Scalars["Long"];
  userId: Scalars["Int"];
  unitsClaimId: Scalars["Int"];
  unitsClaim: UnitsClaimsEntity;
  userClaimValue?: Maybe<Scalars["String"]>;
};

export type UserLogInResponse = {
  __typename?: "UserLogInResponse";
  refreshToken: Scalars["String"];
  email: Scalars["String"];
  isEmailVerified: Scalars["Boolean"];
  uid: Scalars["String"];
  unitsToken: Scalars["String"];
};

export type UserMetadata = {
  __typename?: "UserMetadata";
  creationTimestamp?: Maybe<Scalars["DateTime"]>;
  lastSignInTimestamp?: Maybe<Scalars["DateTime"]>;
  lastRefreshTimestamp?: Maybe<Scalars["DateTime"]>;
};

export type UserModel = {
  __typename?: "UserModel";
  id: Scalars["String"];
};

export type UserRecord = IUserInfo & {
  __typename?: "UserRecord";
  uid?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  photoUrl?: Maybe<Scalars["String"]>;
  providerId?: Maybe<Scalars["String"]>;
  emailVerified: Scalars["Boolean"];
  disabled: Scalars["Boolean"];
  providerData?: Maybe<Array<Maybe<IUserInfo>>>;
  tokensValidAfterTimestamp: Scalars["DateTime"];
  userMetaData?: Maybe<UserMetadata>;
  customClaims?: Record<string, object>[];
  tenantId?: Maybe<Scalars["String"]>;
};
